import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getCurrencyWithId, setExpenseDetailsDrawer, getExpenseDetails, getExpenseDetailsStep,
    getOpenAdvance, resetCurrencyWithIdResponse, expenseChangeStatus, resetExpenseChangeStatus,
    updateExpenseWithCashAction, updateExpenseWithCashActionReset, updateExpenseWithCreditCardAction,
    expenseExportAllFiles, resetExpenseExportAllFiles, updateExpenseProcessed, updateExpenseProcessedReset,
    submitEditRequestAction, submitEditRequestResetAction, expenseCurrencyExportAllFiles, resetExpenseCurrencyExportAllFiles,
    resetExpenseDetailsState
} from '../../../redux/actions/expense.action';
import { getDataset } from '../../../redux/actions/dataset-action';

import { Form, ConfigProvider, Row, Col, Checkbox, Spin, Button, Input, DatePicker, List, Steps } from 'antd';
import { UilMultiply, UilFile } from '@iconscout/react-unicons';

import moment from 'moment';

import DynamicTable from "../../dynamicTable";
import DynamicToastMessage from '../../dynamicToastMessage/index';
import DynamicRequestModal from '../../dynamicModal/requestModal'
import ExpenseEdit from './expense_edit';

import { dateFormat, numberFormatTR, dateFormatConvert, localeLang } from '../../../utils/config';
import { compareObjects, compareCurrencyObjects } from '../../../utils/commonFormFunctions';

import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/expense.scss';
import '../../../styles/utility.scss';

function ExpenseDetails(props) {

    const { expenseManagerList,getDataset, getOpenAdvance, openAdvanceList, expenseDetails, expenseDetailsLoading, getExpenseDetails,
        getExpenseDetailsStep, updateExpenseWithCashAction, updateExpenseWithCashActionReset, detailExpenseRequests,
        expenseExportAllFilesResponse, setExpenseDetailsDrawer, expenseChangeStatus, selectExpense, expenseChangeStatusResponse,
        resetExpenseChangeStatus, expenseDetailStep, expenseDetailStepLoading, updateExpenseWithCreditCardAction, expenseExportAllFiles,
        resetExpenseExportAllFiles, processedButtonStatus, updateExpenseProcessed, updateExpenseProcessedReset, updateExpenseProcessedResponse,
        submitEditRequestAction, submitEditRequestResetAction, expenseEditRequestRepsonse, expenseCurrencyExportDetailFilesResponse, expenseCurrencyExportAllFiles, resetExpenseCurrencyExportAllFiles,
        resetExpenseDetailsState } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const { t } = useTranslation();
    const { Step } = Steps;
    const [openAdvanceListState, setOpenAdvanceListState] = useState([]);

    const [rejectModalData, setRejectModalData] = useState({ 'isVisible': false });
    const [rejectState, setRejectState] = useState({});

    const [approveModalData, setApproveModalData] = useState({ 'isVisible': false });
    const [approveState, setApproveState] = useState({});

    const [submitEditRequestModalData, setSubmitEditRequestModalData] = useState({ 'isVisible': false });
    const [submitEditRequestState, setSubmitEditRequestState] = useState({});

    const [rejectForm] = Form.useForm();
    const [approveForm] = Form.useForm();
    const [processedForm] = Form.useForm();
    const [submitEditRequestForm] = Form.useForm();

    const [creditCardList, setCreditCardList] = useState([]);
    const [creditCardCurrencyList, setCreditCardCurrencyList] = useState();
    const [creditCardTotalAmount, setCreditCardTotalAmount] = useState(0);

    const [returnAdvanceList, setReturnAdvanceList] = useState([]);
    const [returnAdvanceAddList, setReturnAdvanceAddList] = useState([]);
    const [returnAdvanceTotal, setReturnAdvanceTotal] = useState(0);
    const [returnCurrencyList, setReturnCurrencyList] = useState();
    const [returnCheckList, setReturnCheckList] = useState([]);

    const [expenseList, setExpenseList] = useState([]);
    const [expenseTotal, setExpenseTotal] = useState(0);
    const [expenseCurrencyList, setExpenseCurrencyList] = useState();

    const [currencyConvertList, setCurrencyConvertList] = useState([]);
    const [currencyConvertTotal, setCurrencyConvertTotal] = useState(0);
    const [currencyConvertSelectList1, setCurrencyConvertSelectList1] = useState();
    const [currencyConvertSelectList2, setCurrencyConvertSelectList2] = useState();

    const [checkListObj, setCheckListObj] = useState({});
    const [expenseState, setExpenseState] = useState({});
    const [selectRowKey, setSelectRowKey] = useState({ 'id': 0, 'tableName': null });
    const [expenseGroupList, setExpenseGroupList] = useState([]);
    const [differenceStatus, setDifferenceStatus] = useState(false);

    const [processedModalData, setProcessedModalData] = useState({ 'isVisible': false });
    const [processedState, setProcessedState] = useState({});
    const [datePickerFormatType, setDatePickerFormatType] = useState({})
    const defaultExpenseFields = {
        'expenseType': "",
        'expenseDate': "",
        'expenditureGroup': "",
        'spendingAmount': "",
        'spendingAmountXwrite': "",
        'currency': "",
        'tlAmount': 0,
        'exchangeRate': "",
        'description': "",
        'invoiceNo': "",
        'receiptInfo': "",
        'receipt': null
    }
    const defaultConvertCardFields = {
        "expenseDate": "",
        "cashedAmount": 0,
        "cashedCurrency": {
            "id": "",
            "displayValue": ""
        },
        "cashedTotal": "",
        "cashedExchange": 0,
        "convertedAmount": 0,
        "convertedCurrency": {
            "id": "",
            "displayValue": ""
        },
        "convertTotal": "0.00",
        "convertExchange": 0,
        "tlAmount": "0",
        "invoiceNo": "",
        "receiptInfo": "",
        "receipt": null,
        "cashedAmountXwrite": "",
        "convertedAmountXwrite": ""
    }
    const defaultCreditCardFields = {
        'expenseDate': null,
        'expenditureGroup': null,
        'spendingAmount': null,
        'spendingAmountXwrite': null,
        'currency': null,
        'tlAmount': 0,
        'exchangeRate': null,
        'description': null,
        'invoiceNo': null,
        'receiptInfo': null,
        'receipt': null
    }
    const updateExpenseAction = (type) => {
        let hasError = false
        let fields = [];
        let returnListFields = {
            'advanceInformation': false,
            'return': false,
        }
        let expenseListFields = {
            'expenseTypeIdx': false,
            'expenditureGroup': false,
            'expenseDate': false,
            'currencyIdx': false,
            'expenseAmount': false,
            'tlAmount': false,
            'exchangeRate': false,
        }
        let currencyConvertListFields = {
            "exchangeDate": false,
            "exchangeAmount": false,
            "exchangeCurrencyId": false,
            "convertAmount": false,
            "convertCurrencyId": false,
            "exchangeExpense": false
        }
        let requiredCardListFields = {
            'expenseDate': false,
            'expenditureGroup': false,
            'spendingAmount': false,
            'currency': false,
            'tlAmount': 0,
        }
        if (expenseDetails.expenseMasterTypeId === 1) {
            //return  & return Add List
            const returnList = returnAdvanceList.concat(returnAdvanceAddList);
            if (returnList.length > 0) {

                returnList.map(data => {
                    if (!data.advanceInformation?.id) {
                        returnListFields.advanceInformation = true;
                    }
                    if (data.return === "") {
                        returnListFields.return = true;
                    }
                });
                if (returnListFields.advanceInformation) {
                    hasError = true
                    fields.push(t('ADVANCE_INFORMATION'));
                }
                if (returnListFields.return) {
                    hasError = true;
                    fields.push(t('RETURN'));
                }
            }
            //Expense List
            if (compareObjects(expenseList[0], defaultExpenseFields) && compareCurrencyObjects(currencyConvertList[0], defaultConvertCardFields)) {
                hasError = true
                fields.push(t('EXPENSES'));
            } else {
                if (expenseList.length > 0 && (compareObjects(expenseList[0], defaultExpenseFields) === false && currencyConvertList[0].tlAmount)) {
                    expenseList.map(data => {
                        if (!data.currency?.id) {
                            expenseListFields.currecyIdx = true;
                        }
                        if (!data.expenseDate) {
                            expenseListFields.expenseDate = true;
                        }
                        if (!data.spendingAmount) {
                            expenseListFields.expenseAmount = true;
                        }
                        if (!data.tlAmount) {
                            expenseListFields.tlAmount = true;
                        }
                        if (!data.expenditureGroup?.id) {
                            expenseListFields.expenditureGroup = true;
                        }
                    });
                    if (expenseListFields.expenditureGroup) {
                        hasError = true;
                        fields.push(t('EXPENDITURE_GROUP'));
                    }
                    if (expenseListFields.currecyIdx) {
                        hasError = true
                        fields.push(t('PARTNER_CURRENCY'));
                    }
                    if (expenseListFields.expenseDate) {
                        hasError = true;
                        fields.push(t('PARTNER_DATE'));
                    }
                    if (expenseListFields.expenseAmount) {
                        hasError = true;
                        fields.push(t('PARTNER_AMOUNT2'));
                    }
                    if (expenseListFields.tlAmount) {
                        hasError = true;
                        fields.push(t('TL_AMOUNT'));
                    }
                }
            }
            // CurrencyConvertList
            // if (!compareObjects(expenseList[0], defaultExpenseFields)) {
            //     if (!compareCurrencyObjects(currencyConvertList[0], defaultConvertCardFields)) {
            //         hasError = true;
            //         fields.push(t('CURRENCY_CONVERSION_COSTS'));
            //     }
            // }
            if (!compareCurrencyObjects(currencyConvertList[0], defaultConvertCardFields)) {
                currencyConvertList.map(data => {
                    if (!data.expenseDate) {
                        currencyConvertListFields.expenseDate = true;
                    }
                    if (!data.cashedCurrency?.id) {
                        currencyConvertListFields.exchangeCurrencyId = true;
                    }
                    if (!data.cashedAmount) {
                        currencyConvertListFields.exchangeAmount = true;
                    }
                    if (!data.convertedCurrency?.id) {
                        currencyConvertListFields.convertCurrencyId = true;
                    }
                    if (!data.convertedAmount) {
                        currencyConvertListFields.convertAmount = true;
                    }
                    if (!data.tlAmount) {
                        currencyConvertListFields.exchangeExpense = true;
                    }
                });
                if (currencyConvertListFields.expenseDate) {
                    hasError = true;
                    fields.push(t('PARTNER_DATE'));
                }
                if (currencyConvertListFields.exchangeCurrencyId) {
                    hasError = true;
                    fields.push(t('PARTNER_CURRENCY'));
                }
                if (currencyConvertListFields.exchangeAmount) {
                    hasError = true;
                    fields.push(t('CASHED_AMOUNT'));
                }
                if (currencyConvertListFields.convertCurrencyId) {
                    hasError = true;
                    fields.push(t('PARTNER_CURRENCY'));
                }
                if (currencyConvertListFields.convertAmount) {
                    hasError = true;
                    fields.push(t('CONVERTED_AMOUNT'));
                }
                if (currencyConvertListFields.exchangeExpense) {
                    hasError = true;
                    fields.push(t('CONVERSION_COSTS'));
                }
            }
        } else if (expenseDetails.expenseMasterTypeId === 2) {
            creditCardList.map(data => {
                if (!data.expenseDate) {
                    requiredCardListFields.expenseDate = true;
                }
                if (!data.expenditureGroup?.id) {
                    requiredCardListFields.expenditureGroup = true;
                }
                if (!data.spendingAmount || data.spendingAmount === "0") {
                    requiredCardListFields.spendingAmount = true;
                }
                if (!data.currency || !data.currency?.id === "") {
                    requiredCardListFields.currency = true;
                }
                if (!data.tlAmount || !data.tlAmount === "0") {
                    requiredCardListFields.tlAmount = true;
                }
            });
            if (requiredCardListFields.expenseDate) {
                hasError = true
                fields.push(t('PARTNER_DATE'));
            }
            if (requiredCardListFields.expenditureGroup) {
                hasError = true;
                fields.push(t('EXPENDITURE_GROUP'));
            }
            if (requiredCardListFields.spendingAmount) {
                hasError = true;
                fields.push(t('PARTNER_AMOUNT2'));
            }
            if (requiredCardListFields.currency) {
                hasError = true;
                fields.push(t('PARTNER_CURRENCY'));
            }
            if (requiredCardListFields.tlAmount) {
                hasError = true;
                fields.push(t('TL_AMOUNT'));
            }
        }
        if (!hasError) {
            if (expenseDetails.expenseMasterTypeId === 1) {
                const returnList = returnAdvanceList.concat(returnAdvanceAddList);
                const returnAmountArr = returnList.map(data => {
                    return {
                        "currencyId": data.advanceInformation?.id,
                        "currencyName": data.advanceInformation?.displayValue,
                        "returnAmount": data.return,
                        "totalAdvance": data.advanceDebt,
                        "exchangeRate": data.exchangeRate,
                        "remainingAdvance": data.received

                    }
                });
                const expenseDetailsArr = expenseList.map(data => {
                    return {
                        "expenseTypeIdx": data?.expenditureGroup?.id,
                        "currencyIdx": data?.currency?.id,
                        "expenseDate": data?.expenseDate,
                        "expenseAmount": data?.spendingAmount,
                        "invoiceNo": data?.invoiceNo,
                        "description": data?.description,
                        "tlAmount": data?.tlAmount,
                        "exchangeRate": data?.exchangeRate,
                        "invoiceTitle": data?.receiptInfo,
                        "invoiceImage": data?.receipt
                    }
                });

                const currencyConvertArr = currencyConvertList.map(data => {
                    return {
                        "exchangeDate": data?.expenseDate,
                        "exchangeAmount": data?.cashedAmount,
                        'cashedExchange': data?.cashedExchange,
                        'convertExchange': data?.convertExchange,
                        'convertTotal': data?.convertTotal,
                        'cashedTotal': data?.cashedTotal,
                        "exchangeCurrencyId": data?.cashedCurrency?.id,
                        "convertAmount": data?.convertedAmount,
                        "convertCurrencyId": data?.convertedCurrency?.id,
                        "exchangeExpense": data?.tlAmount,
                        "invoiceNumber": data?.invoiceNo,
                        "invoiceTitle": data?.receiptInfo,
                        "invoiceImage": data?.receipt
                    }
                });
                const requestData = {
                    "closedAdvanceResourceId": Array.isArray(openAdvanceListState) ? openAdvanceListState.length > 0 ? openAdvanceListState.map(data => data.resourceId) : [] : [],
                    "expenseMasterTypeId": expenseDetails.expenseMasterTypeId,
                    "returnAmountList": returnAmountArr,
                    "description": expenseState.descriptionType1 ? expenseState.descriptionType1 : '',
                    "additionalField": expenseState.speacialAreaType1 ? expenseState.speacialAreaType1 : '',
                    "totalAdvanceAmount": parseFloat(returnAdvanceTotal),
                    "totalExpenseAmount": parseFloat(expenseTotal),
                    "totalExchangeAmount": parseFloat(currencyConvertTotal),
                    "differentAmount": returnAdvanceList.length > 0 ? parseFloat(parseFloat(returnAdvanceTotal) - (expenseTotal + currencyConvertTotal + expenseState.missingAmount)) : 0,
                    "exchanceExpense": !compareCurrencyObjects(currencyConvertList[0], defaultConvertCardFields) ? currencyConvertArr : [],
                    "expenseDetails": !compareObjects(expenseList[0], defaultExpenseFields) ? expenseDetailsArr : [],
                    "closeMissingAmount": (expenseState.missingAmount !== "" && expenseState.missingAmount > 0) ? true : false,
                    "missingAmount": (expenseState.missingAmount !== "" && expenseState.missingAmount > 0) ? expenseState.missingAmount : 0,
                    "IsDraft": type === 'saveChange' ? 1 : 0
                }
                updateExpenseWithCashAction(requestData, selectExpense?.resourceId);
                returnListFields = {
                    'advanceInformation': false,
                    'return': false,
                }
                expenseListFields = {
                    'expenseTypeIdx': false,
                    'expenditureGroup': false,
                    'expenseDate': false,
                    'currencyIdx': false,
                    'expenseAmount': false,
                    'tlAmount': false,
                    'exchangeRate': false,
                }
                currencyConvertListFields = {
                    "exchangeDate": false,
                    "exchangeAmount": false,
                    "exchangeCurrencyId": false,
                    "convertAmount": false,
                    "convertCurrencyId": false,
                    "exchangeExpense": false
                }
            } else if (expenseDetails.expenseMasterTypeId === 2) {
                const expenseTableArr = creditCardList.map(data => {
                    return {
                        "expenseTypeIdx": data?.expenditureGroup?.id,
                        "currencyIdx": data.currency?.id,
                        "expenseDate": data.expenseDate,
                        "expenseAmount": data.spendingAmount,
                        "invoiceNo": data.invoiceNo,
                        "description": data.description,
                        "tlAmount": data.tlAmount,
                        "exchangeRate": data.exchangeRate,
                        'invoiceTitle': data.receiptInfo,
                        'invoiceImage': data.receipt
                    }
                });
                const requestData = {
                    "Description": expenseState.description ? expenseState.description : '',
                    "AdditionalField": expenseState.speacialArea ? expenseState.speacialArea : '',
                    "ExpenseMasterTypeId": expenseState.expenseMasterTypeId,
                    "expenseDetails": expenseTableArr,
                    "IsDraft": type === 'saveChange' ? 1 : 0

                }
                updateExpenseWithCreditCardAction(requestData, selectExpense?.resourceId);
                requiredCardListFields = {
                    'expenseDate': false,
                    'expenditureGroup': false,
                    'spendingAmount': false,
                    'currency': false,
                    'tlAmount': false
                }
            }
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    function approve() {
        setApproveModalData({ ...approveModalData, 'isVisible': true, 'id': selectExpense?.resourceId })
    }
    function decline() {
        setRejectModalData({ ...rejectModalData, 'isVisible': true, 'id': selectExpense?.resourceId });
    }
    function submitEditRequest() {
        setSubmitEditRequestModalData({ ...submitEditRequestModalData, 'isVisible': true, 'id': selectExpense?.resourceId })

    }
    function edit(status) {
        setCurrentTab(2);
        setDifferenceStatus(status);
    }
    function cancel() {
        setCurrentTab(1);
        setDifferenceStatus(false);
    }
    const handleApproveConfirm = () => {
        expenseChangeStatus({ 'resourceId': selectExpense?.resourceId, 'description': approveState }, 'confirm');
    }
    const handleSubmitEditRequestConfirm = () => {
        submitEditRequestAction({ 'resourceId': selectExpense?.resourceId, 'description': submitEditRequestState });
    }
    const handleProcessedConfirm = () => {
        let hasError = false
        let fields = [];
        let processedRequiredFields = {
            'ProcessedInvoiceNumber': false,
            'ProcessedDate': false,
        }
        if (!processedState.invoiceNumber) {
            processedRequiredFields.ProcessedInvoiceNumber = true;
        }
        if (processedState.invoiceDate === "" || !processedState.invoiceDate) {
            processedRequiredFields.ProcessedDate = true;
        }
        if (processedRequiredFields.ProcessedInvoiceNumber) {
            hasError = true
            fields.push(t('PARTNER_INVOICE_NUMBER'));
        }
        if (processedRequiredFields.ProcessedDate) {
            hasError = true;
            fields.push(t('PARTNER_INVOICE_DATE'));
        }
        if (!hasError) {
            const data = {
                'ProcessedInvoiceNumber': processedState.invoiceNumber,
                'ProcessedDate': moment(processedState.invoiceDate).format(dateFormat)
            }
            updateExpenseProcessed(selectExpense?.resourceId, data);
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item, index) => { return <List.Item key={index}>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    const handleRejectConfirm = () => {
        expenseChangeStatus({ 'resourceId': selectExpense?.resourceId, 'description': rejectState }, 'reject');
    }
    const openProcessedModal = () => {
        setProcessedModalData({ ...processedModalData, 'isVisible': true, 'id': selectExpense?.resourceId })
    }
    const updateProcessedModal = () => {
        if (expenseDetails?.isProcessed) {
            setProcessedModalData({ ...processedModalData, 'isVisible': true, 'id': selectExpense?.resourceId })
            setProcessedState({
                ...processedState,
                'invoiceNumber': expenseDetails?.processedInvoiceNumber,
                'invoiceDate': expenseDetails?.processedDate
            });
            processedForm.setFieldsValue({
                'invoiceNumber': expenseDetails?.processedInvoiceNumber,
                'invoiceDate': moment(expenseDetails?.processedDate)
            });
        }
    }
    const handleApproveCancel = () => {
        setApproveModalData({ 'isVisible': false })
        setApproveState({});
        approveForm.resetFields();
    }
    const handleRejectCancel = () => {
        setRejectModalData({ 'isVisible': false })
        setRejectState({});
        rejectForm.resetFields();
    }
    const handleSubmitEditRequestCancel = () => {
        setSubmitEditRequestModalData({ 'isVisible': false })
        setSubmitEditRequestState({});
        submitEditRequestForm.resetFields();
    }
    const handleProcessedCancel = () => {
        setProcessedModalData({ 'isVisible': false });
        setProcessedState({});
        processedForm.resetFields()
    }
    const handleRejectValuesChange = (changedObj, all) => {
        setRejectState({ ...rejectState, ...changedObj });
    }
    const handleApproveValuesChange = (changedObj, all) => {
        setApproveState({ ...approveState, ...changedObj });
    }
    const handleSubmitEditRequestValuesChange = (changedObj, all) => {
        setSubmitEditRequestState(changedObj.description);
    }
    const handleProcessedValuesChange = (changedObj, all) => {
        setProcessedState({ ...processedState, ...changedObj });
    }
    const handleApproveChangeStatus = (state,resourceId) => {
        if(state == 'APPROVE'){
            const button = document.getElementById('approve-'+resourceId);
            if (button) { button?.click();} 
        }
        if(state == 'REJECT'){
            const button = document.getElementById('reject-'+resourceId);
            if (button) { button?.click();} 
        }
    }
    const rejectModalForm = (
        <div className="table-details pa-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={rejectForm}
                    onValuesChange={handleRejectValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const approveModalForm = (
        <div className="table-details pa-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={approveForm}
                    onValuesChange={handleApproveValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const submitEditRequestModalForm = (
        <div className="table-details pa-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={submitEditRequestForm}
                    onValuesChange={handleSubmitEditRequestValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const processedModalForm = (
        <div className="table-details pa-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={processedForm}
                    onValuesChange={handleProcessedValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="invoiceNumber" label={t('PARTNER_INVOICE_NUMBER') + " *"}>
                            <Input
                                placeholder={t('PARTNER_INVOICE_NUMBER')}
                            />
                        </Form.Item>
                        <Form.Item name="invoiceDate" label={t('PARTNER_INVOICE_DATE') + " *"}>
                            <DatePicker
                                locale={localeLang}
                                placeholder={t('PLACEHOLDER_DATE')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    useEffect(() => {
        if (expenseDetails.person.id) {
            getOpenAdvance(expenseDetails?.person.id, selectExpense?.resourceId)
            getDataset(23);
        }
        // If we need this case
        // if (expenseDetails?.expenseDetails.length > 0) {
        //     return expenseDetails.expenseDetails.filter(item => item.invoiceImage !== "" && item.invoiceImage).map(filteredName => (
        //         setDownloadModalShow(true)
        //     ));
        // }

    }, [expenseDetails]);
    useEffect(() => {
        if (expenseExportAllFilesResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': expenseExportAllFilesResponse?.message ? JSON.parse(expenseExportAllFilesResponse.message).message : null,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetExpenseExportAllFiles();
        }
    }, [expenseExportAllFilesResponse])
    useEffect(() => {
        if (expenseCurrencyExportDetailFilesResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': expenseCurrencyExportDetailFilesResponse?.message ? JSON.parse(expenseCurrencyExportDetailFilesResponse.message).message : null,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetExpenseCurrencyExportAllFiles();
        }
    }, [expenseCurrencyExportDetailFilesResponse])
    useEffect(() => {
        setOpenAdvanceListState(openAdvanceList)
    }, [openAdvanceList])
    useEffect(() => {
        if (expenseChangeStatusResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': expenseChangeStatusResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            resetExpenseDetailsState();
            getExpenseDetails(selectExpense.resourceId);
            getExpenseDetailsStep(selectExpense.resourceId);

            setRejectModalData({ 'isVisible': false });
            setRejectState({});
            rejectForm.resetFields();

            setApproveModalData({ 'isVisible': false });
            setApproveState({});
            approveForm.resetFields();

            resetExpenseChangeStatus();

        }
        else if (expenseChangeStatusResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': expenseChangeStatusResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetExpenseChangeStatus();
        }
    }, [expenseChangeStatusResponse])

    useEffect(() => {
        if (updateExpenseProcessedResponse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': updateExpenseProcessedResponse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            resetExpenseDetailsState();
            getExpenseDetails(selectExpense.resourceId);
            getExpenseDetailsStep(selectExpense.resourceId)
            setProcessedModalData({ 'isVisible': false })
            setProcessedState({});
            processedForm.resetFields()
            updateExpenseProcessedReset();
        }
        else if (updateExpenseProcessedResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': updateExpenseProcessedResponse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            updateExpenseProcessedReset();
        }
    }, [updateExpenseProcessedResponse])

    useEffect(() => {
        if (expenseEditRequestRepsonse.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': expenseEditRequestRepsonse.message,
                'statusType': 'success',
                'position': 'bottom-right'
            });
            resetExpenseDetailsState();
            getExpenseDetails(selectExpense.resourceId);
            getExpenseDetailsStep(selectExpense.resourceId)
            setSubmitEditRequestModalData({ 'isVisible': false })
            setSubmitEditRequestState({});
            submitEditRequestForm.resetFields();
            submitEditRequestResetAction();
        }
        else if (expenseEditRequestRepsonse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': expenseEditRequestRepsonse.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            submitEditRequestResetAction();
        }
    }, [expenseEditRequestRepsonse])


    useEffect(() => {
        if (detailExpenseRequests.status === 'SUCCESS') {
            DynamicToastMessage({
                'key': 'success_key',
                'title': t('SUCCESS'),
                'description': detailExpenseRequests.message,
                'statusType': 'success',
                'position': 'bottom-right'
            })
            resetExpenseDetailsState();
            getExpenseDetails(selectExpense.resourceId);
            getExpenseDetailsStep(selectExpense.resourceId)
            setCurrentTab(1);
            updateExpenseWithCashActionReset()

        }
        else if (detailExpenseRequests.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': detailExpenseRequests.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            updateExpenseWithCashActionReset()
        }
    }, [detailExpenseRequests])
    function closeControl() {
        destroyLastState()
    }
    function destroyLastState() {
        setCurrentTab(1);
        setExpenseDetailsDrawer(false);
        resetExpenseDetailsState();
    }

    const returnColumns = [
        {
            title: `${t('ADVANCE_INFORMATION')}`,
            dataIndex: 'currencyName',
            key: 'currencyName',
            sorter: true,
        },
        {
            title: `${t('RECEIVED')}`,
            dataIndex: 'remainingAdvance',
            key: 'remainingAdvance',
            sorter: true,
            render: obj => {
                return parseFloat(obj).toLocaleString('tr-TR')
            }
        },
        {
            title: `${t('RETURN')}`,
            dataIndex: 'returnAmount',
            key: 'returnAmount',
            sorter: true,
            render: obj => {
                return parseFloat(obj.count).toLocaleString('tr-TR')
            }
        },
        {
            title: `${t('EXCHANGE_RATE')}`,
            dataIndex: 'exchangeRate',
            key: 'exchangeRate',
            sorter: true,
        },
        {
            title: `${t('ADVANCE_DEBT')}`,
            dataIndex: 'totalAdvance',
            key: 'totalAdvance',
            sorter: true,
            render: obj => {
                return parseFloat(obj).toLocaleString('tr-TR') + ' TL'
            }
        }
    ];
    const returnData = expenseDetails && expenseDetails.returnAmountList.map(row => {
        return (
            {
                "currencyName": row.currencyName,
                "remainingAdvance": row.remainingAdvance,
                "returnAmount": { 'count': row.returnAmount, 'currency': row.currencyName },
                "exchangeRate": row.exchangeRate,
                "totalAdvance": row.totalAdvance
            }
        )
    })
    //Return List
    const handleTableChange = (data) => {

        // const paginationObj = { ...searchData.pagination, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": data.sorter.field ? data.sorter.field : searchData.pagination.orderBy }
        // if (data.sorter.order) {
        //     paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        // }
        // setSearchData({ ...searchData, 'pagination': paginationObj })
    }
    const tableConfig = () => ({
        filterableColumns: [],
        sortableColumns: [],
        page: "returnList",
        showVisibilityMenu: false
    })
    const paginationConfig = (tableLength) => ({
        total: tableLength,
        showSizeChanger: true,
        locale: { items_per_page: "/ " + t('PAGE') },
        position: tableLength > 10 ? ['bottomLeft', 'none'] : ['none', 'none'],
    })
    //expense List
    const expenseColumns = [
        {
            title: `${t('PARTNER_DATE')}`,
            dataIndex: 'expenseDate',
            key: 'expenseDate',
            sorter: true,
        },
        {
            title: `${t('EXPENDITURE_GROUP')}`,
            dataIndex: 'expenseGroup',
            key: 'expenseGroup',
            sorter: true,
        },
        {
            title: `${t('PARTNER_RETURN_DESCRIPTION')}`,
            dataIndex: 'description',
            key: 'description',
            sorter: true,
        },
        {
            title: `${t('PARTNER_AMOUNT2')}`,
            dataIndex: 'expenseAmount',
            key: 'expenseAmount',
            sorter: true,
        },
        {
            title: `${t('PARTNER_CURRENCY')}`,
            dataIndex: 'currency',
            key: 'currency',
            sorter: true,
        },
        {
            title: `${t('VAT')}`,
            dataIndex: 'vat',
            key: 'vat',
            sorter: true,
        },
        {
            title: `${t('TL_AMOUNT')}`,
            dataIndex: 'amountTL',
            key: 'amountTL',
            sorter: true,
        },
        {
            title: `${t('VAT_EXCLUDE')}`,
            dataIndex: 'vatExclude',
            key: 'vatExclude',
            sorter: true,
        },
        {
            title: `${t('VAT_AMOUNT')}`,
            dataIndex: 'vatAmount',
            key: 'vatAmount',
            sorter: true,
        },
        {
            title: `${t('PARTNER_INVIOCE_NO')}`,
            dataIndex: 'invoiceNo',
            key: 'invoiceNo',
            sorter: true,
        },
        {
            title: `${t('RECEIPT_TITLE_INFORMATION')}`,
            dataIndex: 'invoiceTitle',
            key: 'invoiceTitle',
            sorter: true,
        },
        {
            title: `${t('RECEIPT')}`,
            dataIndex: 'invoiceImage',
            key: 'invoiceImage',
            sorter: true,
            render: obj => {
                if (obj) {
                    return (
                        <a href={obj} target="_blank" className="upload-icon">
                            <UilFile />
                        </a>
                    )
                } else {
                    return '';
                }

            }
        }
    ];
    const expenseData = expenseDetails && expenseDetails.expenseDetails.map(row => {
        return (
            {
                "expenseDate": row.expenseDate ? moment(row.expenseDate).format(dateFormatConvert) : '',
                "expenseGroup": row.expenseType?.displayValue,
                "description": row.description,
                "expenseAmount": parseFloat(row.expenseAmount).toLocaleString('tr-TR'),
                "currency": row.currency?.displayValue,
                "amountTL": parseFloat(row.amountTL).toLocaleString('tr-TR'),
                "invoiceNo": row.invoiceNo,
                "vat": row.vat === 0 ? '%0' : row.vat ? '%'+row.vat : '-',
                "vatExclude": row.vat ? parseFloat(row.amountTL / (1 + row.vat / 100)).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : parseFloat(row.amountTL).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                "vatAmount": row.vat === 0 ? 0 : row.vat ?parseFloat(row.amountTL - (row.amountTL / (1 + row.vat / 100))).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-',
                "invoiceTitle": row.invoiceTitle,
                "invoiceImage": row.invoiceImage,
            }
        )
    })
    //currency List 
    const currencyColumns = [
        {
            title: `${t('PARTNER_DATE')}`,
            dataIndex: 'exchangeDate',
            key: 'exchangeDate',
            sorter: true,
        },
        {
            title: `${t('CASHED_AMOUNT')}`,
            dataIndex: 'exchangeAmount',
            key: 'exchangeAmount',
            sorter: true,
        },
        {
            title: `${t('PARTNER_CURRENCY')}`,
            dataIndex: 'exchangeCurrencyName',
            key: 'exchangeCurrencyName',
            sorter: true,
        },
        {
            title: `${t('CONVERTED_AMOUNT')}`,
            dataIndex: 'convertAmount',
            key: 'convertAmount',
            sorter: true,
        },
        {
            title: `${t('PARTNER_CURRENCY')}`,
            dataIndex: 'convertCurrencyName',
            key: 'convertCurrencyName',
            sorter: true,
        },
        {
            title: `${t('CONVERSION_COSTS')}`,
            dataIndex: 'exchangeExpense',
            key: 'exchangeExpense',
            sorter: true,
        },
        {
            title: `${t('PARTNER_INVIOCE_NO')}`,
            dataIndex: 'invoiceNumber',
            key: 'invoiceNumber',
            sorter: true,
        },
        {
            title: `${t('RECEIPT_TITLE_INFORMATION')}`,
            dataIndex: 'invoiceTitle',
            key: 'invoiceTitle',
            sorter: true,
        },
        {
            title: `${t('RECEIPT')}`,
            dataIndex: 'invoiceImage',
            key: 'invoiceImage',
            sorter: true,
            render: obj => {
                if (obj) {
                    return (
                        <a href={obj} target="_blank" className="upload-icon">
                            <UilFile />
                        </a>
                    )
                } else {
                    return '';
                }
            }
        },

    ];
    const currencyListData = expenseDetails && expenseDetails.exchanceExpense.map(row => {
        return (
            {
                "exchangeDate": row.exchangeDate ? moment(row.exchangeDate).format(dateFormatConvert) : '',
                "convertAmount": parseFloat(row.convertAmount).toLocaleString('tr-TR'),
                "convertCurrencyName": row.convertCurrencyName,
                "exchangeAmount": parseFloat(row.exchangeAmount).toLocaleString('tr-TR'),
                "exchangeCurrencyName": row.exchangeCurrencyName,
                "exchangeExpense": parseFloat(row.exchangeExpense).toLocaleString('tr-TR'),
                "invoiceNumber": row.invoiceNumber,
                "invoiceTitle": row.invoiceTitle,
                "invoiceImage": row.invoiceImage,
            }
        )
    })
    const expenseAll = () => {
        expenseExportAllFiles(selectExpense.resourceId)
    }
    const downloadAllCurrency = () => {
        expenseCurrencyExportAllFiles(selectExpense.resourceId)
    }

    return (
        <>
            {(expenseDetailsLoading && expenseDetailStepLoading) ?
                <Spin className="tab-loading" /> :
                <div className="table-details pt-5 expense-drawer expense-add-scrollable">
                    <DynamicRequestModal
                        modalVisible={rejectModalData?.isVisible}
                        setStateData={setRejectModalData}
                        handleConfirm={handleRejectConfirm}
                        handleCancel={handleRejectCancel}
                        confirmData={rejectModalData?.id}
                        isForm={rejectModalForm}
                        iconName='UilTimesCircle'
                        modalTitle={t('TIME_OFF_REQUEST_REJECT')}
                        description={t('EXPENSE_REJECT_MESSAGE')}
                        cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                        successBtnTxt={t('TIME_OFF_REQUEST_REJECT')}
                    />
                    <DynamicRequestModal
                        modalVisible={approveModalData?.isVisible}
                        setStateData={setApproveModalData}
                        handleConfirm={handleApproveConfirm}
                        handleCancel={handleApproveCancel}
                        confirmData={approveModalData?.id}
                        isForm={approveModalForm}
                        iconName='UilCheckCircle'
                        modalTitle={t('TIME_OFF_REQUEST_CONFIRM')}
                        description={t('EXPENSE_APPROVE_MESSAGE')}
                        cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                        successBtnTxt={t('TIME_OFF_REQUEST_CONFIRM')}
                    />
                    <DynamicRequestModal
                        modalVisible={submitEditRequestModalData?.isVisible}
                        setStateData={setSubmitEditRequestModalData}
                        handleConfirm={handleSubmitEditRequestConfirm}
                        handleCancel={handleSubmitEditRequestCancel}
                        confirmData={submitEditRequestModalData?.id}
                        isForm={submitEditRequestModalForm}
                        iconName='UilRedo'
                        modalTitle={t('SUBMIT_EDIT_REQUEST')}
                        description={t('SUBMIT_EDIT_REQUEST_TITLE')}
                        cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                        successBtnTxt={t('SUBMIT_EDIT_REQUEST')}
                    />
                    <DynamicRequestModal
                        modalVisible={processedModalData?.isVisible}
                        setStateData={setProcessedModalData}
                        handleConfirm={handleProcessedConfirm}
                        handleCancel={handleProcessedCancel}
                        confirmData={processedModalData?.id}
                        isForm={processedModalForm}
                        iconName='UilCheckCircle'
                        modalTitle={t('PROCESSED')}
                        cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                        successBtnTxt={t('PARTNER_SAVE')}
                    />
                    <div id={'close-expense-details-'+expenseDetails.resourceId} onClick={() => closeControl()} style={{ display: 'block' }} className="w-100 flex-end add-global-close-icon">
                        <UilMultiply />
                    </div>
                    <div className="w-100">
                        {expenseDetailStep[0].stepName &&
                            <Steps className="left-step horizontal-step pt-8 px-5" direction="horizontal" size="middle" current={expenseDetailStep.filter(filterData => filterData.isSelected === true)[0]?.sortOrder - 1 || expenseDetailStep.length}>
                                {expenseDetailStep.map((data, index) => {
                                    return (
                                        <Step key={index} title={data.stepName}
                                            description={

                                                <ul className="sub-tab-hide-icon">
                                                    <li className={data.isSelected ? "sub-tab-active" : ''}>
                                                        {expenseDetailStep.length > 0 ? data.name : ''} <br />
                                                        {expenseDetailStep.length > 0 ? data?.date ? moment(data?.date).format('L') : '' : ''}
                                                    </li>
                                                </ul>
                                            } />
                                    )
                                })}
                            </Steps>
                        }
                        {currentTab === 1 ?
                            <>
                                <div className="w-100">
                                    <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                                        <Form
                                            layout="vertical min-height-500 pa-0"
                                            requiredMark={true}
                                        >
                                            <Form.Item className="pt-5 mb-3" name="expenseType" label={t('PARTNER_EXPENSE_TYPE') + " *"} >
                                                {expenseDetails.expenseMasterTypeId === 1 ?
                                                    <label>{t('CASH_EXPENSE_ENTRY')}</label>
                                                    :
                                                    <label>{t('SPENDING_ENTRY_WITH_CREDIT_CARD')}</label>
                                                }
                                            </Form.Item>
                                            <br />
                                            {expenseDetails.expenseMasterTypeId === 1 &&
                                                <>
                                                    <Row>
                                                        <Col span={12}>
                                                            {Array.isArray(openAdvanceListState) ?
                                                                openAdvanceListState.length > 0 ?
                                                                    <>
                                                                        <Form.Item name="closeAdvanceList" label={t('ADVANCE_TO_BE_CLOSED')}>
                                                                            {openAdvanceListState.map((item, index) => {
                                                                                var checkBoxStatus = null;
                                                                                expenseDetails.closedAdvance.includes(item.resourceId) ?
                                                                                    checkBoxStatus = true
                                                                                    :
                                                                                    checkBoxStatus = false;
                                                                                return (
                                                                                    <div className="w-100" key={index}>
                                                                                        <Checkbox defaultChecked={checkBoxStatus} value={index} disabled={true}>{moment(item.advanceCreatedDate).format(dateFormat) + " " + item.personName + " "}  <span className="close-advance-checkbox-amount-data" />{item.amounts.length > 0 &&
                                                                                            item.amounts.map(data => numberFormatTR.format(data.amount) + ' ' + data.currencyName).join(', ')
                                                                                        }</Checkbox>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </Form.Item>
                                                                    </>
                                                                    : <div className="mb-20"></div>
                                                                : <Spin ></Spin>
                                                            }
                                                            <Form.Item name="descriptionType1" style={{ wordBreak: 'break-word' }} label={t('ADVANCE_DESCRIPTION')}>
                                                                <p className="black f-16">    {expenseDetails?.description} </p>
                                                            </Form.Item>
                                                            <Form.Item name="speacialAreaType1" style={{ wordBreak: 'break-word' }} label={t('SPEACIAL_AREA')}>
                                                                <p className="black f-16">    {expenseDetails?.additionalField} </p>
                                                            </Form.Item>
                                                            {expenseDetails?.isProcessed &&
                                                                <>
                                                                    <Form.Item name="processedNumber" style={{ wordBreak: 'break-word' }} label={t('PARTNER_INVOICE_DATE')}>
                                                                        <p className="black f-16">    {moment(expenseDetails?.processedDate).format(dateFormatConvert)} </p>
                                                                    </Form.Item>
                                                                    <Form.Item name="processedDate" style={{ wordBreak: 'break-word' }} label={t('PARTNER_INVOICE_NUMBER')}>
                                                                        <p className="black f-16">{expenseDetails?.processedInvoiceNumber}</p>
                                                                    </Form.Item>
                                                                </>
                                                            }
                                                        </Col>
                                                        <Col offset={1} span={11}>
                                                            {returnData.length > 0 &&
                                                                <>
                                                                    <div>
                                                                        <span className="f-16 black fw-600">{t('ENTER_THE_ADVANCE_TO_BE_REFUNDED')}</span>
                                                                    </div>
                                                                    <div className="table-container table-hide-all-buttons blue-table ">
                                                                        <DynamicTable columns={returnColumns} dataSource={returnData}
                                                                            tableConfig={tableConfig()} paginationConfig={paginationConfig(returnData.length)} onFilter={() => null}
                                                                            onTableChange={(e) => handleTableChange(e)} searchBox={false} onResetAllFilters={null}
                                                                            isShowPassives={null} passiveButton={false} checkboxStatus={false} loadStatus={false} hideScroll={true} />
                                                                        <br />
                                                                    </div>
                                                                </>
                                                            }
                                                            <div className="expense-total-container">
                                                                <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('NET_ADVANCE_RECEIVED_TOTAL')} : </span>{parseFloat(expenseDetails?.totalAdvanceAmount).toLocaleString('tr-TR')} TL</div>
                                                                <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('TOTAL_EXPENDITURE')} : </span>{parseFloat(expenseDetails?.totalExpenseAmount).toLocaleString('tr-TR')}  TL</div>
                                                                {currencyListData.length > 0 ? <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('CURRENCY_CONVERSION_COSTS')} : </span>{parseFloat(expenseDetails?.totalExchangeAmount).toLocaleString('tr-TR')} TL</div> : ''}
                                                                {expenseDetails?.missingAmount > 0 && <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('CLOSED_ADVANCE_DIFFERENCE')} : </span>{parseFloat(expenseDetails?.missingAmount).toLocaleString('tr-TR')} TL</div>}
                                                                <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('DIFFERENCE')} : </span>{
                                                                    expenseDetails?.missingAmount > 0 ? parseFloat(expenseDetails?.differentAmount).toLocaleString('tr-TR', {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    }) : parseFloat(expenseDetails?.differentAmount).toLocaleString('tr-TR', {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    })} TL</div>
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                    {expenseData.length > 0 &&
                                                        <>
                                                            <div className="w-100 space-between">
                                                                <span className="f-24 black fw-600">{t('EXPENSES')}</span>
                                                                <Button onClick={() => expenseAll()} className="px-15 white-btn">{t('BULK_DOWNLOAD_RECEIPT')}</Button>
                                                            </div>
                                                            <div className="table-container table-hide-all-buttons blue-table">
                                                                <DynamicTable columns={expenseColumns} dataSource={expenseData}
                                                                    tableConfig={tableConfig()} paginationConfig={paginationConfig(expenseData.length)} onFilter={() => null}
                                                                    onTableChange={(e) => handleTableChange(e)} searchBox={false} onResetAllFilters={null}
                                                                    isShowPassives={null} passiveButton={false} checkboxStatus={false} loadStatus={false} hideScroll={true} />
                                                                <br />
                                                                <div className="w-100 space-between">
                                                                    <div>
                                                                        <span className="black f-16"><span className="fw-600 ">{t('TOTAL_EXPENDITURE')} : </span>{parseFloat(expenseDetails?.totalExpenseAmount).toLocaleString('tr-TR')} TL</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {currencyListData.length > 0 &&
                                                        <div className="pad-top-40">
                                                            <div className="w-100 space-between">
                                                                <span className="f-24 black fw-600">{t('CURRENCY_CONVERSION_COSTS')}</span>
                                                                <Button onClick={() => downloadAllCurrency()} className="px-15 white-btn">{t('BULK_DOWNLOAD_RECEIPT')}</Button>
                                                            </div>
                                                            <div className="table-container table-hide-all-buttons blue-table">
                                                                <DynamicTable columns={currencyColumns} dataSource={currencyListData}
                                                                    tableConfig={tableConfig()} paginationConfig={paginationConfig(currencyListData.length)} onFilter={() => null}
                                                                    onTableChange={(e) => handleTableChange(e)} searchBox={false} onResetAllFilters={null}
                                                                    isShowPassives={null} passiveButton={false} checkboxStatus={false} loadStatus={false} hideScroll={true} />
                                                                <br />
                                                                <div className="w-100 space-between">
                                                                    <div>
                                                                        <span className="black f-16"><span className="fw-600 ">{t('TOTAL_EXPENDITURE')} : </span>{parseFloat(expenseDetails?.totalExchangeAmount).toLocaleString('tr-TR')} TL</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                            {expenseDetails.expenseMasterTypeId === 2 &&
                                                <>
                                                    <Row>
                                                        <Col span={11}>
                                                            <Form.Item name="descriptionType1" style={{ wordBreak: 'break-word' }} label={t('ADVANCE_DESCRIPTION')}>
                                                                <p className="black f-16">    {expenseDetails?.description} </p>
                                                            </Form.Item>

                                                        </Col>
                                                        <Col offset={2} span={11}>

                                                            <Form.Item name="speacialAreaType1" style={{ wordBreak: 'break-word' }} label={t('SPEACIAL_AREA')}>
                                                                <p className="black f-16">    {expenseDetails?.additionalField} </p>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    {expenseDetails?.isProcessed &&
                                                        <>
                                                            <Form.Item name="processedDate" style={{ wordBreak: 'break-word' }} label={t('PARTNER_INVOICE_DATE')}>
                                                                <p className="black f-16">    {moment(expenseDetails?.processedDate).format(dateFormatConvert)} </p>
                                                            </Form.Item>
                                                            <Form.Item name="processedDate" style={{ wordBreak: 'break-word' }} label={t('PARTNER_INVOICE_DATE')}>
                                                                <p className="black f-16">{expenseDetails?.processedInvoiceNumber}</p>
                                                            </Form.Item>
                                                        </>
                                                    }
                                                    <div className="pad-top-40" />
                                                    <div className="w-100 space-between">
                                                        <span className="f-24 black fw-600">{t('EXPENSES')}</span>
                                                        <Button onClick={() => expenseAll()} className="px-15 white-btn">{t('BULK_DOWNLOAD_RECEIPT')}</Button>
                                                    </div>
                                                    <div className="table-container table-hide-all-buttons blue-table">
                                                        <DynamicTable columns={expenseColumns} dataSource={expenseData}
                                                            tableConfig={tableConfig()} paginationConfig={paginationConfig(expenseData.length)} onFilter={() => null}
                                                            onTableChange={(e) => handleTableChange(e)} searchBox={false} onResetAllFilters={null}
                                                            isShowPassives={null} passiveButton={false} checkboxStatus={false} loadStatus={false} hideScroll={true} />
                                                        <br />
                                                        <div className="w-100 space-between">
                                                            <div>
                                                                <span className="black f-16"><span className="fw-600 ">{t('TOTAL_EXPENDITURE')} : </span>{parseFloat(expenseDetails?.totalExpenseAmount).toLocaleString('tr-TR')} TL</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </Form>
                                    </ConfigProvider>
                                </div>
                            </>
                            :

                            <ExpenseEdit
                                expenseData={expenseDetails}
                                openAdvanceListState={openAdvanceListState}
                                creditCardList={creditCardList}
                                setCreditCardList={setCreditCardList}
                                creditCardCurrencyList={creditCardCurrencyList}
                                setCreditCardCurrencyList={setCreditCardCurrencyList}
                                creditCardTotalAmount={creditCardTotalAmount}
                                setCreditCardTotalAmount={setCreditCardTotalAmount}
                                returnAdvanceList={returnAdvanceList}
                                setReturnAdvanceList={setReturnAdvanceList}
                                returnAdvanceAddList={returnAdvanceAddList}
                                setReturnAdvanceAddList={setReturnAdvanceAddList}
                                returnAdvanceTotal={returnAdvanceTotal}
                                setReturnAdvanceTotal={setReturnAdvanceTotal}
                                returnCurrencyList={returnCurrencyList}
                                setReturnCurrencyList={setReturnCurrencyList}
                                returnCheckList={returnCheckList}
                                setReturnCheckList={setReturnCheckList}
                                expenseList={expenseList}
                                setExpenseList={setExpenseList}
                                expenseTotal={expenseTotal}
                                setExpenseTotal={setExpenseTotal}
                                expenseCurrencyList={expenseCurrencyList}
                                setExpenseCurrencyList={setExpenseCurrencyList}
                                currencyConvertList={currencyConvertList}
                                setCurrencyConvertList={setCurrencyConvertList}
                                currencyConvertTotal={currencyConvertTotal}
                                setCurrencyConvertTotal={setCurrencyConvertTotal}
                                currencyConvertSelectList1={currencyConvertSelectList1}
                                setCurrencyConvertSelectList1={setCurrencyConvertSelectList1}
                                currencyConvertSelectList2={currencyConvertSelectList2}
                                setCurrencyConvertSelectList2={setCurrencyConvertSelectList2}
                                checkListObj={checkListObj}
                                setCheckListObj={setCheckListObj}
                                expenseState={expenseState}
                                setExpenseState={setExpenseState}
                                selectRowKey={selectRowKey}
                                setSelectRowKey={setSelectRowKey}
                                expenseGroupList={expenseGroupList}
                                setExpenseGroupList={setExpenseGroupList}
                                isManager={expenseDetailStep[0].editableStatus}
                                differenceStatus={differenceStatus}
                                defaultExpenseFields={defaultExpenseFields}
                                defaultConvertCardFields={defaultConvertCardFields}
                                defaultCreditCardFields={defaultCreditCardFields}
                            />
                        }
                    </div>

                    <div className="add-expense-draw-footer">
                        <div className="add-expense-draw-footer">

                            {(expenseDetailStep[0].editableStatus === 3 && currentTab === 1) &&
                                <div className="w-100  space-between" >
                                    <div className="w-100 flex-class">
                                        <Button onClick={() => edit(false)} className="w-180 h-40 white-btn">{t('PARTNER_EDIT')}</Button>
                                        <div className="pad-right-12" />
                                        <Button onClick={() => submitEditRequest()} className="w-200 h-40  primary-btn ">{t('SUBMIT_EDIT_REQUEST')}</Button>
                                        {(expenseDetails.expenseMasterTypeId === 1 && expenseDetails.returnAmountList.length > 0 && expenseDetails?.differentAmount > 0) &&
                                            <>
                                                <div className="pad-right-12" />
                                                <Button onClick={() => edit(true)} className="w-180 h-40  primary-btn ">{t('CLOSE_THE_ADVANCE_DIFFERENCE')}</Button>
                                            </>
                                        }
                                    </div>
                                    <div className="flex-class flex-end ">
                                        <Button onClick={() => decline()} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('PARTNER_DECLINE')}</Button>
                                        <div className="pad-right-12" />
                                        <Button onClick={() => approve()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('PARTNER_APPROVE')}</Button>
                                    </div>
                                </div>
                            }
                            {(expenseDetailStep[0].editableStatus === 2 && currentTab === 1 || expenseDetails.isDraft && currentTab === 1) &&
                                <div className="w-100 space-between" >
                                    <Button onClick={() => edit(false)} className="w-120 h-40 transparent-import fw-600 transparent-btn pa-0">{t('PARTNER_EDIT')}</Button>
                                    <Button onClick={() => destroyLastState()} className="w-180 h-40 f-14 font-w-700 primary-btn ">{t('INVENTORY_RETURN_LIST')}</Button>
                                </div>
                            }
                            {((expenseDetailStep[0].editableStatus === 1 || expenseDetailStep[0].editableStatus === 0) && currentTab === 1 && !processedButtonStatus) &&
                                <div className="w-100 flex-end" >
                                   { ((expenseManagerList?.find(x => x.resourceId == expenseDetails.resourceId)?.showConfirmButton) 
                                    && (expenseManagerList?.find(x => x.resourceId == expenseDetails.resourceId)?.stateType.id == '1')
                                   ) ? 
                                   <div className="w-100 flex-end pad-right-12" >
                                    <Button className="ml-2 w-180 h-40 f-14 font-w-700 white-btn" onClick={() => handleApproveChangeStatus('REJECT', expenseDetails.resourceId)} >{t('TIME_OFF_REQUEST_REJECT')}</Button>
                                    <Button className="ml-2 w-180 h-40 f-14 font-w-700 primary-btn" onClick={() => handleApproveChangeStatus('APPROVE', expenseDetails.resourceId)} >{t('TIME_OFF_REQUEST_CONFIRM')}</Button>
                                    </div>
                                    : <Button onClick={() => destroyLastState()} className="w-180 h-40 f-14 font-w-700 primary-btn">{t('INVENTORY_RETURN_LIST')}</Button>
                                    } 
                                </div>
                            }
                            {(expenseDetailStep[0].editableStatus === 1 && currentTab === 1 && processedButtonStatus && !expenseDetails.isProcessed) &&
                                <div className="w-100 space-between" >
                                    <div>
                                        <Button onClick={() => openProcessedModal()} style={{ position: 'relative', right: '24px', marginLeft: '24px' }} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('PROCESSED')}</Button>
                                    </div>
                                    <Button onClick={() => destroyLastState()} className="w-180 h-40 f-14 font-w-700 primary-btn ">{t('INVENTORY_RETURN_LIST')}</Button>
                                </div>
                            }
                            {(expenseDetailStep[0].editableStatus === 1 && currentTab === 1 && processedButtonStatus && expenseDetails.isProcessed) &&
                                <div className="w-100 space-between" >
                                    <div>
                                        <Button onClick={() => updateProcessedModal()} style={{ position: 'relative', right: '24px', marginLeft: '24px' }} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('UPDATE_PROCESSED')}</Button>
                                    </div>
                                    <Button onClick={() => destroyLastState()} className="w-180 h-40 f-14 font-w-700 primary-btn ">{t('INVENTORY_RETURN_LIST')}</Button>
                                </div>
                            }
                            {currentTab === 2 &&
                                <div className="w-100 space-between" >
                                    <div>
                                        <Button onClick={() => cancel()} className="w-120 h-40 transparent-import fw-600 transparent-btn pa-0">{t('INVENTORY_CANCEL')}</Button>
                                    </div>
                                    <div>
                                        {expenseDetails.isDraft &&
                                            <Button onClick={() => updateExpenseAction('saveChange')} style={{ position: 'relative', right: '24px', marginLeft: '24px' }} className="w-180 h-40 f-14 font-w-700 white-btn ">{t('PARTNER_SAVE_CHANGES')}</Button>
                                        }
                                        <Button onClick={() => updateExpenseAction('sendChange')} style={{ position: 'relative', right: '24px', marginLeft: '24px' }} className="w-180 h-40 f-14 font-w-700 primary-btn ">{t('SAVE_AND_SEND')}</Button>
                                        {/* <Button onClick={() => expenseDetailStep[0].editableStatus === 3 ? updateExpenseAction('saveChange') : updateExpenseAction('sendChange')} style={{ position: 'relative', right: '24px', marginLeft: '24px' }} className="w-180 h-40 f-14 font-w-700 primary-btn ">{t('SAVE_AND_SEND')}</Button> */}

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div >
            }
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        currencyTypes: state.dataset.currencyTypes,
        currencyWithId: state.expense.currencyWithId,
        creatExpenseRequests: state.expense.creatExpenseRequests,
        creatExpenseWithCashRequests: state.expense.creatExpenseWithCashRequests,
        profile: state.profile,
        openAdvanceList: state.expense.openAdvanceList.data,
        expenseDetails: state.expense.expenseDetails,
        expenseDetailsLoading: state.expense.expenseDetailsLoading,
        expenseChangeStatusResponse: state.expense.expenseChangeStatusResponse,
        expenseDetailStep: state.expense.expenseDetailStep,
        expenseDetailStepLoading: state.expense.expenseDetailStepLoading,
        detailExpenseRequests: state.expense.detailExpenseRequests,
        expenseExportAllFilesResponse: state.expense.expenseExportAllFilesResponse,
        updateExpenseProcessedResponse: state.expense.updateExpenseProcessedResponse,
        expenseEditRequestRepsonse: state.expense.expenseEditRequestRepsonse,
        expenseCurrencyExportDetailFilesResponse: state.expense.expenseCurrencyExportDetailFilesResponse,
        expenseManagerList: state.expense.expenseManagerList.results,
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getCurrencyWithId: (id) => { dispatch(getCurrencyWithId(id)) },
        getDataset: (type) => { dispatch(getDataset(type)) },
        setExpenseDetailsDrawer: (status) => { dispatch(setExpenseDetailsDrawer(status)) },
        resetCurrencyWithIdResponse: () => { dispatch(resetCurrencyWithIdResponse()) },
        getOpenAdvance: (resourceId, expenseResourceId) => { dispatch(getOpenAdvance(resourceId, expenseResourceId)) },
        expenseChangeStatus: (data, type) => { dispatch(expenseChangeStatus(data, type)) },
        resetExpenseChangeStatus: () => { dispatch(resetExpenseChangeStatus()) },
        getExpenseDetails: (resourceId) => { dispatch(getExpenseDetails(resourceId)) },
        getExpenseDetailsStep: (resourceId) => { dispatch(getExpenseDetailsStep(resourceId)) },
        updateExpenseWithCashAction: (data, resourceId) => { dispatch(updateExpenseWithCashAction(data, resourceId)) },
        updateExpenseWithCreditCardAction: (data, resourceId) => { dispatch(updateExpenseWithCreditCardAction(data, resourceId)) },
        updateExpenseWithCashActionReset: () => { dispatch(updateExpenseWithCashActionReset()) },
        expenseExportAllFiles: (data) => { dispatch(expenseExportAllFiles(data)) },
        resetExpenseExportAllFiles: () => { dispatch(resetExpenseExportAllFiles()) },
        expenseCurrencyExportAllFiles: (data) => { dispatch(expenseCurrencyExportAllFiles(data)) },
        resetExpenseCurrencyExportAllFiles: () => { dispatch(resetExpenseCurrencyExportAllFiles()) },
        updateExpenseProcessed: (resourceId, data) => { dispatch(updateExpenseProcessed(resourceId, data)) },
        updateExpenseProcessedReset: () => { dispatch(updateExpenseProcessedReset()) },
        submitEditRequestAction: (data) => { dispatch(submitEditRequestAction(data)) },
        submitEditRequestResetAction: () => { dispatch(submitEditRequestResetAction()) },
        resetExpenseDetailsState: () => { dispatch(resetExpenseDetailsState()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExpenseDetails);