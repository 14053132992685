import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Layout, Col, Card, Row, Spin, Button, Drawer, Image, Dropdown, Form, ConfigProvider, Input } from "antd";
import { Link } from "react-router-dom";
import { getPersonMe } from "../../redux/actions/person.actions";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { setPersonAddOpenDraw } from "../../redux/actions/person-detail-tab-action";
import { setDepartmentAddDraw, setOrganizationAddDrawer } from "../../redux/actions/person-organization-drawer-action";
import { getPersonWorkHours } from "../../redux/actions/organization.actions";
import { setTimeOffAddDrawer, setTimeOffRequestApproveDrawer } from "../../redux/actions/time-off-drawer-action";
import { getTimeOffRequestConfirmedPersons, getTimeOffRequestListByPerson, getTimeOffDashboardPaiChartData, getCongratulations, getPendingApprovalTimeOffRequest, getRequestsPendingMyApproval } from "../../redux/actions/dashboard.actions";
import { setAdvanceCreateDrawer } from "../../redux/actions/advance.actions";
import { setTravelRequestCreateDrawer } from "../../redux/actions/travel.actions";
import { setExpenseCreateDrawer } from "../../redux/actions/expense.action";
import { setTravelHostCreateDrawer } from "../../redux/actions/travelHost.actions";
import { searchTimeOffRequestManager, getTimeOffRequest, getTimeOffRequestStep, timeOffApprove, timeOffReject, timeOffCancel, deleteTimeOffRequestReset, resetTimeOffApproveResponseStatus, timeOffRequestUpdateReset } from "../../redux/actions/timeOff.actions";
import { setCreateTaskDrawer } from "../../redux/actions/task.actions";

import { UilPlaneDeparture, UilTrophy, UilGift, UilCalendarAlt, UilStar, UilArrowRight, UilCheckCircle, UilTimesCircle } from "@iconscout/react-unicons";
import { PlusOutlined } from "@ant-design/icons";
import { CheckboxIcon, WaitingIcon, ErrorIcon } from "../../features/Icon/Icons";
import { ToastContainer } from "react-toastify";

import { dateFormatConvert, timeFormat } from "../../utils/config";
import { drawerWidth } from "../../utils/config";
import { homePageDropdown } from "../../features/global-dropdown-menu/index";

import AddUserTabs from "../../component/personTabs/add-user/add-user-show-all-tabs";
import NewTimeOffRequestTabs from "../../component/timeOffTabs/add/time-off-all-tabs";
import CreateDepartmentTab from "../../component/departmentDrawerTabs/add/department-all-tabs";
import CreateTravelRequestTabs from "../../component/travelRequests/add/create_travel_all_tabs";
import CreateAdvanceTabs from "../../component/advanceTabs/add/create_advance_all_tabs";
import ExpenseAdd from "../../component/expenseTabs/add/expense_add";
import CreateTravelHostAllTab from "../../component/travelHost/add/travel_host_all_tabs";
import TimeOffAproveTabs from "../../component/timeOffTabs/timeOffRequestPerson/timeOffApproveDrawer/timeOffApproveTabs";
import OpenCreateTaskDrawer from "../../component/taskTabs/assignedTasks/add/create_task_all_tabs";

import DynamicToastMessage from "../../component/dynamicToastMessage/index";
import DynamicTimeOffModal from "../../component/dynamicTimeOffModal";
import DynamicRequestModal from "../../component/dynamicModal/requestModal";

import Can from "../../authorization/index";
import moment from "moment";

import "../../styles/dashboard.scss";

function HomePage(props) {
  const { Content } = Layout;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [cancelForm] = Form.useForm();
  const {tabreducer, setPersonAddOpenDraw, setDepartmentAddDraw, timeOffRequestConfirmedPersonLoading, 
    setTimeOffAddDrawer, profile, timeOffDrawerReducer, timeOffRequestConfirmedPersons, getTimeOffRequestConfirmedPersons,
    getCongratulations, congratulationsList, congratulationsLoading, getTimeOffRequestListByPerson,
    timeOffRequestListPersons, timeOffRequestListPersonLoading, getTimeOffDashboardPaiChartData, paiChartData, 
    paiChartLoading, getPendingApprovalTimeOffRequest, pendingApprovalTimeOffRequestLoading, returnShow, searchData,
    pendingApprovalTimeOffRequestData, requestsPendingMyApprovalData, requestsPendingMyApprovalLoading, 
    getRequestsPendingMyApproval, organizationTabreducer, getPersonWorkHours, permissions, setTravelRequestCreateDrawer,
    travelRequestDrawer, setAdvanceCreateDrawer, advanceCreateDrawer, setExpenseCreateDrawer, expenseCreateDrawer, 
    travelHostCreateDrawer, setTravelHostCreateDrawer, searchTimeOffManagerResult, searchTimeOffRequestManager,
    isSearchTimeOffManagerLoading, getTimeOffRequest, getTimeOffRequestStep, setTimeOffRequestApproveDrawer, 
    timeOffApprove, timeOffReject, timeOffCancel, deleteTimeOffRequestResponse, timeOffApproveResponse, 
    timeOffApproveUpdateStatus, deleteTimeOffRequestReset, resetTimeOffApproveResponseStatus, timeOffRequestUpdateReset, 
    getPersonMe, person, isPersonMeLoading, setCreateTaskDrawer, taskCreateDrawerStatus
  } = props;

  const currentDate = new Date();
  const [componentKeyPersonAdd, setComponentKeyPersonAdd] = useState(500);
  const [componentKeyDepartmentAdd, setComponentKeyDepartmentAdd] = useState(1500);
  const [componentKeyTimeoffAdd, setComponentKeyTimeoffAdd] = useState(2500);
  const [componentKeyCreateTravelRequest, setComponentKeyCreateTravelRequest] = useState(2900);
  const [componentKeyCreateAdvanceKey, setComponentKeyCreateAdvanceKey] = useState(3100);
  const [componentKeyCreateExpenseKey, setComponentKeyCreateExpenseKey] = useState(3400);
  const [componentKeyCreateTravelHostKey, setComponentKeyCreateTravelHostKey] = useState(3600);
  const [resetIndex, setResetIndex] = useState(false);
  const [selectedRowKeys, setselectedRowKeys] = useState([]);
  const [selectedRows, setselectedRows] = useState([]);
  const [componentKey, setcomponentKey] = useState(0);
  const [selectedData, setSelectedData] = useState({});
  const [cancelModalData, setCancelModalData] = useState({ isVisible: false, description: "" });
  const [ApproveModalData, setApproveModalData] = useState({ isVisible: false });
  const [modalView, setModalView] = useState(false);
  const [addUserPersonId, setAddUserPersonId] = useState("");
  const [taskCreateDrawerKey, setTaskCreateDrawerKey] = useState(10000);

  //locale(localStorage.getItem('i18nLang') === 'en' ? 'en' : 'tr')
  var startDate = moment(profile.hireDate, timeFormat());
  var endDate = moment(currentDate, timeFormat());
  var result = endDate.diff(startDate, "days");
  const key = "timeOffDrawerTabs";
  const searchManagerData = {
    searchTerm: "",
    timeOffTypes: [],
    persons: [],
    ShowConfirmButton: true,
    showPassiveRecords: true,
    stateTypes: ["ABX030nrkG7PwHc"],
    pagination: {
      pageNumber: 1,
      pageSize: 1000,
      orderBy: "fullName",
      ascending: true,
    },
  };
  useEffect(() => {
    getTimeOffRequestConfirmedPersons();
  }, []);
  useEffect(() => {
    getCongratulations();
  }, []);
  useEffect(() => {
    getTimeOffRequestListByPerson();
  }, []);
  useEffect(() => {
    getPendingApprovalTimeOffRequest();
  }, []);

  useEffect(() => {
    getPersonMe();
  }, []);
  useEffect(() => {
      if (!isPersonMeLoading && person.firstLogin == false) {
        setModalView(true)
      } else {
        setModalView(false)
      }
  }, [person,isPersonMeLoading])
  useEffect(() => {
    getRequestsPendingMyApproval();
  }, []);
  useEffect(() => {
    searchTimeOffRequestManager(searchManagerData);
  }, []);
  useEffect(() => {
    if (profile.resourceId !== undefined) {
      getTimeOffDashboardPaiChartData(profile.resourceId, "FH25DW56DYU5");
    }
  }, [profile.resourceId]);
  //
  const getIcon = (id) => {
    if (id === 1) {
      return <UilGift />;
    } else if (id === 2) {
      return <UilCalendarAlt />;
    } else if (id === 3) {
      return <UilStar />;
    } else {
      return id;
    }
  };
  const getCustomIcon = (id) => {
    if (id === 1) {
      return <WaitingIcon style={{ color: "transparent" }} />;
    } else if (id === 2) {
      return <CheckboxIcon style={{ color: "transparent" }} />;
    } else if (id === 4 || id === 3) {
      return <ErrorIcon style={{ color: "transparent" }} />;
    }
  };
  const dateRender = (data) => {
    var currentDate = moment()
      .locale(localStorage.getItem("i18nLang") === "en" ? "en" : "tr")
      .format("DD MMMM");
    var tomorrowDate = moment()
      .add(1, "days")
      .locale(localStorage.getItem("i18nLang") === "en" ? "en" : "tr")
      .format("DD MMMM");
    if (data === currentDate) {
      return t("TODAY");
    } else if (data === tomorrowDate) {
      return t("TOMORROW");
    } else {
      return data;
    }
  };
  const openCreateTaskDrawer = () => {
    setCreateTaskDrawer(true)
    setTaskCreateDrawerKey((i) => i + 1)
}

  useEffect(() => {
    if (tabreducer.personAddOpenDraw) {
      setComponentKeyPersonAdd((i) => i + 1);
    }
  }, [tabreducer]);

  useEffect(() => {
    if (organizationTabreducer.departmentAddDrawer) {
      setComponentKeyDepartmentAdd((i) => i + 1);
    }
  }, [organizationTabreducer]);

  useEffect(() => {
    if (timeOffDrawerReducer.timeOffRequestAddDrawer) {
      setComponentKeyTimeoffAdd((i) => i + 1);
    }
  }, [timeOffDrawerReducer]);

  const addDepartment = () => {
    setDepartmentAddDraw(true);
    getPersonWorkHours();
  };
  const addPerson = () => {
    setPersonAddOpenDraw(true);
    getPersonWorkHours();
  };
  const createTravelRequest = () => {
    setComponentKeyCreateTravelRequest((i) => i + 1);
    setTravelRequestCreateDrawer(true);
  };
  const createAdvanceRequest = () => {
    setAdvanceCreateDrawer(true);
    setComponentKeyCreateAdvanceKey((i) => i + 1);
  };
  const createExpenseRequest = () => {
    setExpenseCreateDrawer(true);
    setComponentKeyCreateExpenseKey((i) => i + 1);
  };
  const createTravelHostRequest = () => {
    setTravelHostCreateDrawer(true);
    setComponentKeyCreateTravelHostKey((i) => i + 1);
  };
  const showTimeOffRequestApproveDrawer = (id, requestDate, timeOfffTypeName, approveSeqNumber, fullName, postId) => {
    if (id !== undefined) {
      getTimeOffRequest(id);
      getTimeOffRequestStep(id);
      setSelectedData({ resourceId: id, requestDate: requestDate, timeOfffTypeName: timeOfffTypeName, approveSeqNumber: approveSeqNumber, fullName: fullName, postId: postId });
      setTimeOffRequestApproveDrawer(true);
      setcomponentKey((i) => i + 1);
    }
  };
  const handleTimeOffChangeStatus = (statusObj, decision, isApprovePage) => {
    if (decision === "APPROVE") {
      var modalDesc = "";
      if (statusObj.personResourceId === profile.resourceId || isApprovePage) {
        modalDesc = t("TIMEOFF_SINGLE_APPROVE_TEXT", { person: statusObj.fullName, requestDate: moment(statusObj.requestDate).format(dateFormatConvert), timeOffTypeName: statusObj.timeOffTypeName });
      } else {
        modalDesc = t("TIMEOFF_SINGLE_APPROVE_TEXT_NEW", { person: statusObj.fromName });
      }
      setApproveModalData({
        ...ApproveModalData,
        onModalCancel: handleCloseModal,
        icon: <UilCheckCircle className="dynamic-modal-icon" size={50} />,
        cancelBtnLbl: t("PARTNER_CANCEL"),
        cancelBtnFunc: handleCloseModal,
        confirmBtnLbl: t("TIME_OFF_REQUEST_CONFIRM"),
        confirmBtnFunc: "SINGLE_APPROVE",
        modalTitle: t("TIME_OFF_REQUEST_CONFIRM"),
        modalDesc: modalDesc,
        balanceDesc: statusObj?.timeOffTypeId === 1 ? t("TIMEOFF_SINGLE_APPROVE_BALANCE", { balance: statusObj?.timeOffInfoRemainderBalance }) : null,
        handleValuesChange: handleApproveFormValuesChange,
        isVisible: true,
        id: statusObj.id,
        approveSeqNumber: statusObj.approveSeqNumber,
        description: null,
        descriptionShow: true,
      });
    } else if (decision === "REJECT") {
      setApproveModalData({
        ...ApproveModalData,
        onModalCancel: handleCloseModal,
        icon: <UilTimesCircle className="dynamic-modal-icon" size={50} />,
        cancelBtnLbl: t("PARTNER_CANCEL"),
        cancelBtnFunc: handleCloseModal,
        confirmBtnLbl: t("TIME_OFF_REQUEST_REJECT"),
        confirmBtnFunc: "SINGLE_REJECT",
        modalTitle: t("TIME_OFF_REQUEST_REJECT"),
        modalDesc: t("TIMEOFF_SINGLE_REJECT_TEXT", { person: statusObj.fullName, requestDate: moment(statusObj.requestDate).format(dateFormatConvert), timeOffTypeName: statusObj.timeOffTypeName }),
        handleValuesChange: handleApproveFormValuesChange,
        isVisible: true,
        id: statusObj.id,
        approveSeqNumber: statusObj.approveSeqNumber,
        description: null,
        descriptionShow: true,
      });
    } else if (decision === "CANCEL") {
      setCancelModalData({ ...cancelModalData, isVisible: true });
    } else if (decision === "BULK_APPROVE") {
      if (selectedRowKeys.length > 0) {
        setApproveModalData({
          ...ApproveModalData,
          onModalCancel: handleCloseModal,
          icon: <UilCheckCircle className="dynamic-modal-icon" size={50} />,
          cancelBtnLbl: t("PARTNER_CANCEL"),
          cancelBtnFunc: handleCloseModal,
          confirmBtnLbl: t("TIME_OFF_REQUEST_CONFIRM"),
          confirmBtnFunc: "BULK_APPROVE",
          modalTitle: t("TIME_OFF_REQUEST_CONFIRM"),
          modalDesc: t("TIMEOFF_BULK_APPROVE_TEXT", { count: selectedRowKeys.length }),
          handleValuesChange: handleApproveFormValuesChange,
          isVisible: true,
          id: null,
          approveSeqNumber: null,
          description: null,
          descriptionShow: true,
        });
      } else {
        DynamicToastMessage({
          key: "BULK_APPROVE_NOT_SELECTED",
          title: t("WARNING"),
          description: t("NOT_SELECTED_ROW_WARNING"),
          statusType: "warning",
          position: "bottom-right",
        });
      }
    } else if (decision === "BULK_REJECT") {
      if (selectedRowKeys.length > 0) {
        setApproveModalData({
          ...ApproveModalData,
          onModalCancel: handleCloseModal,
          icon: <UilTimesCircle className="dynamic-modal-icon" size={50} />,
          cancelBtnLbl: t("PARTNER_CANCEL"),
          cancelBtnFunc: handleCloseModal,
          confirmBtnLbl: t("TIME_OFF_REQUEST_REJECT"),
          confirmBtnFunc: "BULK_REJECT",
          modalTitle: t("TIME_OFF_REQUEST_REJECT"),
          modalDesc: t("TIMEOFF_BULK_REJECT_TEXT", { count: selectedRowKeys.length }),
          handleValuesChange: handleApproveFormValuesChange,
          isVisible: true,
          id: null,
          approveSeqNumber: null,
          description: null,
          descriptionShow: true,
        });
      } else {
        DynamicToastMessage({
          key: "BULK_APPROVE_NOT_SELECTED",
          title: t("WARNING"),
          description: t("NOT_SELECTED_ROW_WARNING"),
          statusType: "warning",
          position: "bottom-right",
        });
      }
    }
  };
  const handleCloseModal = () => {
    setApproveModalData({ ...ApproveModalData, isVisible: false, description: null });
    form.setFieldsValue({ description: null });
    setCancelModalData({ isVisible: false, description: "" });
  };
  const handleApproveFormValuesChange = (changedObj, all) => {
    setApproveModalData((prevState) => ({
      ...prevState,
      description: changedObj["description"],
    }));
  };
  const handleConfirm = () => {
    if (ApproveModalData.confirmBtnFunc === "SINGLE_APPROVE") {
      const approveObj = {
        processStepList: [
          {
            timeOffRequestId: ApproveModalData.id,
            approveSeqNumber: ApproveModalData.approveSeqNumber,
            description: ApproveModalData.description,
          },
        ],
      };
      timeOffApprove({ approveData: approveObj, key: key });
    } else if (ApproveModalData.confirmBtnFunc === "SINGLE_REJECT") {
      const approveObj = {
        processStepList: [
          {
            timeOffRequestId: ApproveModalData.id,
            approveSeqNumber: ApproveModalData.approveSeqNumber,
            description: ApproveModalData.description,
          },
        ],
      };
      timeOffReject({ rejectData: approveObj, key: key });
    } else if (ApproveModalData.confirmBtnFunc === "BULK_APPROVE") {
      const approveObj = {};
      const processStepList = [];
      selectedRows.forEach((item) => {
        processStepList.push({
          timeOffRequestId: item?.stateType?.statusObj?.id,
          approveSeqNumber: item?.stateType?.statusObj?.approveSeqNumber,
          description: ApproveModalData.description,
        });
      });
      approveObj["processStepList"] = processStepList;
      timeOffApprove({ approveData: approveObj, key: key });
    } else if (ApproveModalData.confirmBtnFunc === "BULK_REJECT") {
      const approveObj = {};
      const processStepList = [];
      selectedRows.forEach((item) => {
        processStepList.push({
          timeOffRequestId: item?.stateType?.statusObj?.id,
          approveSeqNumber: item?.stateType?.statusObj?.approveSeqNumber,
          description: ApproveModalData.description,
        });
      });
      approveObj["processStepList"] = processStepList;
      timeOffReject({ rejectData: approveObj, key: key });
    } else if (ApproveModalData.confirmBtnFunc === "SINGLE_CANCEL") {
      timeOffCancel({ resourceId: selectedData.resourceId, key: key });
    }
  };
  const handleCancelConfirm = () => {
    timeOffCancel({ resourceId: cancelModalData?.id, key: key, description: cancelModalData.description });
  };
  const handleCancelExit = () => {
    setCancelModalData({ isVisible: false, description: "" });
    const fields = cancelForm.getFieldsValue();
    const projects = { ...fields };
    projects["description"] = "";
    cancelForm.setFieldsValue(projects);
  };
  const handleCancelFormValuesChange = (changedObj) => {
    setCancelModalData({ ...cancelModalData, description: changedObj.description });
  };
  const cancelFormModal = (
    <div className="table-details pad-0">
      <ConfigProvider getPopupContainer={(trigger) => trigger.parentElement}>
        <Form form={cancelForm} onValuesChange={handleCancelFormValuesChange} layout="vertical">
          <div className="form-left-body max-w-478">
            <Form.Item name="description">
              <Input.TextArea placeholder={t("TIMEOFF_MODAL_DESC_PLACEHOLDER")} />
            </Form.Item>
          </div>
        </Form>
      </ConfigProvider>
    </div>
  );
  useEffect(() => {
    if (deleteTimeOffRequestResponse?.status === "SUCCESS") {
      DynamicToastMessage({
        key: "DELETE_SUCCESS",
        title: t("SUCCESS"),
        description: deleteTimeOffRequestResponse?.message,
        statusType: "success",
        position: "bottom-right",
      });
      searchTimeOffRequestManager(searchManagerData);
      deleteTimeOffRequestReset();
    } else if (deleteTimeOffRequestResponse?.status === "FAIL") {
      DynamicToastMessage({
        key: "DELETE_FAIL",
        title: t("ERROR"),
        description: deleteTimeOffRequestResponse?.message,
        statusType: "error",
        position: "bottom-right",
      });
      deleteTimeOffRequestReset();
    }
  }, [deleteTimeOffRequestResponse]);
  useEffect(() => {
    if (timeOffApproveResponse.status === "SUCCESS") {
      DynamicToastMessage({
        key: "APPROVE_SUCCESS",
        title: t("SUCCESS"),
        description: timeOffApproveResponse.message,
        statusType: "success",
        position: "bottom-right",
      });
      if (timeOffApproveResponse.resourceId !== undefined) {
        getTimeOffRequest(timeOffApproveResponse.resourceId);
        getTimeOffRequestStep(timeOffApproveResponse.resourceId);
      }
      resetTimeOffApproveResponseStatus();
      searchTimeOffRequestManager(searchManagerData);
      handleCloseModal();
      setselectedRowKeys([]);
      setselectedRows([]);
    } else if (timeOffApproveResponse.status === "FAIL") {
      DynamicToastMessage({
        key: "timeOffApproveResponseStatus_fail",
        title: t("ERROR"),
        description: timeOffApproveResponse.message,
        statusType: "error",
        position: "bottom-right",
      });
      resetTimeOffApproveResponseStatus();
      timeOffRequestUpdateReset();
    }
  }, [timeOffApproveResponse]);

  useEffect(() => {
    if (timeOffApproveUpdateStatus.status === "SUCCESS") {
      searchTimeOffRequestManager(searchManagerData);
      setselectedRowKeys([]);
      setselectedRows([]);
      timeOffRequestUpdateReset();
    }
  }, [timeOffApproveUpdateStatus]);

  const handleCancelSlideModal = () => {
    setModalView(false);
    setResetIndex(false);
  };
  const changeModal = () => {
    setModalView(true);
    setResetIndex(true);
  };

  return (
    <Layout>
      <Drawer title={false} width={875} placement="right" closable={false} visible={tabreducer.personAddOpenDraw}>
        <AddUserTabs key={componentKeyPersonAdd} returnShow={false} openCreateTaskDrawer={openCreateTaskDrawer} addUserPersonId={addUserPersonId} setAddUserPersonId={setAddUserPersonId}/>
      </Drawer>
      <Drawer title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={organizationTabreducer.departmentAddDrawer}>
        {" "}
        {/* EKLE DRAWI */}
        <CreateDepartmentTab key={componentKeyDepartmentAdd} returnShow={returnShow} searchData={searchData} />
      </Drawer>
      <Drawer title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={timeOffDrawerReducer.timeOffRequestAddDrawer}>
        <NewTimeOffRequestTabs key={componentKeyTimeoffAdd} returnShow={false} />
      </Drawer>
      <Drawer title={false} width={1280} key={componentKeyCreateTravelRequest} onClose={false} closable={false} placement="right" visible={travelRequestDrawer}>
        <CreateTravelRequestTabs page="home" />
      </Drawer>
      <Drawer title={false} width={drawerWidth} key={componentKeyCreateAdvanceKey} onClose={false} closable={false} placement="right" visible={advanceCreateDrawer}>
        <CreateAdvanceTabs page="home" />
      </Drawer>
      <Drawer key={componentKeyCreateExpenseKey} title={false} width={1550} closable={false} onClose={false} placement="right" visible={expenseCreateDrawer}>
        <ExpenseAdd page="home" />
      </Drawer>
      <Drawer key={componentKeyCreateTravelHostKey} title={false} width={1280} closable={false} onClose={false} placement="right" visible={travelHostCreateDrawer}>
        <CreateTravelHostAllTab page="home" />
      </Drawer>
      <Drawer key={"detail" + componentKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={timeOffDrawerReducer.timeOffRequestApproveDrawer}>
        <TimeOffAproveTabs selectedData={selectedData} handleTimeOffChangeStatus={handleTimeOffChangeStatus} page="timeOffMy" />
      </Drawer>
      <Drawer key={taskCreateDrawerKey} title={false} width={drawerWidth} closable={false} onClose={false} placement="right" visible={taskCreateDrawerStatus} >
          <OpenCreateTaskDrawer personId={addUserPersonId} />
      </Drawer>
      <DynamicTimeOffModal form={form} ApproveModalData={ApproveModalData} handleConfirm={() => handleConfirm()} />
      <DynamicRequestModal modalVisible={cancelModalData?.isVisible} setStateData={setCancelModalData} handleConfirm={handleCancelConfirm} handleCancel={handleCancelExit} isForm={cancelFormModal} iconName="UilTimesCircle" modalTitle={t("TIME_OFF_CANCEL_BTN")} description={t("")} cancelBtnTxt={t("PARTNER_CANCEL")} successBtnTxt={t("PARTNER_YES_CANCEL")} />
      <Layout>
        <Content className="page-container pt-10">
          <Button onClick={changeModal} className="d-none primary-btn m-w-220 h-32 margin-r-24 mb-10">
            {t("PROMOTIONAL_TOUR")}
          </Button>
          <div className="tab-page">
            <h1 className="page-title">{t("HOMEPAGE")}</h1>
          </div>
          {result === 0 
          ? <p className="secondary-dark-text f-18 font-w-700 pt-1">{t("HOMEPAGE_HELLO_TITLE_FIRST_DAY", { name: profile.givenName, organizationName: profile.organization?.name })} </p> 
          : isNaN(result) ? <div className="py-1"></div> : <p className="secondary-dark-text f-18 font-w-700 pt-1">{t("HOMEPAGE_HELLO_TITLE", { name: profile.givenName, organizationName: profile.organization?.name, workTime: profile?.workDate })} </p>}
          <div className="dashboard-action-button-class">
            <Dropdown className="action-menu" overlay={homePageDropdown(t, permissions, setTimeOffAddDrawer, createTravelRequest, createAdvanceRequest, createExpenseRequest, createTravelHostRequest)} trigger={["click"]}>
              <Button onClick={(e) => e.preventDefault()} className="primary-btn m-w-220 h-32 margin-r-24 mb-10" icon={<PlusOutlined />}>
                {t("CREATE_NEW_REQUEST")}
              </Button>
            </Dropdown>
            <Can I="department.add">
              <Button onClick={() => addDepartment()} className="white-btn m-w-200 h-32 f-14  margin-r-24 mb-10" icon={<PlusOutlined />}>
                {" "}
                {t("CREATE_NEW_DEPARTMENTS")}
              </Button>
            </Can>
            <Can I="person.add">
              <Button onClick={() => addPerson()} className="white-btn m-w-200 h-32 f-14  margin-r-24 mb-10" icon={<PlusOutlined />}>
                {t("PARTNER_CREATE_NEW_PERSON")}
              </Button>
            </Can>
          </div>
          <div className="dashobard-card-container">
            <Row gutter={[24, 32]}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xxl={{ span: 12 }}>
                <Card className="dashboard-card" key={1}>
                  <div className="dashboard-card-title pad-6">
                    <UilPlaneDeparture />
                    <h1>{t("MY_TIME_OFF_REQUESTS2")}</h1>
                  </div>
                  <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: requestsPendingMyApprovalData.length !== 0 ? 8 : 12 }} lg={{ span: requestsPendingMyApprovalData.length !== 0 ? 8 : 12 }} xl={{ span: requestsPendingMyApprovalData.length !== 0 ? 8 : 12 }} xxl={{ span: requestsPendingMyApprovalData.length !== 0 ? 8 : 12 }}>
                      {paiChartLoading ? (
                        <Spin className="flex-center w-100 pad-top-75" />
                      ) : (
                        <div className="dashboard-pai-chart-container">
                          {paiChartData.map((data, index) => {
                            //Yüzdelik oranı
                            var minus = data.remainingValue * 100;
                            var result = minus / data.maxBalance;
                            return (
                              <div key={index} className="dashboard-pai-chart">
                                <CircularProgressbarWithChildren
                                  value={result}
                                  strokeWidth={20}
                                  styles={buildStyles({
                                    pathColor: "#0ACF83",
                                    trailColor: "#DDDDDD",
                                  })}
                                  counterClockwise
                                >
                                  <h1 className="pai-chart-total">{data.remainingValue} </h1>
                                </CircularProgressbarWithChildren>
                                <h1 className="pai-chart-remain">{t("HOMEPAGE_ANNUAL_LEAVE")}</h1>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: requestsPendingMyApprovalData.length !== 0 ? 8 : 12 }} lg={{ span: requestsPendingMyApprovalData.length !== 0 ? 8 : 12 }} xl={{ span: requestsPendingMyApprovalData.length !== 0 ? 8 : 12 }} xxl={{ span: requestsPendingMyApprovalData.length !== 0 ? 8 : 12 }}>
                      {pendingApprovalTimeOffRequestLoading ? (
                        <Spin className="flex-center w-100 pad-top-75" />
                      ) : (
                        <div className="dashboard-pai-chart-container">
                          <div className="dashboard-pai-chart">
                            <CircularProgressbarWithChildren
                              value={100}
                              strokeWidth={20}
                              styles={buildStyles({
                                pathColor: "#FFB237",
                                trailColor: "#DDDDDD",
                              })}
                              counterClockwise
                            >
                              <h1 className="pai-chart-total">{pendingApprovalTimeOffRequestData} </h1>
                            </CircularProgressbarWithChildren>
                            <h1 className="pai-chart-remain">{t("PENDING_TIME_OFF_REQUEST")}</h1>
                          </div>
                        </div>
                      )}
                    </Col>
                    {requestsPendingMyApprovalData.length !== 0 ? (
                      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: requestsPendingMyApprovalData.length !== 0 ? 8 : 12 }} lg={{ span: requestsPendingMyApprovalData.length !== 0 ? 8 : 12 }} xl={{ span: requestsPendingMyApprovalData.length !== 0 ? 8 : 12 }} xxl={{ span: requestsPendingMyApprovalData.length !== 0 ? 8 : 12 }}>
                        {requestsPendingMyApprovalLoading ? (
                          <Spin className="flex-center w-100 pad-top-75" />
                        ) : (
                          <div className="dashboard-pai-chart-container">
                            <div className="dashboard-pai-chart">
                              <CircularProgressbarWithChildren
                                value={100}
                                strokeWidth={20}
                                styles={buildStyles({
                                  pathColor: "#45B8AC",
                                  trailColor: "#DDDDDD",
                                })}
                                counterClockwise
                              >
                                <h1 className="pai-chart-total">{requestsPendingMyApprovalData} </h1>
                              </CircularProgressbarWithChildren>
                              <h1 className="pai-chart-remain pb-0 mb-0">{t("EMPLOYEED_TIME_OFF_REQUEST")}</h1>
                              <Link to="/time-off/person">
                                <span className="pad-0 justify-center f-14 d-flex blue">{t("SHOW_ALL")}</span>
                              </Link>
                            </div>
                          </div>
                        )}
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 24 }} xxl={{ span: 6 }}>
                <Card className="dashboard-card">
                  <div className="dashboard-card-title">
                    <UilPlaneDeparture />
                    <h1>{t("MY_TIME_OFF_REQUESTS")}</h1>
                  </div>
                  {timeOffRequestListPersonLoading ? (
                    <Spin className="flex-center w-100 pad-top-75" />
                  ) : (
                    <div className="dashboard-time-off-request-card-table">
                      {timeOffRequestConfirmedPersonLoading ? (
                        <Spin className="flex-center w-100 pad-top-75" />
                      ) : (
                        <div className="w-100">
                          {timeOffRequestListPersons.length > 0 ? (
                            timeOffRequestListPersons.map((data, index) => {
                              var timeOffStart = data.startDate ? moment(data.startDate).format(dateFormatConvert) : "";
                              return (
                                <div key={index} className="dashboard-timeOff-card">
                                  <div className="dashboard-card-table-custom-icon">{getCustomIcon(data.stateTypeSetIdx)}</div>
                                  <span className="dashboard-card-table-text">
                                    {timeOffStart} - {data.timeOffTypeName} - {data.stateTypeName}
                                  </span>
                                </div>
                              );
                            })
                          ) : (
                            <span className="dashboard-card-table-text">{t("NO_PENDING_PERMISSION_REQUESTS")}</span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="dashboard-card-all-view">
                    <Link to="/time-off/me">
                      <span>
                        {t("SHOW_ALL")} <UilArrowRight />
                      </span>
                    </Link>
                  </div>
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 24 }} xxl={{ span: 6 }}>
                <Card className="dashboard-card">
                  <div className="dashboard-card-title">
                    <UilPlaneDeparture />
                    <h1>{t("TIME_OFF_REQUEST_PERSON")}</h1>
                  </div>
                  {isSearchTimeOffManagerLoading ? (
                    <Spin className="flex-center w-100 pad-top-75" />
                  ) : (
                    <div className="dashboard-time-off-request-card-table">
                      <div className="w-100">
                        {searchTimeOffManagerResult.length > 0 ? (
                          searchTimeOffManagerResult.map((data, index) => {
                            var timeOffStart = data.startDate ? moment(data.startDate).format(dateFormatConvert) : "";

                            return (
                              <div key={index} className="dashboard-timeOff-card">
                                <div className="dashboard-card-table-custom-icon">{getCustomIcon(parseInt(data.stateType?.id))}</div>
                                <a className="dashboard-card-table-text" onClick={() => showTimeOffRequestApproveDrawer(data.resourceId, data.requestDate, data.timeOffTypeName, data.approveSeqNumber, data.fullName, data.id)}>
                                  {data.fullName} - {timeOffStart} - {data.timeOffTypeName}
                                </a>
                              </div>
                            );
                          })
                        ) : (
                          <span className="dashboard-card-table-text">{t("NO_PENDING_PERMISSION_APPROVAL")}</span>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="dashboard-card-all-view">
                    <Link to="/time-off/person">
                      <span>
                        {t("SHOW_ALL")} <UilArrowRight />
                      </span>
                    </Link>
                  </div>
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xxl={{ span: 12 }}>
                <Card className="dashboard-card">
                  <div className="dashboard-card-title">
                    <UilPlaneDeparture />
                    <h1>{t("PEOPLE_ON_LEAVE2")}</h1>
                  </div>
                  <div className="dashboard-card-table">
                    {timeOffRequestConfirmedPersonLoading ? (
                      <Spin className="flex-center w-100 pad-top-75" />
                    ) : (
                      <div className="w-100">
                        {timeOffRequestConfirmedPersons.length > 0 ? (
                          timeOffRequestConfirmedPersons.map((data, index) => {
                            var timeOffStart = data.startDate ? moment(data.startDate).format("DD MMMM YYYY") : "";
                            var timeOffEnd = data.endDate ? moment(data.endDate).format(dateFormatConvert) : "";
                            return (
                              <Row key={index}>
                                <Col className="mt-2" xs={{ span: 5 }} sm={{ span: 3 }} md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }} xxl={{ span: 2 }}>
                                  <Image src={data.photo ? data.photo : process.env.REACT_APP_URL + "images/avatar_" + data?.genderIdx + ".png"} className="dashboard-card-table-image" preview={false} />
                                </Col>
                                <Col className="mt-2" xs={{ span: 12, offset: 1 }} sm={{ span: 11, offset: 1 }} md={{ span: 6, offset: 1 }} lg={{ span: 6 }} xl={{ span: 6 }} xxl={{ span: 6, offset: 0 }}>
                                  <span className="dashboard-card-table-title ml-2">{data.personName}</span>
                                </Col>
                                <Col className="mt-2" xs={{ span: 6 }} sm={{ span: 5 }} md={{ span: 3 }} lg={{ span: 2 }} xl={{ span: 3 }} xxl={{ span: 3 }}>
                                  <span className="dashboard-card-table-text dashboard-employee-text">{data.name}</span>
                                </Col>
                                <Col className="mt-2" xs={{ span: 6 }} sm={{ span: 4 }} md={{ span: 2, offset: 1 }} lg={{ span: 2 }} xl={{ span: 2 }} xxl={{ span: 2 }}>
                                  <span className="dashboard-card-table-text dashboard-employee-text">{t("TIME_OFF_REQUEST_DAYS", { day: data.balance })}</span>
                                </Col>
                                <Col className="mt-2" xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 8, offset: 1 }} lg={{ span: 8 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
                                  <span className="dashboard-card-table-text dashboard-employee-text">
                                    {timeOffStart} - {timeOffEnd}
                                  </span>
                                </Col>
                              </Row>
                            );
                          })
                        ) : (
                          <span className="dashboard-card-table-text">{t("NO_EMPLOYEES_ON_LEAVE_TODAY")}</span>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="dashboard-card-all-view">
                    <Link to="/time-off/people">
                      <span>
                        {t("SHOW_ALL")} <UilArrowRight />
                      </span>
                    </Link>
                  </div>
                </Card>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xxl={{ span: 12 }}>
                <Card className="dashboard-card" key={4}>
                  <div className="dashboard-card-title">
                    <UilTrophy />
                    <h1>{t("HOMEPAGE_CONGATULATIONS")}</h1>
                  </div>
                  <div className="dashboard-card-table">
                    {congratulationsLoading ? (
                      <Spin className="flex-center w-100 pad-top-75" />
                    ) : (
                      <div className="w-100">
                        {congratulationsList.map((data, index) => {
                          return (
                            <Row key={index}>
                              <Col xs={{ span: 3 }} sm={{ span: 2 }} md={{ span: 1 }} lg={{ span: 1 }} xl={{ span: 1 }} xxl={{ span: 1 }}>
                                <div className="dashboard-card-table-icon">{getIcon(data.statusId)}</div>
                              </Col>
                              <Col xs={{ span: 4 }} sm={{ span: 3 }} md={{ span: 3 }} lg={{ span: 2 }} xl={{ span: 2 }} xxl={{ span: 2 }}>
                                <Image src={data.photo ? data.photo : process.env.REACT_APP_URL + "images/avatar_" + data?.genderIdx + ".png"} className="dashboard-card-table-image" preview={false} />
                              </Col>
                              <Col xs={{ span: 16, offset: 1 }} sm={{ span: 10, offset: 1 }} md={{ span: 8 }} lg={{ span: 9 }} xl={{ span: 9 }} xxl={{ span: 9 }}>
                                <span className="dashboard-card-table-title">{data.personName}</span>
                              </Col>
                              <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xxl={{ span: 5 }}>
                                <span className="dashboard-card-table-text">{data.status}</span>
                              </Col>
                              <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xxl={{ span: 5 }}>
                                <span className="dashboard-card-table-text">{dateRender(data.DateInfo)}</span>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          <ToastContainer />
        </Content>
      </Layout>
    </Layout>
  );
}
const mapStateToProps = (state) => {
  return {
    organizationTabreducer: state.organizationTabreducer,
    tabreducer: state.tabreducer,
    timeOffDrawerReducer: state.timeOffDrawerReducer,
    timeOffRequestConfirmedPersons: state.dashboard.timeOffRequestConfirmedPersons,
    timeOffRequestConfirmedPersonLoading: state.dashboard.timeOffRequestConfirmedPersonLoading,
    congratulationsList: state.dashboard.congratulationsList,
    congratulationsLoading: state.dashboard.congratulationsLoading,
    timeOffRequestListPersons: state.dashboard.timeOffRequestListPersons,
    timeOffRequestListPersonLoading: state.dashboard.timeOffRequestListPersonLoading,
    pendingApprovalTimeOffRequestLoading: state.dashboard.pendingApprovalTimeOffRequestLoading,
    pendingApprovalTimeOffRequestData: state.dashboard.pendingApprovalTimeOffRequestData,
    profile: state.profile,
    paiChartData: state.dashboard.timeOffPaiChartData,
    paiChartLoading: state.dashboard.timeOffPaiChartDataLoading,
    requestsPendingMyApprovalData: state.dashboard.requestsPendingMyApprovalData,
    requestsPendingMyApprovalLoading: state.dashboard.requestsPendingMyApprovalLoading,
    permissions: state.profile.permissions,
    travelRequestDrawer: state.travel.travelRequestDrawer,
    advanceCreateDrawer: state.advance.advanceCreateDrawer,
    expenseCreateDrawer: state.expense.expenseCreateDrawer,
    travelHostCreateDrawer: state.travelHostReducer.travelHostCreateDrawer,
    searchTimeOffManagerResult: state.timeOff.searchTimeOffManagerResult.results,
    isSearchTimeOffManagerLoading: state.timeOff.isSearchTimeOffManagerLoading,
    deleteTimeOffRequestResponse: state.timeOff.deleteTimeOffRequestResponse,
    timeOffApproveResponse: state.timeOff.timeOffApproveResponse,
    timeOffApproveUpdateStatus: state.timeOff.timeOffApproveUpdateStatus,
    person: state.person.personMe,
    isPersonMeLoading: state.person.isPersonMeLoading,
    taskCreateDrawerStatus: state.taskReducer.taskCreateDrawerStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPersonAddOpenDraw: (props) => { dispatch(setPersonAddOpenDraw(props)); },
    setDepartmentAddDraw: (props) => { dispatch(setDepartmentAddDraw(props)); },
    setOrganizationAddDrawer: (props) => { dispatch(setOrganizationAddDrawer(props)); },
    setTimeOffAddDrawer: (props) => { dispatch(setTimeOffAddDrawer(props)); },
    getTimeOffDashboardPaiChartData: (personResourceId, timeOffResourceId) => { dispatch(getTimeOffDashboardPaiChartData(personResourceId, timeOffResourceId)); },
    getTimeOffRequestConfirmedPersons: () => { dispatch(getTimeOffRequestConfirmedPersons()); },
    getCongratulations: () => { dispatch(getCongratulations()); },
    getTimeOffRequestListByPerson: () => { dispatch(getTimeOffRequestListByPerson()); },
    getPendingApprovalTimeOffRequest: () => { dispatch(getPendingApprovalTimeOffRequest()); },
    getRequestsPendingMyApproval: () => { dispatch(getRequestsPendingMyApproval()); },
    getPersonWorkHours: () => { dispatch(getPersonWorkHours()); },
    setTravelRequestCreateDrawer: (status) => { dispatch(setTravelRequestCreateDrawer(status)); },
    setAdvanceCreateDrawer: (status) => { dispatch(setAdvanceCreateDrawer(status)); },
    setExpenseCreateDrawer: (status) => { dispatch(setExpenseCreateDrawer(status)); },
    setTravelHostCreateDrawer: (status) => { dispatch(setTravelHostCreateDrawer(status)); },
    searchTimeOffRequestManager: (searchManagerData) => { dispatch(searchTimeOffRequestManager(searchManagerData)); },
    getTimeOffRequest: (resourceId) => { dispatch(getTimeOffRequest(resourceId)); },
    getTimeOffRequestStep: (resourceId) => { dispatch(getTimeOffRequestStep(resourceId)); },
    setTimeOffRequestApproveDrawer: (props) => { dispatch(setTimeOffRequestApproveDrawer(props)); },
    timeOffApprove: (approveData) => { dispatch(timeOffApprove(approveData)); },
    timeOffReject: (approveData) => { dispatch(timeOffReject(approveData)); },
    timeOffCancel: (resourceId) => { dispatch(timeOffCancel(resourceId)); },
    deleteTimeOffRequestReset: () => { dispatch(deleteTimeOffRequestReset()); },
    resetTimeOffApproveResponseStatus: () => { dispatch(resetTimeOffApproveResponseStatus()); },
    timeOffRequestUpdateReset: () => { dispatch(timeOffRequestUpdateReset()); },
    getPersonMe: () => { dispatch(getPersonMe()); },
    setCreateTaskDrawer: (status) => { dispatch(setCreateTaskDrawer(status)) }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
