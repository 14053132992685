import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx-js-style';
import moment from 'moment';

const ExpenseExcelGenerator = () => {
    const { t } = useTranslation();

    const formatNumber = (value) => {
        if (value === null || value === undefined) return '-';
        return typeof value === 'number' ? value.toLocaleString('tr-TR', { minimumFractionDigits: 2 }) : value;
    };

    // Define common styles
    const commonStyle = {
        alignment: { horizontal: "center", vertical: "center", wrapText: true },
        border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } }
        }
    };

    const headerStyle = {
        ...commonStyle,
        font: { sz: 11, bold: true }
    };

    const rowStyle = {
        ...commonStyle,
        font: { sz: 11 }
    };

    const generateExcel = (data) => {
        try {
            const wb = XLSX.utils.book_new();
            
            // Header information
            const headerInfo = [
                [{ v: t('PDF_REQUESTER'), s: headerStyle }, { v: data.person?.displayValue || "-", s: rowStyle }],
                [{ v: t('PDF_REQUEST_DATE'), s: headerStyle }, { v: data.createdDate ? moment(data.createdDate).format('DD.MM.YYYY HH:mm') : "-", s: rowStyle }],
                [{ v: t('PDF_APPROVAL_DATE'), s: headerStyle }, { v: data.lastConfirmDate ? moment(data.lastConfirmDate).format('DD.MM.YYYY HH:mm') : "-", s: rowStyle }],
                [{ v: t('PDF_EXPENSE_TYPE'), s: headerStyle }, { v: data.expenseMasterTypeId === 1 ? t('PDF_CASH_EXPENSE') : t('PDF_CREDIT_CARD_EXPENSE'), s: rowStyle }],
                [{ v: t('PDF_DESCRIPTION'), s: headerStyle }, { v: data.description || "-", s: rowStyle }],
                [{ v: t('PDF_SPECIAL_FIELD'), s: headerStyle }, { v: data.additionalField || "-", s: rowStyle }],
                [{ v: "", s: rowStyle }],
                [{ v: t('PDF_ADVANCE_RETURN_INFO'), s: headerStyle }],
                [{ v: t('PDF_ADVANCE_INFO'), s: headerStyle }, { v: t('PDF_RECEIVED'), s: headerStyle }, { v: t('PDF_RETURN'), s: headerStyle }, { v: t('PDF_EXCHANGE_RATE'), s: headerStyle }, { v: t('PDF_ADVANCE_DEBT'), s: headerStyle }]
            ];

            // Add return amount list information
            if (data.returnAmountList && data.returnAmountList.length > 0) {
                data.returnAmountList.forEach(item => {
                    headerInfo.push([
                        { v: item.currencyName || "-", s: rowStyle },
                        { v: formatNumber(item.remainingAdvance) || "-", s: rowStyle },
                        { v: formatNumber(item.returnAmount) || "-", s: rowStyle },
                        { v: formatNumber(item.exchangeRate) || "-", s: rowStyle },
                        { v: formatNumber(item.totalAdvance) || "-", s: rowStyle },

                    ]);
                });
            } else {
                headerInfo.push([
                    { v: "-", s: rowStyle },
                    { v: "-", s: rowStyle },
                    { v: "-", s: rowStyle },
                    { v: "-", s: rowStyle },
                    { v: "-", s: rowStyle }
                ]);
            }

            headerInfo.push(
                [{ v: "", s: rowStyle }],
                [{ v: t('PDF_EXPENSES'), s: headerStyle }],
                [
                    { v: t('PDF_DATE'), s: headerStyle },
                    { v: t('PDF_GROUP'), s: headerStyle },
                    { v: t('PDF_DESCRIPTION'), s: headerStyle },
                    { v: t('PDF_AMOUNT'), s: headerStyle },
                    { v: t('PDF_CURRENCY'), s: headerStyle },
                    { v: t('PDF_VAT'), s: headerStyle },
                    { v: t('PDF_TL_AMOUNT'), s: headerStyle },
                    { v: t('PDF_VAT_EXCLUDE'), s: headerStyle },
                    { v: t('PDF_VAT_AMOUNT'), s: headerStyle },
                    { v: t('PDF_INVOICE_NO'), s: headerStyle },
                    { v: t('PDF_INVOICE_TITLE'), s: headerStyle }
                ]
            );

            // Create worksheet
            const ws = XLSX.utils.aoa_to_sheet(headerInfo);

            // Format expense details
            const details = (data.expenseDetails || []).map(detail => {
                const kdvHaric = detail.amountTL / (1 + (detail.vat / 100));
                const kdvTutar = detail.amountTL - kdvHaric;

                return [
                    { v: moment(detail.expenseDate).format('DD.MM.YYYY'), s: rowStyle },
                    { v: detail.expenseType?.displayValue || "-", s: rowStyle },
                    { v: detail.description || "-", s: rowStyle },
                    { v: formatNumber(detail.expenseAmount), s: rowStyle },
                    { v: detail.currency?.displayValue || "-", s: rowStyle },
                    { v: detail.vat ? detail.vat : "0", s: rowStyle },
                    { v: formatNumber(detail.amountTL), s: rowStyle },
                    { v: formatNumber(kdvHaric), s: rowStyle },
                    { v: formatNumber(kdvTutar), s: rowStyle },
                    { v: detail.invoiceNo || "-", s: rowStyle },
                    { v: detail.invoiceTitle || "-", s: rowStyle }
                ];
            });

            // Add expense details
            if (details.length > 0) {
                XLSX.utils.sheet_add_aoa(ws, details, { origin: 'A14' });
            }

            let lastRow = 14 + (details.length || 0) + 1;

            // Add currency exchange section
            const exchangeHeaders = [
                [{ v: t('PDF_EXCHANGE_EXPENSE'), s: headerStyle }],
                [
                    { v: t('PDF_EXCHANGE_DATE'), s: headerStyle },
                    { v: t('PDF_EXCHANGE_AMOUNT'), s: headerStyle },
                    { v: t('PDF_EXCHANGE_CURRENCY'), s: headerStyle },
                    { v: t('PDF_CONVERT_AMOUNT'), s: headerStyle },
                    { v: t('PDF_CONVERT_CURRENCY'), s: headerStyle },
                    { v: t('PDF_EXCHANGE_EXPENSE_AMOUNT'), s: headerStyle },
                    { v: t('PDF_INVOICE_NO'), s: headerStyle },
                    { v: t('PDF_INVOICE_TITLE'), s: headerStyle }
                ]
            ];

            XLSX.utils.sheet_add_aoa(ws, exchangeHeaders, { origin: `A${lastRow}` });

            // Add exchange details if they exist
            if (data.exchanceExpense && data.exchanceExpense.length > 0) {
                const exchangeDetails = data.exchanceExpense.map(exchange => [
                    { v: moment(exchange.exchangeDate).format('DD.MM.YY'), s: rowStyle },
                    { v: formatNumber(exchange.exchangeAmount), s: rowStyle },
                    { v: exchange.exchangeCurrencyName || "-", s: rowStyle },
                    { v: formatNumber(exchange.convertAmount), s: rowStyle },
                    { v: exchange.convertCurrencyName || "-", s: rowStyle },
                    { v: formatNumber(exchange.exchangeExpense), s: rowStyle },
                    { v: exchange.invoiceNumber || "-", s: rowStyle },
                    { v: exchange.invoiceTitle || "-", s: rowStyle }
                ]);
                XLSX.utils.sheet_add_aoa(ws, exchangeDetails, { origin: `A${lastRow + 2}` });
                lastRow += exchangeDetails.length + 2;
            } else {
                lastRow += 2;
            }

            // Add totals section
            const totalRow = lastRow + 1;
            const totals = [
                [
                    { v: t('PDF_NET_ADVANCE_TOTAL'), s: headerStyle },
                    { v: t('PDF_EXPENSE_TOTAL'), s: headerStyle },
                    { v: t('PDF_TOTAL_EXCHANGE'), s: headerStyle },
                    { v: t('PDF_DIFFERENCE'), s: headerStyle }
                ],
                [
                    { v: formatNumber(data.totalAdvanceAmount || 0) + " TL", s: rowStyle },
                    { v: formatNumber(data.totalExpenseAmount || 0) + " TL", s: rowStyle },
                    { v: formatNumber(data.totalExchangeAmount || 0) + " TL", s: rowStyle },
                    { v: formatNumber(data.differentAmount || 0) + " TL", s: rowStyle }
                ]
            ];

            XLSX.utils.sheet_add_aoa(ws, totals, { origin: `A${totalRow}` });

            // Set column widths
            const wscols = [
                {wch: 30},  // Tarih
                {wch: 15},  // Grup
                {wch: 20},  // Açıklama
                {wch: 15},  // Tutar
                {wch: 15},  // Para Birimi
                {wch: 10},  // KDV
                {wch: 15},  // TL Tutar
                {wch: 15},  // KDV Hariç
                {wch: 15},  // KDV Tutar
                {wch: 15},  // Fiş No
                {wch: 20},  // Fiş Unvanı
            ];
            ws['!cols'] = wscols;

            // Add worksheet to workbook
            XLSX.utils.book_append_sheet(wb, ws, t('PDF_EXPENSE_DETAILS'));

            // Generate Excel file
            XLSX.writeFile(wb, `expense-details-${data.resourceId || 'report'}.xlsx`);

        } catch (error) {
            console.error('Excel generation error:', error);
        }
    };

    return { generateExcel };
};

export default ExpenseExcelGenerator;