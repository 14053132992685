import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    getAllExpenseRequests, getExpenseDetails, expenseChangeStatus, getExpenseDetailsStep, expenseExportDetailPage, expenseExportTable,
    resetExpenseExportTable
} from '../../redux/actions/expense.action';
import { Button, Dropdown, Form, Input, ConfigProvider } from 'antd';
import { UilCheckCircle, UilMinusCircle, UilImport } from '@iconscout/react-unicons';
import { MoreOutlined } from '@ant-design/icons';

import Media from 'react-media';
import moment from 'moment';
import 'moment/locale/tr';

import DynamicTable from '../dynamicTable/index';
import DynamicToastMessage from '../dynamicToastMessage/index';
import DynamicRequestModal from '../dynamicModal/requestModal'
import ExportModal from '../dynamicModal/exportModal';

import { useWindowDimensions, dateFormatConvert } from '../../utils/config';
import { expenseListDropdown } from '../../features/global-dropdown-menu/index';
import { IsJsonString } from '../../utils/commonFormFunctions';
import Can from "../../authorization/index";
import Cannot from "../../authorization/cannot";
import ExpenseExcelGenerator from './ExpenseExcelGenerator';
import { useQueryClient } from '@tanstack/react-query';
import { hrApi } from '../../utils/apiConfig';
import { apiUrl } from '../../utils/config';
function AllExpenseRequest(props) {

    const { t } = useTranslation();
    const isShowPassives = false;
    const { width } = useWindowDimensions();
    const [deleteModalData, setDeleteModalData] = useState({ 'isVisible': false })

    const { openExpenseDetailsDrawer, allExpenseRequestInitialSearch, allExpenseRequestSearchState, setAllExpenseRequestSearchState, expenseChangeStatusResponse,
        getExpenseDetailsStep, setSelectExpense, getAllExpenseRequests, allExpenseListLoading, allExpenseList, totalNumberOfRecords, getExpenseDetails, currentTab,
        expenseChangeStatus, permissions, expenseExportDetailPage, expenseExportTable, expenseTableExportDataResponse, resetExpenseExportTable } = props;

    const [rejectModalData, setRejectModalData] = useState({ 'isVisible': false });
    const [rejectState, setRejectState] = useState({});
    const [approveModalData, setApproveModalData] = useState({ 'isVisible': false });
    const [approveState, setApproveState] = useState({});
    const [rejectForm] = Form.useForm();
    const [approveForm] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [exportState, setExportState] = useState({ ...allExpenseRequestSearchState, exportType: 0 });

    const queryClient = useQueryClient();
    const { generateExcel } = ExpenseExcelGenerator();

    const [exportModalVisible, setExportModalVisible] = useState(false);
    const [selectedExpenseId, setSelectedExpenseId] = useState(null);

    const downloadExpenseDetail = async (id) => {
        setSelectedExpenseId(id);
        setExportModalVisible(true);
    };

    const handleExportDocument = async (type) => {
        setExportModalVisible(false);
        
        if (!selectedExpenseId) return;
        
        try {
            const data = await queryClient.fetchQuery(
                ['expenseDetail', selectedExpenseId],
                async ({ queryKey }) => {
                    const response = await hrApi({
                        method: "get",
                        url: `${apiUrl}/expenses/${queryKey[1]}`,
                    });
                    return response.data;
                },
                {
                    staleTime: 0,
                    cacheTime: 0,
                }
            );

            if (type === 2) { // PDF
                expenseExportDetailPage(selectedExpenseId);

            } else if (type === 0) { // Excel
                generateExcel(data.data);
            }
        } catch (error) {
            console.log(error);
        }
        
        setSelectedExpenseId(null);
    };

    const handleApproveConfirm = () => {
        expenseChangeStatus({ 'resourceId': approveModalData.id, 'description': approveState }, 'confirm');
    }
    const handleRejectConfirm = () => {
        expenseChangeStatus({ 'resourceId': rejectModalData.id, 'description': rejectState }, 'reject');
    }
    const handleApproveCancel = () => {
        setApproveModalData({ 'isVisible': false })
        const fields = approveForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        approveForm.setFieldsValue(projects)
    }
    const handleRejectCancel = () => {
        setRejectModalData({ 'isVisible': false })
        const fields = rejectForm.getFieldsValue()
        const projects = { ...fields };
        projects["description"] = "";
        rejectForm.setFieldsValue(projects)
    }
    const handleRejectValuesChange = (changedObj, all) => {
        setRejectState({ ...rejectState, ...changedObj });
    }
    const handleApproveValuesChange = (changedObj, all) => {
        setApproveState({ ...approveState, ...changedObj });
    }
    const handleApproveChangeStatus = (status, resourceId) => {
        if (status === "APPROVE") {
            setApproveModalData({ ...approveModalData, 'isVisible': true, 'id': resourceId })
        } else {
            setRejectModalData({ ...rejectModalData, 'isVisible': true, 'id': resourceId })
        }
    }
    const rejectModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={rejectForm}
                    onValuesChange={handleRejectValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    const approveModalForm = (
        <div className="table-details pad-0">
            <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                <Form
                    form={approveForm}
                    onValuesChange={handleApproveValuesChange}
                    layout="vertical"
                >
                    <div className="form-left-body max-w-478">
                        <Form.Item name="description">
                            <Input.TextArea
                                placeholder={t('TIMEOFF_MODAL_DESC_PLACEHOLDER')}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </ConfigProvider>
        </div>
    );
    useEffect(() => {
        if (currentTab === "AllExpenseRequest") {
            if (expenseChangeStatusResponse.status === 'SUCCESS') {
                setRejectModalData({ 'isVisible': false });
                setRejectState({});
                rejectForm.resetFields();

                setApproveModalData({ 'isVisible': false });
                setApproveState({});
                approveForm.resetFields();
            }
        }
    }, [expenseChangeStatusResponse])
    useEffect(() => {
        if (allExpenseRequestSearchState !== allExpenseRequestInitialSearch) {
            getAllExpenseRequests(allExpenseRequestSearchState);
        }
    }, [allExpenseRequestSearchState]);
    useEffect(() => {
        if (expenseTableExportDataResponse.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'DOWNLOAD_ERROR',
                'title': t('ERROR'),
                'description': IsJsonString(expenseTableExportDataResponse.message) ? JSON.parse(expenseTableExportDataResponse.message).message : t('ERROR'),
                'statusType': 'error',
                'position': 'bottom-right'
            });
            resetExpenseExportTable();
        }
    }, [expenseTableExportDataResponse])
    const canDelete = (id) => {
        setDeleteModalData({ ...deleteModalData, 'id': id, 'isVisible': true })
    }
    const handleSaveGridSettings = (d) => { }

    const handleResetAllfilters = () => {
        setExportState({ ...allExpenseRequestInitialSearch, exportType: 0 });
        setAllExpenseRequestSearchState({ ...allExpenseRequestInitialSearch });
    }
    const handleTableFilter = (data) => {
        setAllExpenseRequestSearchState({ ...allExpenseRequestSearchState, "stateTypes": data.stateType, "persons": data.personsExpense });
        setExportState({ ...exportState, "stateTypes": data.stateType, "persons": data.personsExpense });
    }
    const showExpenseDetails = (id) => {
        if (id !== undefined) {
            setSelectExpense({ 'resourceId': id })
            openExpenseDetailsDrawer();
            getExpenseDetails(id);
            getExpenseDetailsStep(id);
        }
    }
    const data = allExpenseList.map((row) => {
        return (
            {
                "key": row.expenseResourceId,
                "createdDate": { "text": moment(row.createdDate).format(dateFormatConvert), "id": row.expenseResourceId, 'stateTypeId': row.stateType?.id },
                "givenName": row.expenseCreatedPersonName,
                "expenseMasterTypeName": row.expenseMasterTypeName,
                "detailCount": row.detailCount,
                "expenseExchangeList": row.expenseExchangeList,
                "totalExpenseTL": row.totalExpenseTL + ' TL',
                "differentAmount": row.differentAmount,
                "stateType": { "id": row.stateType?.id, 'displayValue': row.stateType?.displayValue, 'resourceId': row.expenseResourceId, 'isProcessed': row.isProcessed, },
            }
        );
    });
    const tableConfig = () => ({
        filterableColumns: width >= 875 ? ['stateType', 'givenName'] : [],
        sortableColumns: ['stateType', 'givenName', 'detailCount', 'expenseMasterTypeName', 'createdDate'],
        visibilityOptionExcludes: ['stateType', 'givenName', 'detailCount', 'expenseMasterTypeName', 'expenseExchangeList', 'totalExpenseTL', 'differentAmount', 'createdDate'],
        page: "ExpenseAll",
        leftActionMenu: totalNumberOfRecords > 0 ? leftActionMenu() : null,
        //leftActionMenu: null,
        showVisibilityMenu: false,
    });
    const leftActionMenu = () => {
        return (
            <div className="w-100 flex-end">
                {width >= 875 &&
                    <Can I="timeoff.request.export">
                        <Button onClick={() => setIsModalVisible(true)} className="flex-class svg-class white-btn h-32 w-auto m-w-180"><UilImport />  {t('SETTINGS_EXPORT')}</Button>
                    </Can>
                }
            </div>
        );
    }
    const paginationConfig = () => ({
        total: totalNumberOfRecords,
        showSizeChanger: true,
        locale: { items_per_page: "/" + t('PAGE') },
        position: totalNumberOfRecords < 10 ? ['none', 'none'] : ['none', 'bottomLeft']
    });
    const renameFieldName = (field) => {
        if (field === 'stateType') {
            return 'stateTypeName'
        } else {
            return field
        }
    }
    const handleTableChange = (data) => {
        const paginationObj = { ...allExpenseRequestSearchState.pagination, 'isChanged': true, 'pageNumber': data.paging.current, 'pageSize': data.paging.pageSize, "orderBy": renameFieldName(data.sorter.field ? data.sorter.field : allExpenseRequestSearchState.pagination.orderBy) }
        if (data.sorter.order) {
            paginationObj["ascending"] = data.sorter.order === 'ascend' ? true : false;
        }
        setAllExpenseRequestSearchState({ ...allExpenseRequestSearchState, 'pagination': paginationObj })
    }
    const handleTableSearch = (e) => {
        const pagingObj = { ...allExpenseRequestSearchState.pagination, "pageNumber": 1 }
        setAllExpenseRequestSearchState({ ...allExpenseRequestSearchState, "SearchTerm": e, "pagination": pagingObj })
        setExportState({ ...exportState, "SearchTerm": e });

    }
    const handleShowPassivesToogle = (e) => { }
    
    /*
    function handleExportDocument(props) {
        expenseExportTable({
            ...exportState,
            'exportType': props,
            'pagination': {
                "pageNumber": 1,
                "pageSize": 10000,
                "orderBy": "NeedDate",
                "ascending": exportState.pagination.ascending
            }
        });
        setIsModalVisible(false);
    }
    */
    return (
        <div className="table-container">
            <ExportModal 
                status={exportModalVisible}
                onCancel={() => {
                    setExportModalVisible(false);
                    setSelectedExpenseId(null);
                }}
                exclude={['csv']}
                handleExportDocument={handleExportDocument}
            />
            <DynamicRequestModal
                modalVisible={deleteModalData?.isVisible}
                setStateData={setDeleteModalData}
                postAction={expenseChangeStatus}
                confirmData={'delete'}
                isMultipleData={{ 'resourceId': deleteModalData.id, 'description': null }}
                iconName='UilTrash'
                modalTitle={t('PARTNER_DELETE')}
                description={t('DELETE_EXPENSE_REQUEST')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('PARTNER_YES_DELETE')}
            />
            <DynamicRequestModal
                modalVisible={rejectModalData?.isVisible}
                setStateData={setRejectModalData}
                handleConfirm={handleRejectConfirm}
                handleCancel={handleRejectCancel}
                confirmData={rejectModalData?.id}
                isForm={rejectModalForm}
                iconName='UilTimesCircle'
                modalTitle={t('TIME_OFF_REQUEST_REJECT')}
                description={t('EXPENSE_REJECT_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('TIME_OFF_REQUEST_REJECT')}
            />
            <DynamicRequestModal
                modalVisible={approveModalData?.isVisible}
                setStateData={setApproveModalData}
                handleConfirm={handleApproveConfirm}
                handleCancel={handleApproveCancel}
                confirmData={approveModalData?.id}
                isForm={approveModalForm}
                iconName='UilCheckCircle'
                modalTitle={t('TIME_OFF_REQUEST_CONFIRM')}
                description={t('EXPENSE_APPROVE_MESSAGE')}
                cancelBtnTxt={t('PARTNER_NO_CANCEL')}
                successBtnTxt={t('TIME_OFF_REQUEST_CONFIRM')}
            />
            <Media query="(min-width: 875px)" >
                {matches => {
                    const columns = [
                        {
                            title: `${t('ORGANIZATION_STATU')}`,
                            dataIndex: 'stateType',
                            key: 'stateType',
                            sorter: true,
                            render: state => {
                                if (state.id === '1') {
                                    return <div className="flex-class w-100" >
                                        <span className='yellow  flex-align-center'>
                                            {state.displayValue}
                                        </span>
                                        <div className="timeoff-person-table-action-buttons w-50 ml-30" >
                                            <Button className="flex-align-center svg-class transparent-btn w-75 green pad-0 f-14" onClick={() => handleApproveChangeStatus('APPROVE', state.resourceId)} icon={<UilCheckCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_CONFIRM')}</Button>
                                            <Button className="flex-align-center svg-class transparent-btn w-75 red pad-0 f-14" onClick={() => handleApproveChangeStatus('REJECT', state.resourceId)} icon={<UilMinusCircle className="margin-left-12" />}>{t('TIME_OFF_REQUEST_REJECT')}</Button>
                                        </div>
                                    </div>
                                } else if (state.id === '2') {
                                    if (state.isProcessed) {
                                        return <span>{t('PROCESSED')}</span>
                                    } else {
                                        return <span>{state.displayValue}</span>
                                    }
                                } else if (state.id === '3') {
                                    return <div className="flex-class w-100" >
                                        <span className='red  flex-align-center'>
                                            {state.displayValue}
                                        </span>

                                    </div>

                                } else if (state.id === '4') {
                                    return <div className="flex-class w-100" >
                                        <span className='red  flex-align-center'>
                                            {state.displayValue}
                                        </span>
                                    </div>
                                } else {
                                    return <div>{state?.displayValue}</div>
                                }
                            }
                        },
                        {
                            title: `${t('EMPLOYEE')}`.charAt(0).toUpperCase() + `${t('EMPLOYEE')}`.slice(1).toLowerCase(),
                            dataIndex: 'givenName',
                            key: 'givenName',
                            sorter: false,
                        },
                        {
                            title: `${t('PARTNER_EXPENSE_TYPE')}`,
                            dataIndex: 'expenseMasterTypeName',
                            key: 'expenseMasterTypeName',
                            sorter: true,
                        },
                        {
                            title: `${t('NUMBER_OF_EXPENDITURE_ITEMS')}`,
                            dataIndex: 'detailCount',
                            key: 'detailCount',
                            sorter: true,
                        },
                        {
                            title: `${t('CURRENCY_AMOUNT')}`,
                            dataIndex: 'expenseExchangeList',
                            key: 'expenseExchangeList',
                            sorter: true,
                        },
                        {
                            title: `${t('TL_AMOUNT')}`,
                            dataIndex: 'totalExpenseTL',
                            key: 'totalExpenseTL',
                            sorter: true,
                            render: total => {
                                return parseFloat(total).toLocaleString('tr-TR') + ' TL'
                            }
                        },
                        {
                            title: `${t('OPEN_ADVANCE_DIFFERENCE')}`,
                            dataIndex: 'differentAmount',
                            key: 'differentAmount',
                            sorter: true,
                            render: total => {
                                return parseFloat(total).toLocaleString('tr-TR') + ' TL'
                            }
                        },
                    ];
                    if (matches) {
                        columns.splice(0, 0,
                            {
                                title: `${t('EXPENSE_ENTRY_DATE')}`,
                                dataIndex: 'createdDate',
                                key: 'createdDate',
                                sorter: true,
                                render: obj => {
                                    return (
                                        <div className="imageNameActionColumn">
                                            <Can I="timeoff.request.read">
                                                <a onClick={() => showExpenseDetails(obj.id)} className="details-text mar-0" >{obj.text}</a>
                                                <Dropdown className="action-menu" overlay={expenseListDropdown(t, canDelete, downloadExpenseDetail, obj.id, obj.stateTypeId, permissions)} trigger={['click']}>
                                                    <Button onClick={e => e.preventDefault()} className="transparent-btn" icon={<MoreOutlined className="more-icon" />}></Button>
                                                </Dropdown>
                                            </Can>
                                            <Cannot I="timeoff.request.read">
                                                <a className="details-text mar-0" >{obj.text}</a>
                                                <Dropdown className="action-menu" overlay={null} trigger={['click']}>
                                                    <Button onClick={e => e.preventDefault()} className="transparent-btn" ></Button>
                                                </Dropdown>
                                            </Cannot>
                                        </div>
                                    );
                                }
                            }
                        );
                    }
                    return <DynamicTable
                        columns={columns} dataSource={data}
                        tableConfig={tableConfig()} paginationConfig={paginationConfig()} onFilter={(data) => handleTableFilter(data)}
                        onTableChange={(e) => handleTableChange(e)} onSearch={(e) => handleTableSearch(e)} onResetAllFilters={handleResetAllfilters}
                        searchPlaceholder=""
                        onShowPassivesToggle={(e) => handleShowPassivesToogle(e)} isShowPassives={isShowPassives}
                        onSaveGridSettings={(d) => handleSaveGridSettings(d)}
                        searchBox={true}
                        checkboxStatus={false}
                        passiveButton={false}
                        loadStatus={allExpenseListLoading}
                    />
                }}
            </Media>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        allExpenseListLoading: state.expense.allExpenseListLoading,
        allExpenseList: state.expense.allExpenseList.results,
        totalNumberOfRecords: state.expense.allExpenseList.totalNumberOfRecords,
        expenseChangeStatusResponse: state.expense.expenseChangeStatusResponse,
        permissions: state.profile.permissions,
        expenseTableExportDataResponse: state.expense.expenseTableExportDataResponse
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllExpenseRequests: (searchData) => { dispatch(getAllExpenseRequests(searchData)) },
        getExpenseDetails: (resourceId) => { dispatch(getExpenseDetails(resourceId)) },
        getExpenseDetailsStep: (resourceId) => { dispatch(getExpenseDetailsStep(resourceId)) },
        expenseChangeStatus: (data, type) => { dispatch(expenseChangeStatus(data, type)) },
        expenseExportDetailPage: (id) => { dispatch(expenseExportDetailPage(id)) },
        expenseExportTable: (props) => { dispatch(expenseExportTable(props)) },
        resetExpenseExportTable: () => { dispatch(resetExpenseExportTable()) },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllExpenseRequest);