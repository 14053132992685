import { Modal, Image, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

const ExportModal = ({ onCancel = null, status = null, handleExportDocument = null, exclude = [] }) => {
    const { t } = useTranslation();

    const exportTypes = [
        { type: 'excel', value: 0, icon: 'excel.png', label: 'EXPORT_AS_EXCEL' },
        { type: 'pdf', value: 2, icon: 'pdf.png', label: 'EXPORT_AS_PDF' },
        { type: 'csv', value: 1, icon: 'csv.png', label: 'EXPORT_AS_CSV' }
    ];

    return (
        <Modal
            className="export-modal"
            visible={status}
            footer={null}
            onCancel={() => { onCancel() }}
            closable={false}
            centered={true}
            maskClosable={true}
        >
            <Row>
                {exportTypes
                    .filter(item => !exclude.includes(item.type))
                    .map((item) => (
                        <Col span={24} key={item.type}>
                            <div onClick={() => handleExportDocument(item.value)} className="export-model-item">
                                <Row>
                                    <Col span={6}>
                                        <Image src={process.env.REACT_APP_URL + `images/${item.icon}`} preview={false} />
                                    </Col>
                                    <Col span={18}>
                                        <span>{t(item.label)}</span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    ))}
            </Row>
        </Modal>
    )
}

export default ExportModal