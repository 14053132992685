import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getCurrencyWithId, getMultipleCurrency, createExpenseAction, resetCreateExpenseResponse, setExpenseCreateDrawer,
    getOpenAdvance, resetCurrencyWithIdResponse, createExpenseWithCashAction, resetCreateExpenseWithCashResponse, resetGetMultipleCurrency
} from '../../../redux/actions/expense.action';
import { getDataset } from '../../../redux/actions/dataset-action';

import { Input, Button, Form, ConfigProvider, Radio, Row, Col, DatePicker, Upload, List, Checkbox, Spin, Tooltip, Popover } from 'antd';
import { UilMultiply, UilFile, UilTrashAlt, UilPlusCircle } from '@iconscout/react-unicons';
import { UploadOutlined } from '@ant-design/icons';

import moment from 'moment';
import Successful from "../../successful";
import MaskedInput from 'react-text-mask';
import DynamicToastMessage from '../../dynamicToastMessage/index';
import DynamicSelect from '../../dynamicSelectbox/index';

import { numberMaskWithSeperator, compareObjects, compareCurrencyObjects } from '../../../utils/commonFormFunctions';
import { dateFormat, apiUrl, numberFormatTR, localeLang } from '../../../utils/config';

import '../../../styles/table-draw.scss';
import '../../../styles/table-add-draw.scss';
import '../../../styles/expense.scss';
import '../../../styles/utility.scss';
// 0 8 10 18 20
function ExpenseAdd(props) {
    const { auth, getCurrencyWithId, currencyWithId, getDataset, currencyTypes, creatExpenseRequests, creatExpenseWithCashRequests,
        getOpenAdvance, openAdvanceList, expenseTypes, page, createExpenseAction, resetCreateExpenseResponse,
        setExpenseCreateDrawer, resetCurrencyWithIdResponse, profile, createExpenseWithCashAction,
        resetCreateExpenseWithCashResponse, getMultipleCurrency, multipleCurrencyWithId, resetGetMultipleCurrency } = props;
    const [currentTab, setCurrentTab] = useState(1);
    const { t } = useTranslation();
    const [isCreateLoading, setisCreateLoading] = useState(false);
    const [form] = Form.useForm();
    const [fileLoadState, setFileLoadState] = useState({ 'page': null, 'status': false });
    const defaultExpenseFields = {
        'expenseType': "",
        'expenseDate': "",
        'expenditureGroup': "",
        'vat': "",
        'spendingAmount': "",
        'spendingAmountXwrite': "",
        'currency': "",
        'tlAmount': 0,
        'exchangeRate': "",
        'description': "",
        'invoiceNo': "",
        'receiptInfo': "",
        'receipt': null,
    }
    const defaultConvertCardFields = {
        "expenseDate": "",
        "cashedAmount": 0,
        "cashedCurrency": {
            "id": "",
            "displayValue": ""
        },
        "cashedTotal": "",
        "cashedExchange": 0,
        "convertedAmount": 0,
        "convertedCurrency": {
            "id": "",
            "displayValue": ""
        },
        "convertTotal": "0.00",
        "convertExchange": 0,
        "tlAmount": "0",
        "invoiceNo": "",
        "receiptInfo": "",
        "receipt": null,
        "cashedAmountXwrite": "",
        "convertedAmountXwrite": ""
    }
    const defaultCreditCardFields = {
        'expenseDate': null,
        'expenditureGroup': null,
        'spendingAmount': null,
        'spendingAmountXwrite': null,
        'currency': null,
        'vat': null,
        'tlAmount': 0,
        'exchangeRate': null,
        'description': null,
        'invoiceNo': null,
        'receiptInfo': null,
        'receipt': null
    }
    const [creditCardList, setCreditCardList] = useState([defaultCreditCardFields]);
    const [creditCardCurrencyList, setCreditCardCurrencyList] = useState();
    const [creditCardTotalAmount, setCreditCardTotalAmount] = useState(0);

    const [returnAdvanceList, setReturnAdvanceList] = useState([]);
    const [returnAdvanceAddList, setReturnAdvanceAddList] = useState([]);
    const [returnAdvanceTotal, setReturnAdvanceTotal] = useState(0);
    const [returnCurrencyList, setReturnCurrencyList] = useState();
    const [returnCheckList, setReturnCheckList] = useState([]);

    const [expenseList, setExpenseList] = useState([defaultExpenseFields]);
    const [expenseTotal, setExpenseTotal] = useState(0);
    const [expenseCurrencyList, setExpenseCurrencyList] = useState();

    const [currencyConvertList, setCurrencyConvertList] = useState([defaultConvertCardFields]);
    const [currencyConvertTotal, setCurrencyConvertTotal] = useState(0);
    const [currencyConvertSelectList1, setCurrencyConvertSelectList1] = useState();
    const [currencyConvertSelectList2, setCurrencyConvertSelectList2] = useState();

    const [checkValueList, setCheckedValueList] = useState([]);
    const [checkListObj, setCheckListObj] = useState({});
    const [openAdvanceListState, setOpenAdvanceListState] = useState([])
    const [expenseState, setExpenseState] = useState({
        'expenseType': 1
    });
    const [selectRowKey, setSelectRowKey] = useState({ 'id': 0, 'tableName': null });
    const [expenseGroupList, setExpenseGroupList] = useState([]);
    const [selectAdvanceDate, setSelectAdvanceDate] = useState(null);
    const [vatList, setVatList] = useState([
            { id: 0, displayValue: '%0' }, 
            { id: 1, displayValue: '%1' }, 
            { id: 8, displayValue: '%8' }, 
            { id: 10, displayValue: '%10' }, 
            { id: 18, displayValue: '%18' },
            { id: 20, displayValue: '%20' }
        ]);
    const access_token = auth.user?.access_token;

    useEffect(() => {
        getOpenAdvance(profile.resourceId, null)
        getDataset(23);
        getDataset(28);
    }, []);
    useEffect(() => {
        setOpenAdvanceListState(openAdvanceList)
    }, [openAdvanceList])
    useEffect(() => {
        if (creatExpenseRequests.status === 'SUCCESS') {
            setCurrentTab(2);
            resetCreateExpenseResponse()
        }
        else if (creatExpenseRequests.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': creatExpenseRequests.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateExpenseResponse()
        }
    }, [creatExpenseRequests])
    useEffect(() => {
        if (creatExpenseWithCashRequests.status === 'SUCCESS') {
            setCurrentTab(2);
            resetCreateExpenseWithCashResponse()
        }
        else if (creatExpenseWithCashRequests.status === 'FAIL') {
            DynamicToastMessage({
                'key': 'error_key',
                'title': t('ERROR'),
                'description': creatExpenseWithCashRequests.message,
                'statusType': 'error',
                'position': 'bottom-right'
            })
            resetCreateExpenseWithCashResponse()
        }
    }, [creatExpenseWithCashRequests])

    const handleValuesChange = (changedObj, all) => {
        const key = Object.keys(changedObj)[0]
        if (key === "expenseType") {
            if (changedObj.expenseType === 1) {
                getOpenAdvance(profile.resourceId, null)
                getDataset(23);
            }
            setExpenseState({ ...expenseState, 'expenseType': changedObj.expenseType, 'description': "", 'descriptionType1': "", 'speacialAreaType1': "" });
            form.resetFields();
            form.setFieldsValue({
                'expenseType': changedObj.expenseType
            })
            destroyAllData();
        }
        setExpenseState({ ...expenseState, ...changedObj });
    }
    // =============== CREDIT CART LIST  && EXPENSE CART LIST  && CURRENCY LIST START ====================== //
    useEffect(() => {
        var amount = 0;
        creditCardList.length > 0 &&
            creditCardList.map((data) => {
                if (data.tlAmount) {
                    amount += parseFloat(data.tlAmount);
                }
            })
        setCreditCardTotalAmount(amount);
    }, [creditCardList])
    useEffect(() => {
        var amount = 0;
        expenseList.length > 0 &&
            expenseList.map((data) => {
                if (data.tlAmount) {
                    amount += parseFloat(data.tlAmount);
                }
            })
        setExpenseTotal(amount);
    }, [expenseList])
    useEffect(() => {
        var amount = 0;
        currencyConvertList.length > 0 &&
            currencyConvertList.map((data) => {
                if (data.tlAmount) {
                    amount += parseFloat(data.tlAmount);
                }
            })
        setCurrencyConvertTotal(amount);
    }, [currencyConvertList])
    const creditCardCurrencyControl = (currencyControlData) => {
        // if (creditCardCurrencyList) {
        //     var selectData = currencyControlData.map((data) => { return data.currency?.id });
        //     const findSelectData = currencyTypes.filter(filterData => selectData.includes(filterData.id));
        //     const findSelectDataID = findSelectData.map((data) => data.id);

        //     var newCurrencyData = creditCardCurrencyList.map((data) => {
        //         return {
        //             'id': data.id,
        //             'displayValue': data.displayValue,
        //             'disabled': findSelectDataID.includes(data.id)
        //         }
        //     });
        //     setCreditCardCurrencyList(newCurrencyData)
        // }
    }
    const expenseCardCurrencyControl = (currencyControlData) => {
        // if (expenseList) {
        //     var selectData = currencyControlData.map((data) => { return data.currency?.id });
        //     const findSelectData = currencyTypes.filter(filterData => selectData.includes(filterData.id));
        //     const findSelectDataID = findSelectData.map((data) => data.id);

        //     var newCurrencyData = expenseCurrencyList.map((data) => {
        //         return {
        //             'id': data.id,
        //             'displayValue': data.displayValue,
        //             'disabled': findSelectDataID.includes(data.id)
        //         }
        //     });
        //     setExpenseCurrencyList(newCurrencyData)
        // }
    }
    const handleDynamicListInputChange = (e, index, key, inputType, page) => {
        var list = [];
        if (page === "creditCard") {
            list = [...creditCardList];
        } else if (page === "expense") {
            list = [...expenseList]
        } else if (page === "currencyConvert") {
            list = [...currencyConvertList]
        }
        switch (inputType) {
            case 'datePicker':
                list[index][key] = e;
                if (page === "creditCard") {
                    creditCardAmountDataControl({ 'spendingAmount': list[index].spendingAmount, 'currency': list[index].currency?.id, 'expenseDate': e }, index)
                    return setCreditCardList(list);
                } else if (page === "expense") {
                    expenseCardAmountDataControl({ 'spendingAmount': list[index].spendingAmount, 'currency': list[index].currency?.id, 'expenseDate': e }, index)
                    return setExpenseList(list);
                }
                else if (page === "currencyConvert") {
                    if (e && e.trim()) {
                        if (list[index].cashedCurrency?.id && list[index].convertedCurrency?.id) {
                            currencyConvertAmountDataControl({
                                'cashedAmount': list[index].cashedAmount,
                                'cashedCurrency': list[index].cashedCurrency?.id,
                                'convertedAmount': list[index].convertedAmount,
                                'convertedCurrency': list[index].convertedCurrency?.id,
                                'expenseDate': e
                            }, index, 'multipleCurrency');
                        } else if (list[index].cashedCurrency?.id && !list[index].convertedCurrency?.id) {
                            currencyConvertAmountDataControl(
                                {
                                    'cashedAmount': list[index].cashedAmount,
                                    'cashedCurrency': list[index].cashedCurrency?.id,
                                    'convertedAmount': list[index].convertedAmount,
                                    'convertedCurrency': list[index].convertedCurrency?.id,
                                    'expenseDate': e
                                }
                                , index, 'cashedCurrency');
                        } else if (!list[index].cashedCurrency?.id && list[index].convertedCurrency?.id) {
                            currencyConvertAmountDataControl(
                                {
                                    'cashedAmount': list[index].cashedAmount,
                                    'cashedCurrency': list[index].cashedCurrency?.id,
                                    'convertedAmount': list[index].convertedAmount,
                                    'convertedCurrency': list[index].convertedCurrency?.id,
                                    'expenseDate': e
                                }, index, 'convertedCurrency');
                        }
                    }
                    return setCurrencyConvertList(list);
                }
            case 'select':
                if (key === "currency") {
                    if (page === "creditCard") {
                        creditCardAmountDataControl({ 'spendingAmount': list[index].spendingAmount, 'currency': e?.id, 'expenseDate': list[index].expenseDate }, index)
                    } else if (page === "expense") {
                        expenseCardAmountDataControl({ 'spendingAmount': list[index].spendingAmount, 'currency': e?.id, 'expenseDate': list[index].expenseDate }, index)
                    }
                } 
                else {
                    if (page === "currencyConvert") {
                        if (key === "cashedCurrency") {
                            currencyConvertAmountDataControl(
                                {
                                    'cashedAmount': list[index].cashedAmount,
                                    'cashedCurrency': e?.id,
                                    'convertedAmount': list[index].convertedAmount,
                                    'convertedCurrency': list[index].convertedCurrency?.id,
                                    'expenseDate': list[index].expenseDate
                                }
                                , index, 'cashedCurrency')
                        } else if (key === "convertedCurrency") {
                            currencyConvertAmountDataControl(
                                {
                                    'cashedAmount': list[index].cashedAmount,
                                    'cashedCurrency': list[index].cashedCurrency?.id,
                                    'convertedAmount': list[index].convertedAmount,
                                    'convertedCurrency': e?.id,
                                    'expenseDate': list[index].expenseDate
                                }
                                , index, 'convertedCurrency')
                        }
                    }
                }
                list[index][key] = e;
                if (page === "creditCard") {
                    creditCardCurrencyControl(list)
                    return setCreditCardList(list);
                } else if (page === "expense") {
                    expenseCardCurrencyControl(list)
                    return setExpenseList(list);
                } else if (page === "currencyConvert") {
                    return setCurrencyConvertList(list);
                }
            case 'priceInput':
                const val = e.target.value;
                const replaceVal = val.replaceAll('.', '').replaceAll(',', '.');
                let parseVal = parseFloat(parseFloat(replaceVal).toFixed(2));
                parseVal = parseVal !== "NaN" ? parseVal : 0;
                list[index][key] = isNaN(parseVal) ? 0 : parseVal;
                list[index][key + "Xwrite"] = val;
                if (page === "creditCard") {
                    creditCardAmountDataControl({ 'spendingAmount': parseVal, 'currency': list[index].currency?.id, 'expenseDate': list[index].expenseDate }, index, 'amount')
                    return setCreditCardList(list);
                } else if (page === "expense") {
                    expenseCardAmountDataControl({ 'spendingAmount': parseVal, 'currency': list[index].currency?.id, 'expenseDate': list[index].expenseDate }, index, 'amount')
                    return setExpenseList(list);
                } else if (page === "currencyConvert") {
                    if (key === "cashedAmount") {
                        currencyConvertAmountDataControl(
                            {
                                'cashedAmount': parseVal,
                                'cashedCurrency': list[index].cashedCurrency?.id,
                                'convertedAmount': list[index].convertedAmount,
                                'convertedCurrency': list[index].convertedCurrency?.id,
                                'expenseDate': list[index].expenseDate
                            }
                            , index, 'cashedCurrency', 'amount')
                    } else if (key === "convertedAmount") {
                        currencyConvertAmountDataControl(
                            {
                                'cashedAmount': list[index].cashedAmount,
                                'cashedCurrency': list[index].cashedCurrency?.id,
                                'convertedAmount': parseVal,
                                'convertedCurrency': list[index].convertedCurrency?.id,
                                'expenseDate': list[index].expenseDate
                            }
                            , index, 'convertedCurrency', 'amount')
                    }
                    return setCurrencyConvertList(list);
                }
            case 'text':
                list[index][key] = e.target.value;
                if (page === "creditCard") {
                    return setCreditCardList(list);
                } else if (page === "expense") {
                    return setExpenseList(list);
                } else if (page === "currencyConvert") {
                    return setCurrencyConvertList(list);
                }
            case 'img':
                list[index][key] = e;
                if (page === "creditCard") {
                    return setCreditCardList(list);
                } else if (page === "expense") {
                    return setExpenseList(list);
                } else if (page === "currencyConvert") {
                    return setCurrencyConvertList(list);
                }
            default:
                return null;
        }
    };
    const creditCardAmountDataControl = (dataObj, index, type) => {
        if ((dataObj.spendingAmount !== "" && dataObj.spendingAmount !== "0") && (dataObj.currency !== "" && dataObj.currency) && dataObj.expenseDate) {
            if (type === "amount") {
                const list = [...creditCardList];
                const lastCurrency = list[index].exchangeRate;
                const total = lastCurrency * dataObj.spendingAmount;
                list[index].tlAmount = 0;
                list[index].tlAmount = isNaN(total) ? 0 : total;
                setCreditCardList(list)
            } else {
                const date1 = moment(dataObj.expenseDate).format('YYYYMM');
                const date2 = moment(dataObj.expenseDate).format('DDMMYYYY');
                getCurrencyWithId(dataObj.currency, date1, date2)
            }
            setSelectRowKey({ 'id': index, 'tableName': 'creditcardTable' });
        } else if (dataObj.spendingAmount === "0" || dataObj.spendingAmount === "") {
            const list = [...creditCardList];
            list[index].tlAmount = 0;
            setCreditCardList(list)
        }
    }
    const expenseCardAmountDataControl = (dataObj, index, type) => {
        if ((dataObj.spendingAmount !== "" && dataObj.spendingAmount !== "0") && (dataObj.currency !== "" && dataObj.currency)) {
            if (selectAdvanceDate && type !== "amount") {
                const date1 = moment(selectAdvanceDate).format('YYYYMM');
                const date2 = moment(selectAdvanceDate).format('DDMMYYYY');
                getCurrencyWithId(dataObj.currency, date1, date2)
            } else if (dataObj.expenseDate && type !== "amount") {
                const date1 = moment(dataObj.expenseDate).format('YYYYMM');
                const date2 = moment(dataObj.expenseDate).format('DDMMYYYY');
                getCurrencyWithId(dataObj.currency, date1, date2)
            } else if (type === "amount") {
                const list = [...expenseList];
                const lastCurrency = list[index].exchangeRate;
                const total = lastCurrency * dataObj.spendingAmount;
                list[index].tlAmount = 0;
                list[index].tlAmount = isNaN(total) ? 0 : total;
                setExpenseList(list)
            }
            setSelectRowKey({ 'id': index, 'tableName': 'expense' })
        } else if (dataObj.spendingAmount === "0" || dataObj.spendingAmount === "") {
            const list = [...expenseList];
            list[index].tlAmount = 0;
            setExpenseList(list)
        }
    }
    const currencyConvertAmountDataControl = (dataObj, index, whichSelect, type) => {
        if (whichSelect === "multipleCurrency" && dataObj.cashedCurrency && dataObj.convertedCurrency) {
            const date1 = moment(dataObj.expenseDate).format('YYYYMM');
            const date2 = moment(dataObj.expenseDate).format('DDMMYYYY');
            getMultipleCurrency(dataObj.cashedCurrency, dataObj.convertedCurrency, date1, date2);
        } else {
            if ((dataObj.cashedAmount !== "" && dataObj.cashedAmount !== "0") && (dataObj.cashedCurrency !== "" && dataObj.cashedCurrency) && whichSelect === "cashedCurrency") {
                if (selectAdvanceDate && type !== "amount") {
                    const date1 = moment(selectAdvanceDate).format('YYYYMM');
                    const date2 = moment(selectAdvanceDate).format('DDMMYYYY');
                    getCurrencyWithId(dataObj.cashedCurrency, date1, date2);
                } else if (dataObj.expenseDate && type !== "amount" && dataObj.cashedCurrency) {
                    const date1 = moment(dataObj.expenseDate).format('YYYYMM');
                    const date2 = moment(dataObj.expenseDate).format('DDMMYYYY');
                    getCurrencyWithId(dataObj.cashedCurrency, date1, date2);
                }
                if (type === "amount") {
                    const list = [...currencyConvertList];
                    const cashedAmount = dataObj.cashedAmount * list[index].cashedExchange;
                    const convertAmount = dataObj.convertedAmount * list[index].convertExchange;
                    const total = parseFloat(cashedAmount - convertAmount).toFixed(2);
                    list[index].tlAmount = 0;
                    list[index].tlAmount = isNaN(total) ? 0 : total;
                    setCurrencyConvertList(list)
                }
                setSelectRowKey({ 'id': index, 'tableName': 'cashedCurrency' })
            } else if ((dataObj.convertedAmount !== "" && dataObj.convertedAmount !== "0") && (dataObj.convertedCurrency !== "" && dataObj.convertedCurrency) && whichSelect === "convertedCurrency") {
                if (selectAdvanceDate && type !== "amount") {
                    const date1 = moment(selectAdvanceDate).format('YYYYMM');
                    const date2 = moment(selectAdvanceDate).format('DDMMYYYY');
                    getCurrencyWithId(dataObj.convertedCurrency, date1, date2)
                } else if (dataObj.expenseDate && type !== "amount" && dataObj.convertedCurrency) {
                    const date1 = moment(dataObj.expenseDate).format('YYYYMM');
                    const date2 = moment(dataObj.expenseDate).format('DDMMYYYY');
                    getCurrencyWithId(dataObj.convertedCurrency, date1, date2)
                }
                if (type === "amount") {
                    const list = [...currencyConvertList];
                    const cashedAmount = (isNaN(dataObj.cashedAmount) ? 0 : dataObj.cashedAmount) * list[index].cashedExchange;
                    const convertAmount = (isNaN(dataObj.convertedAmount) ? 0 : dataObj.convertedAmount) * list[index].convertExchange;
                    const total = parseFloat(cashedAmount - convertAmount).toFixed(2);
                    list[index].tlAmount = 0;
                    list[index].tlAmount = isNaN(total) ? 0 : total;
                    setCurrencyConvertList(list)
                }
                setSelectRowKey({ 'id': index, 'tableName': 'convertedCurrency' })
            }
        }
    }
    //
    const handleDynamicListRemoveItem = (index, page) => {
        if (page === "creditCard") {
            const list = [...creditCardList];
            list.splice(index, 1);
            setCreditCardList(list);
            creditCardCurrencyControl(list)
        } else if (page === "expense") {
            const list = [...expenseList];
            list.splice(index, 1);
            setExpenseList(list);
            expenseCardCurrencyControl(list)
        } else if (page === "currencyConvert") {
            const list = [...currencyConvertList];
            list.splice(index, 1);
            setCurrencyConvertList(list);
            //expenseCardCurrencyControl(list)
        }
    };
    const handleDynamicAddRow = (page) => {
        var requiredFields = {};
        let hasError = false
        let fields = []
        var list = [];
        if (page === "creditCard") {
            list = [...creditCardList];
            requiredFields = {
                'expenseDate': false,
                'expenditureGroup': false,
                'vat': false,
                'spendingAmount': false,
                'currency': false,
                'tlAmount': false,
            }
        } else if (page === "expense") {
            list = [...expenseList];
            requiredFields = {
                "expenseType": false,
                'expenditureGroup': false,
                'vat': false,
                'expenseDate': false,
                'spendingAmount': false,
                'currency': false,
                'tlAmount': false,
            }
        } else if (page === "currencyConvert") {
            list = [...currencyConvertList];
            requiredFields = {
                "expenseType": false,
                'expenseDate': false,
                'cashedAmount': false,
                'cashedCurrency': false,
                'convertedAmount': false,
                'convertedCurrency': false,
                'tlAmount': false,
            }
        }
        list.map((data) => {
            if (!data.expenseDate) {
                requiredFields.expenseDate = true;
            }
            if ((!data.expenditureGroup || data.expenditureGroup?.id === "") && (page === "expense" || page === "creditCard")) {
                requiredFields.expenditureGroup = true;
            }
            if ((data.vatList?.id === "") && (page === "expense" || page === "creditCard")) {
                requiredFields.vat = true;
            }
            if ((!data.spendingAmount || data.spendingAmount === "0") && page !== "currencyConvert") {
                requiredFields.spendingAmount = true;
            }
            if ((!data.currency || data.currency?.id === "") && page !== "currencyConvert") {
                requiredFields.currency = true;
            }
            if (!data.tlAmount || !data.tlAmount === "0") {
                requiredFields.tlAmount = true;
            }
        });
        if (requiredFields.expenseDate) {
            hasError = true
            fields.push(t('PARTNER_DATE'));
        }
        if (requiredFields.expenditureGroup) {
            hasError = true;
            fields.push(t('EXPENDITURE_GROUP'));
        }
        if (requiredFields.spendingAmount) {
            hasError = true;
            fields.push(t('PARTNER_AMOUNT2'));
        }
        if (requiredFields.currency) {
            hasError = true;
            fields.push(t('PARTNER_CURRENCY'));
        }
        if (requiredFields.tlAmount) {
            hasError = true;
            fields.push(t('TL_AMOUNT'));
        }
        if (!hasError || (page === "expense" && expenseList.length === 0)) {
            if (page === "creditCard") {
                creditCardCurrencyControl(list)
                list.push(defaultCreditCardFields);
                requiredFields = {
                    'expenseDate': false,
                    'expenditureGroup': false,
                    'vat': false,
                    'spendingAmount': false,
                    'currency': false,
                    'tlAmount': false
                }
                setCreditCardList(list);
            } else if (page === "expense") {
                list.push(defaultExpenseFields)
                requiredFields = {
                    "expenseType": false,
                    'expenditureGroup': false,
                    'vat': false,
                    'expenseDate': false,
                    'spendingAmount': false,
                    'currency': false,
                    'tlAmount': false,
                }
                setExpenseList(list);
            } else if (page === "currencyConvert") {
                list.push(defaultConvertCardFields);
                requiredFields = {
                    "expenseType": false,
                    'expenseDate': false,
                    'cashedAmount': false,
                    'cashedCurrency': false,
                    'convertedAmount': false,
                    'convertedCurrency': false,
                    'tlAmount': false,
                }
                setCurrencyConvertList(list);
            }
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    };

    // ===============  CREDIT CART LIST  && EXPENSE CART LIST  && CURRENCY LIST END ====================== //

    // =============== RETURN ADVANCE START ====================== //
    useEffect(() => {
        var advanceCount = 0;
        var returnlist = returnAdvanceList.concat(returnAdvanceAddList);
        if (returnAdvanceList.length > 0) {
            returnAdvanceList.map((data) => {
                var total = data.received * data.exchangeRate;
                total -= data.return * data.exchangeRate;
                advanceCount += total;
            })
        }
        if (returnAdvanceAddList.length > 0) {
            returnAdvanceAddList.map((data) => {
                if (data.advanceInformation?.id && data.exchangeRate > 0) {
                    var returnCount = data.return * data.exchangeRate;
                    advanceCount -= returnCount;
                }
            })
        }
        if (returnAdvanceList.length > 0 || returnAdvanceAddList.length > 0) {
            setReturnAdvanceTotal(advanceCount)
        } else if (returnlist.length === 0) {
            advanceCount = 0;
            setReturnAdvanceTotal(advanceCount)
        }
    }, [returnAdvanceList, returnAdvanceAddList])
    const addCheckedExpenceToTable = (index, data) => {
        setCheckListObj((prevState) => ({
            ...prevState,
            [index]: data
        }))
    }
    const removeExpenceFromTable = (index) => {
        const tempObj = { ...checkListObj }
        delete tempObj[index]
        setCheckListObj(tempObj)
    }
    useEffect(() => {
        let list = []
        let resourceIdList = [];
        let confirmDateList = [];
        for (const [key, value] of Object.entries(checkListObj)) {
            resourceIdList.push(value.resourceId);
            confirmDateList.push(value.confirmedDate)
            value.amounts.map(data => {
                list.push(data)
            })
        }
        if (confirmDateList.length > 0) {
            var confirmDate = confirmDateList.reduce(function (r, a, i) {
                return !i || a < r ? a : r;
            }, undefined);
            setSelectAdvanceDate(confirmDate)
        }
        if (Object.keys(checkListObj).length === 0) {
            setSelectAdvanceDate(null);
        }
        setReturnCheckList(resourceIdList);
        const res = Array.from(list.reduce((acc, { amount, ...r }) => {
            const key = JSON.stringify(r);
            const current = acc.get(key) || { ...r, amount: 0 };
            return acc.set(key, { ...current, amount: current.amount + amount });
        }, new Map).values());
        var newRowList = [];
        res.map(data => {
            let returnx = returnAdvanceList.filter(item => item.advanceInformation.id === data.currencyId)[0] ?
                returnAdvanceList.filter(item => item.advanceInformation.id === data.currencyId)[0].return : null;
            const total = data.amount * data.exchangeRate;
            newRowList.push(
                {
                    'advanceInformation': { 'id': data.currencyId, 'displayValue': data.currencyName },
                    'received': data.amount,
                    'return': returnx,
                    'exchangeRate': data.exchangeRate,
                    'advanceDebt': total,
                }
            );
        })
        returnListCurrencyControl(newRowList)
        setReturnAdvanceList(newRowList);
    }, [checkListObj])

    const changeCloseAdvanceList = (checkedValue) => {
        let differenceA = checkedValue.filter(x => !checkValueList.includes(x));
        let differenceB = checkValueList.filter(x => !checkedValue.includes(x));
        setCheckedValueList(checkedValue);
        if (differenceA.length > 0) {
            const checkedItem = openAdvanceListState.filter((filterData, index) => index === differenceA[0])[0]
            addCheckedExpenceToTable(differenceA[0], checkedItem)
        }
        if (differenceB.length > 0) {
            removeExpenceFromTable(differenceB[0])
        }
    }
    const handleReturnAdvanceListInputChange = (e, index, key, inputType) => {
        const list = [...returnAdvanceList];
        switch (inputType) {
            case 'priceInput':
                const val = e.target.value;
                var replaceVal = val.replaceAll('.', '').replaceAll(',', '.');
                var parseVal = parseFloat(parseFloat(replaceVal).toFixed(2));
                //.replaceAll(RegExp(/\x20?\d+(?:[.,]\d{0,2})? +[$€£₺]/), '');
                var exchangeRate = parseFloat(list[index].exchangeRate);
                var returnAmount = parseFloat(replaceVal);
                var received = parseFloat(list[index].received);
                if (parseFloat(replaceVal) > 0) {
                    list[index].advanceDebt = 0;
                    var calculator = (received * exchangeRate) - (returnAmount * exchangeRate);
                    list[index].advanceDebt = calculator;
                } else {
                    var calculator = received * exchangeRate;
                    list[index].advanceDebt = 0;
                    list[index].advanceDebt = calculator;
                }
                list[index][key] = isNaN(parseVal) ? 0 : parseVal;;
                list[index][key + "Xwrite"] = val;

                return setReturnAdvanceList(list);
            default:
                return null;
        }
    };
    const handleReturnAdvanceAddListInputChange = (e, index, key, inputType) => {
        const list = [...returnAdvanceAddList];
        switch (inputType) {
            case 'select':
                list[index][key] = e.id ? { 'id': parseInt(e.id), 'displayValue': e.displayValue } : { 'id': null, 'displayValue': '' };
                if (key === "advanceInformation") {
                    returnAdvanceAmountDataControl({ 'received': list[index].received, 'return': list[index].return, 'advanceInformation': e?.id }, index, 'add')
                    returnListCurrencyControl(list)
                }
                returnListCurrencyControl(list)
                return setReturnAdvanceAddList(list);
            case 'priceInput':
                const val = e.target.value;
                var replaceVal = val.replaceAll('.', '').replaceAll(',', '.');
                var parseVal = parseFloat(parseFloat(replaceVal).toFixed(2));
                list[index][key] = isNaN(parseVal) ? 0 : parseVal;
                list[index][key + "Xwrite"] = val;
                if (key === "received") {
                    returnAdvanceAmountDataControl({ 'received': replaceVal, 'return': list[index].return, 'advanceInformation': list[index].currency?.id }, index, 'add')
                } else {
                    returnAdvanceAmountDataControl({ 'return': replaceVal, 'received': list[index].received, 'advanceInformation': list[index].currency?.id }, index, 'add')
                }
                return setReturnAdvanceAddList(list);
            case 'text':
                list[index][key] = e.target.value;
                return setReturnAdvanceAddList(list);
            default:
                return null;
        }
    };
    const returnAdvanceAmountDataControl = (dataObj, index, data) => {
        if (dataObj.advanceInformation !== "" && dataObj.advanceInformation) {
            setSelectRowKey({ 'id': index, 'tableName': 'returnAddList' })
            if (selectAdvanceDate) {
                const date1 = moment(selectAdvanceDate).format('YYYYMM');
                const date2 = moment(selectAdvanceDate).format('DDMMYYYY');
                getCurrencyWithId(dataObj.advanceInformation, date1, date2)
            }
            var list = [];
            list = [...returnAdvanceAddList];
            list[index].return = null;
            setReturnAdvanceAddList(list);
        }
    }
    const handleReturnAddListRemoveItem = (index, page) => {
        if (page === "returnList") {
            const list = [...returnAdvanceAddList];
            list.splice(index, 1);
            setReturnAdvanceAddList(list);
            returnListCurrencyControl(list)
        }
    };
    function handleAddNewReturnList() {
        const newRowList = [...returnAdvanceAddList];
        newRowList.push(
            {
                'advanceInformation': { 'id': null, 'displayValue': null },
                'received': 0,
                'return': null,
                'exchangeRate': 0,
                'advanceDebt': 0,
            }
        );
        const returnMergeObj = returnAdvanceList.concat(returnAdvanceAddList);
        returnListCurrencyControl(returnMergeObj);
        setReturnAdvanceAddList(newRowList);
    }
    const returnListCurrencyControl = (currencyControlDatax) => {
        const currencyControlData = returnAdvanceList.concat(currencyControlDatax);

        var defaultSelectList = currencyTypes.map((data) => {
            return {
                'id': parseInt(data.id),
                'displayValue': data.displayValue,
                'disabled': false
            }
        })
        if (returnCurrencyList) {
            var selectData = currencyControlData.map((data) => { return data.advanceInformation?.id });
            const findSelectData = defaultSelectList.filter(filterData => selectData.includes(filterData.id));
            const findSelectDataID = findSelectData.map((data) => data.id);
            var newCurrencyData = returnCurrencyList.map((data) => {
                return {
                    'id': data.id,
                    'displayValue': data.displayValue,
                    'disabled': findSelectDataID.includes(data.id)
                }
            });
            setReturnCurrencyList(newCurrencyData)
        }
    }

    // =============== RETURN ADVANCE END ====================== //

    function closeControl() {
        destroyLastState()
    }
    function destroyLastState() {
        setCurrentTab(1);
        setExpenseCreateDrawer(false)
        setCreditCardList([
            {
                'expenseDate': null,
                'expenditureGroup': null,
                'spendingAmount': null,
                'currency': null,
                'vat': null,
                'tlAmount': 0,
                'exchangeRate': null,
                'description': null,
                'invoiceNo': null,
                'receiptInfo': null,
                'receipt': null
            }
        ]);
        setExpenseState({
            'expenseType': 1
        })
    }
    useEffect(() => {
        form.setFieldsValue({
            'expenseType': 1
        })
    }, [form])
    useEffect(() => {
        setExpenseGroupList(
            expenseTypes.map((data) => {
                return {
                    'id': data.id,
                    'displayValue': data.displayValue,
                }
            })
        );
    }, [expenseTypes])
    useEffect(() => {
        if (multipleCurrencyWithId.status === "SUCCESS") {
            var list = [...currencyConvertList];
            if (multipleCurrencyWithId.cashed && multipleCurrencyWithId.converted) {
                list[selectRowKey.id].cashedTotal = null;
                list[selectRowKey.id].convertTotal = null;
                list[selectRowKey.id].cashedExchange = multipleCurrencyWithId.cashed;
                const cashedTotal = list[selectRowKey.id].cashedAmount ? list[selectRowKey.id].cashedAmount * multipleCurrencyWithId.cashed : 0
                list[selectRowKey.id].cashedTotal = parseFloat(cashedTotal).toFixed(2);

                list[selectRowKey.id].convertExchange = multipleCurrencyWithId.converted;
                const convertTotal = list[selectRowKey.id].convertedAmount ? list[selectRowKey.id].convertedAmount * multipleCurrencyWithId.converted : 0
                list[selectRowKey.id].convertTotal = parseFloat(convertTotal).toFixed(2);
                list[selectRowKey.id].tlAmount = parseFloat(cashedTotal - convertTotal).toFixed(2);
                setCurrencyConvertList(list);
                resetGetMultipleCurrency();
            }
        }
    }, [multipleCurrencyWithId])
    useEffect(() => {
        if (currencyWithId.status === "SUCCESS") {
            if (expenseState.expenseType === 2 && selectRowKey.tableName === "creditcardTable") {
                const list = [...creditCardList];
                list[selectRowKey.id].tlAmount = null;
                const tlAmount = list[selectRowKey.id].spendingAmount ? list[selectRowKey.id].spendingAmount * currencyWithId.data : 0
                list[selectRowKey.id].tlAmount = parseFloat(tlAmount).toFixed(2);
                list[selectRowKey.id].exchangeRate = currencyWithId.data;
                setCreditCardList(list)
            } else if (expenseState.expenseType === 1 && selectRowKey.tableName === "returnAddList") {
                const list = [...returnAdvanceAddList];
                list[selectRowKey.id].exchangeRate = currencyWithId.data;
                setReturnAdvanceAddList(list)
            } else if (expenseState.expenseType === 1 && selectRowKey.tableName === "expense") {
                const list = [...expenseList];
                list[selectRowKey.id].tlAmount = null;
                const tlAmount = list[selectRowKey.id].spendingAmount ? list[selectRowKey.id].spendingAmount * currencyWithId.data : 0
                list[selectRowKey.id].tlAmount = parseFloat(tlAmount).toFixed(2);
                list[selectRowKey.id].exchangeRate = currencyWithId.data;
                setExpenseList(list)
            } else if (expenseState.expenseType === 1 && selectRowKey.tableName === "cashedCurrency") {
                const list = [...currencyConvertList];
                list[selectRowKey.id].cashedTotal = null;
                list[selectRowKey.id].cashedExchange = currencyWithId.data;
                const cashedTotal = list[selectRowKey.id].cashedAmount ? list[selectRowKey.id].cashedAmount * currencyWithId.data : 0
                list[selectRowKey.id].cashedTotal = parseFloat(cashedTotal).toFixed(2);
                if (list[selectRowKey.id].convertTotals) {
                    list[selectRowKey.id].tlAmount = parseFloat(cashedTotal - list[selectRowKey.id].convertTotal).toFixed(2);
                }
                setCurrencyConvertList(list)
            } else if (expenseState.expenseType === 1 && selectRowKey.tableName === "convertedCurrency") {
                const list = [...currencyConvertList];
                list[selectRowKey.id].convertTotal = null;
                list[selectRowKey.id].convertExchange = currencyWithId.data;
                const convertTotal = list[selectRowKey.id].convertedAmount ? list[selectRowKey.id].convertedAmount * currencyWithId.data : 0
                list[selectRowKey.id].convertTotal = parseFloat(convertTotal).toFixed(2);
                if (list[selectRowKey.id].cashedTotal) {
                    list[selectRowKey.id].tlAmount = parseFloat(list[selectRowKey.id].cashedTotal - convertTotal).toFixed(2);
                }
                setCurrencyConvertList(list)
            }
        }
    }, [currencyWithId])

    useEffect(() => {
        if (creditCardList.length === 1 || creditCardList.length === 0) {
            setCreditCardCurrencyList(
                currencyTypes.map((data) => {
                    return {
                        'id': data.id,
                        'displayValue': data.displayValue,
                        'disabled': false
                    }
                })
            );
        }
        if (expenseList.length === 0 || expenseList.length === 1) {
            setExpenseCurrencyList(
                currencyTypes.map((data) => {
                    return {
                        'id': data.id,
                        'displayValue': data.displayValue,
                        'disabled': false
                    }
                })
            );
        }
        if (currencyConvertList.length === 0 || currencyConvertList.length === 1) {
            setCurrencyConvertSelectList1(
                currencyTypes.map((data) => {
                    return {
                        'id': data.id,
                        'displayValue': data.displayValue,
                        'disabled': false
                    }
                })
            );
            setCurrencyConvertSelectList2(
                currencyTypes.map((data) => {
                    return {
                        'id': data.id,
                        'displayValue': data.displayValue,
                        'disabled': false
                    }
                })
            );
        }
        if (returnAdvanceList.length === 0 || returnAdvanceAddList.length === 0) {
            setReturnCurrencyList(
                currencyTypes.map((data) => {
                    return {
                        'id': parseInt(data.id),
                        'displayValue': data.displayValue,
                        'disabled': false
                    }
                })
            );
        }
    }, [currencyTypes])

    const uploadImgAction = (values, index, page) => {
        if (values.file.status === 'uploading') {
            setFileLoadState({ 'page': page, 'status': true });
        }
        if (values?.file?.response) {
            if (values?.file?.response?.status === "SUCCESS") {
                handleDynamicListInputChange(values?.file?.response?.data, index, 'receipt', 'img', page);
                DynamicToastMessage({
                    'key': 'upload_image_response_successx',
                    'title': t('SUCCESS'),
                    'description': t('FILE_UPLOAD_COMPLETED'),
                    'statusType': 'success',
                    'position': 'bottom-right'
                });
                setFileLoadState({ 'page': null, 'status': false });
            } else {
                DynamicToastMessage({
                    'key': 'upload_image_response_error',
                    'title': t('ERROR'),
                    'description': values?.file?.response?.message,
                    'statusType': 'error',
                    'position': 'bottom-right'
                })
                setFileLoadState({ 'page': null, 'status': false });
            }
        }
    }
    const createExpense = (type) => {
        if(isCreateLoading) return;
        setisCreateLoading(true);
        setTimeout(() => {setisCreateLoading(false);}, 1055) // double click prevent;
        let hasError = false
        let fields = [];
        let returnListFields = {
            'advanceInformation': false,
            'return': false,
        }
        let expenseListFields = {
            'expenseTypeIdx': false,
            'expenditureGroup': false,
            'vat': false,
            'expenseDate': false,
            'currencyIdx': false,
            'expenseAmount': false,
            'tlAmount': false,
            'exchangeRate': false,
        }
        let currencyConvertListFields = {
            "exchangeDate": false,
            "exchangeAmount": false,
            "exchangeCurrencyId": false,
            "convertAmount": false,
            "convertCurrencyId": false,
            "exchangeExpense": false

        }
        let requiredCardListFields = {
            'expenseDate': false,
            'expenditureGroup': false,
            'vat': false,
            'spendingAmount': false,
            'currency': false,
            'tlAmount': false,
        }
        if (expenseState.expenseType === 1) {
            //return  & return Add List
            const returnList = returnAdvanceList.concat(returnAdvanceAddList);
            if (returnList.length > 0) {

                returnList.map((data) => {
                    if (!data.advanceInformation?.id) {
                        returnListFields.advanceInformation = true;
                    }
                });
                if (returnListFields.advanceInformation) {
                    hasError = true
                    fields.push(t('ADVANCE_INFORMATION'));
                }
                if (returnListFields.return) {
                    hasError = true;
                    fields.push(t('RETURN'));
                }
            }
            // if (returnList.length === 0) {
            //     hasError = true
            //     fields.push(t('ENTER_THE_ADVANCE_TO_BE_REFUNDED'));
            // }

            //Expense List
            if (compareObjects(expenseList[0], defaultExpenseFields) && compareCurrencyObjects(currencyConvertList[0], defaultConvertCardFields)) {
                hasError = true
                fields.push(t('EXPENSES'));
            } else {
                if (!compareObjects(expenseList[0], defaultExpenseFields)) {
                    expenseList.map((data) => {
                        if (!data.currency?.id) {
                            expenseListFields.currecyIdx = true;
                        }
                        if (!data.expenseDate) {
                            expenseListFields.expenseDate = true;
                        }
                        if (!data.spendingAmount) {
                            expenseListFields.expenseAmount = true;
                        }
                        if (!data.tlAmount) {
                            expenseListFields.tlAmount = true;
                        }
                        if (!data.expenditureGroup?.id) {
                            expenseListFields.expenditureGroup = true;
                        }

                    });
                    if (expenseListFields.expenditureGroup) {
                        hasError = true;
                        fields.push(t('EXPENDITURE_GROUP'));
                    }
                    if (expenseListFields.currecyIdx) {
                        hasError = true
                        fields.push(t('PARTNER_CURRENCY'));
                    }
                    if (expenseListFields.expenseDate) {
                        hasError = true;
                        fields.push(t('PARTNER_DATE'));
                    }
                    if (expenseListFields.expenseAmount) {
                        hasError = true;
                        fields.push(t('PARTNER_AMOUNT2'));
                    }
                    if (expenseListFields.tlAmount) {
                        hasError = true;
                        fields.push(t('TL_AMOUNT'));
                    }
                }
                // CurrencyConvertList
                // if (!compareObjects(expenseList[0], defaultExpenseFields)) {
                //     if (!compareCurrencyObjects(currencyConvertList[0], defaultConvertCardFields)) {
                //         hasError = true;
                //         fields.push(t('CURRENCY_CONVERSION_COSTS'));
                //     }
                // }
                if (!compareCurrencyObjects(currencyConvertList[0], defaultConvertCardFields)) {
                    currencyConvertList.map((data) => {
                        if (!data.expenseDate) {
                            currencyConvertListFields.expenseDate = true;
                        }
                        if (!data.cashedCurrency?.id) {
                            currencyConvertListFields.exchangeCurrencyId = true;
                        }
                        if (!data.cashedAmount) {
                            currencyConvertListFields.exchangeAmount = true;
                        }
                        if (!data.convertedCurrency?.id) {
                            currencyConvertListFields.convertCurrencyId = true;
                        }
                        if (!data.convertedAmount) {
                            currencyConvertListFields.convertAmount = true;
                        }
                        if (!data.tlAmount) {
                            currencyConvertListFields.exchangeExpense = true;
                        }
                    });
                    if (currencyConvertListFields.expenseDate) {
                        hasError = true;
                        fields.push(t('PARTNER_DATE'));
                    }
                    if (currencyConvertListFields.exchangeCurrencyId) {
                        hasError = true;
                        fields.push(t('PARTNER_CURRENCY'));
                    }
                    if (currencyConvertListFields.exchangeAmount) {
                        hasError = true;
                        fields.push(t('CASHED_AMOUNT'));
                    }
                    if (currencyConvertListFields.convertCurrencyId) {
                        hasError = true;
                        fields.push(t('PARTNER_CURRENCY'));
                    }
                    if (currencyConvertListFields.convertAmount) {
                        hasError = true;
                        fields.push(t('CONVERTED_AMOUNT'));
                    }
                    if (currencyConvertListFields.exchangeExpense) {
                        hasError = true;
                        fields.push(t('CONVERSION_COSTS'));
                    }
                }
            }
        } else if (expenseState.expenseType === 2) {
            creditCardList.map((data) => {
                if (!data.expenseDate) {
                    requiredCardListFields.expenseDate = true;
                }
                if (!data.expenditureGroup?.id) {
                    requiredCardListFields.expenditureGroup = true;
                }
                if (!data.vat) {
                    requiredCardListFields.vat = true;
                }
                if (!data.spendingAmount || data.spendingAmount === "0") {
                    requiredCardListFields.spendingAmount = true;
                }
                if (!data.currency || !data.currency?.id === "") {
                    requiredCardListFields.currency = true;
                }
                if (!data.tlAmount || !data.tlAmount === "0") {
                    requiredCardListFields.tlAmount = true;
                }
            });
            if (requiredCardListFields.expenseDate) {
                hasError = true
                fields.push(t('PARTNER_DATE'));
            }
            if (requiredCardListFields.expenditureGroup) {
                hasError = true;
                fields.push(t('EXPENDITURE_GROUP'));
            }
            if (requiredCardListFields.vat) {
                hasError = true;
                fields.push(t('PARTNER_AMOUNT2'));
            }
            if (requiredCardListFields.spendingAmount) {
                hasError = true;
                fields.push(t('PARTNER_AMOUNT2'));
            }
            if (requiredCardListFields.currency) {
                hasError = true;
                fields.push(t('PARTNER_CURRENCY'));
            }
            if (requiredCardListFields.tlAmount) {
                hasError = true;
                fields.push(t('TL_AMOUNT'));
            }
        }
        if (!hasError) {
            if (expenseState.expenseType === 1) {
                const returnList = returnAdvanceList.concat(returnAdvanceAddList);
                const returnAmountArr = returnList.map((data) => {
                    return {
                        "currencyId": data.advanceInformation?.id,
                        "currencyName": data.advanceInformation?.displayValue,
                        "returnAmount": data.return ? data.return : 0,
                        "totalAdvance": data.advanceDebt,
                        "exchangeRate": data.exchangeRate,
                        "remainingAdvance": data.received
                    }
                });
                const expenseDetailsArr = expenseList.map((data) => {
                    return {
                        "expenseTypeIdx": data?.expenditureGroup?.id,
                        "vat": data?.vat?.id == 0 ? 0 : data?.vat !== "" ? data?.vat.id : null,
                        "currencyIdx": data?.currency?.id,
                        "expenseDate": data?.expenseDate,
                        "expenseAmount": data?.spendingAmount,
                        "invoiceNo": data?.invoiceNo,
                        "description": data?.description,
                        "tlAmount": data?.tlAmount,
                        "exchangeRate": data?.exchangeRate,
                        "invoiceTitle": data?.receiptInfo,
                        "invoiceImage": data?.receipt
                    }
                });
                const currencyConvertArr = currencyConvertList.map((data) => {
                    return {
                        "exchangeDate": data?.expenseDate,
                        "exchangeAmount": data?.cashedAmount,
                        "cashedTotal": data?.cashedTotal,
                        'cashedExchange': data?.cashedExchange,
                        "exchangeCurrencyId": data?.cashedCurrency?.id,
                        "convertAmount": data?.convertedAmount,
                        'convertExchange': data?.convertExchange,
                        "convertTotal": data?.convertTotal,
                        "convertCurrencyId": data?.convertedCurrency?.id,
                        "exchangeExpense": data?.tlAmount,
                        "invoiceNumber": data?.invoiceNo,
                        "invoiceTitle": data?.receiptInfo,
                        "invoiceImage": data?.receipt
                    }
                })
                const requestData = {
                    "closedAdvanceResourceId": returnCheckList,
                    "expenseMasterTypeId": expenseState.expenseType ? expenseState.expenseType : '',
                    "returnAmountList": returnAmountArr,
                    "description": expenseState.descriptionType1 ? expenseState.descriptionType1 : '',
                    "additionalField": expenseState.speacialAreaType1 ? expenseState.speacialAreaType1 : '',
                    "totalAdvanceAmount": parseFloat(returnAdvanceTotal).toFixed(2),
                    "totalExpenseAmount": parseFloat(expenseTotal),
                    "totalExchangeAmount": parseFloat(currencyConvertTotal),
                    "differentAmount": returnAdvanceList.length > 0 ? parseFloat(parseFloat(returnAdvanceTotal) - (expenseTotal + currencyConvertTotal)).toFixed(2) : 0,
                    "exchanceExpense": !compareCurrencyObjects(currencyConvertList[0], defaultConvertCardFields) ? currencyConvertArr : [],
                    "expenseDetails": !compareObjects(expenseList[0], defaultExpenseFields) ? expenseDetailsArr : [],
                    "IsDraft": type === 'add' ? 0 : 1
                }
                createExpenseWithCashAction(requestData);
                returnListFields = {
                    'advanceInformation': false,
                    'return': false,
                }
                expenseListFields = {
                    'expenseTypeIdx': false,
                    'expenseDate': false,
                    'expenditureGroup': false,
                    'vat': false,
                    'currencyIdx': false,
                    'expenseAmount': false,
                    'tlAmount': false,
                    'exchangeRate': false,
                }
                currencyConvertListFields = {
                    "exchangeDate": false,
                    "exchangeAmount": false,
                    "exchangeCurrencyId": false,
                    "convertAmount": false,
                    "convertCurrencyId": false,
                    "exchangeExpense": false
                }
            } else if (expenseState.expenseType === 2) {
                const expenseTableArr = creditCardList.map((data) => {
                    return {
                        "expenseTypeIdx": data?.expenditureGroup?.id,
                        "vat": data?.vat?.id == 0 ? 0 : data?.vat !== "" ? data?.vat.id : null,
                        "currencyIdx": data.currency?.id,
                        "expenseDate": data.expenseDate,
                        "expenseAmount": data.spendingAmount,
                        "invoiceNo": data.invoiceNo,
                        "description": data.description,
                        "tlAmount": data.tlAmount,
                        "exchangeRate": data.exchangeRate,
                        'invoiceTitle': data.receiptInfo,
                        'invoiceImage': data.receipt
                    }
                });
                const requestData = {
                    "Description": expenseState.description ? expenseState.description : '',
                    "AdditionalField": expenseState.speacialArea ? expenseState.speacialArea : '',
                    "ExpenseMasterTypeId": expenseState.expenseType ? expenseState.expenseType : '',
                    "expenseDetails": expenseTableArr,
                    "IsDraft": type === 'add' ? 0 : 1
                }
                createExpenseAction(requestData)
                requiredCardListFields = {
                    'expenseDate': false,
                    'expenditureGroup': false,
                    'vat': false,
                    'spendingAmount': false,
                    'currency': false,
                    'tlAmount': false
                }
            }
        } else {
            DynamicToastMessage({
                'key': 'required_warning',
                'title': t('REQUIRED_FIELDS_WARNING_TITLE'),
                'description': <List>{fields.map((item) => { return <List.Item>{item}</List.Item> })}</List>,
                'statusType': 'warning',
                'position': 'bottom-right'
            })
        }
    }
    function destroyAllData() {
        setReturnAdvanceList([]);
        setReturnAdvanceAddList([]);
        setReturnAdvanceTotal(0);
        setReturnCurrencyList([]);
        setReturnCheckList([]);

        setExpenseList([defaultExpenseFields]);
        setExpenseTotal(0);
        setExpenseCurrencyList([]);
        setCurrencyConvertList([defaultConvertCardFields]);
        setCurrencyConvertTotal(0);
        setCurrencyConvertSelectList1([]);
        setCurrencyConvertSelectList2([]);

        setCheckedValueList([]);
        setCheckListObj({});
        setOpenAdvanceListState([]);
        setSelectRowKey({ 'id': 0, 'tableName': null })
        setCreditCardList([defaultCreditCardFields]);
        setCreditCardTotalAmount(0)
    }
    function createNewOne() {
        setCurrentTab(1);
        destroyAllData();
        setExpenseState({
            'expenseType': 1
        })
        form.resetFields();
        form.setFieldsValue({
            'expenseType': 1
        })
        resetCurrencyWithIdResponse();
        getOpenAdvance(profile.resourceId, null)
        getDataset(23);
    }
    return (
        <div className="table-details pad-top-40 expense-drawer expense-add-scrollable">
            {currentTab !== 2 ?
                <>
                    <Col span={16}>
                        <div className="add-global-draw-title flex-start">
                            <h1>{t('PARTNER_NEW_EXPENSE_REQUEST')}</h1>
                        </div>
                    </Col>
                    <Col offset={4} span={4}>
                        <div className="add-global-close-icon">
                            <UilMultiply onClick={() => closeControl()} />
                        </div>
                    </Col>
                    <div className="w-100">
                        <ConfigProvider getPopupContainer={trigger => trigger.parentElement}>
                            <Form
                                form={form}
                                onValuesChange={handleValuesChange}
                                layout="vertical min-height-500"
                                requiredMark={true}
                            >
                                <Form.Item className="pad-top-40 margin-b-12" name="expenseType" label={t('PARTNER_EXPENSE_TYPE') + " *"} >
                                    <Radio.Group options={
                                        [
                                            { label: `${t('CASH_EXPENSE_ENTRY')}`, value: 1, },
                                            { label: `${t('SPENDING_ENTRY_WITH_CREDIT_CARD')}`, value: 2 },
                                        ]
                                    } />
                                </Form.Item>
                                {expenseState.expenseType === 1 &&
                                    <>
                                        <Row>
                                            <Col span={12}>
                                                {Array.isArray(openAdvanceListState) ?
                                                    openAdvanceListState.length > 0 ?
                                                        <>
                                                            <Form.Item name="closeAdvanceList" label={t('SELECT_THE_ADVANCE_TO_BE_CLOSED')}>
                                                                <Checkbox.Group onChange={changeCloseAdvanceList}>
                                                                    {openAdvanceListState.map((item, index) => {
                                                                        return (
                                                                            <Row className="w-100" key={index}>
                                                                                <Col size={24}>
                                                                                    <Checkbox value={index} >{moment(item.advanceCreatedDate).format(dateFormat) + " " + item.personName + " "}  <span className="close-advance-checkbox-amount-data">{item.amounts.length > 0 &&
                                                                                        item.amounts.map((data) => numberFormatTR.format(data.amount) + ' ' + data.currencyName).join(', ')
                                                                                    }</span></Checkbox>


                                                                                </Col>
                                                                            </Row>
                                                                        )
                                                                    })}
                                                                </Checkbox.Group>
                                                            </Form.Item>
                                                        </>
                                                        : <div className="pad-24"><span className="f-16 black "> {t('NO_ADVANCE_TO_CLOSE')}</span></div>
                                                    : <Spin />
                                                }
                                                <Form.Item name="descriptionType1" label={t('ADVANCE_DESCRIPTION')}>
                                                    <Input.TextArea />
                                                </Form.Item>
                                                <Form.Item name="speacialAreaType1" label={t('SPEACIAL_AREA')}>
                                                    <Input.TextArea />
                                                </Form.Item>
                                            </Col>
                                            <Col offset={1} span={11}>
                                                {(Array.isArray(openAdvanceListState) && openAdvanceListState?.length > 0) &&
                                                    <>
                                                        <div>
                                                            <span className="f-16 black fw-600">{t('ENTER_THE_ADVANCE_TO_BE_REFUNDED')}</span>
                                                        </div>
                                                        <div className="table-container table-hide-all-buttons blue-table">
                                                            <div className="expense-drawer-advance-return-card-table">
                                                                <div className="row">
                                                                    <label className="advance-information m-r-10"> {t('ADVANCE_INFORMATION')}</label>
                                                                    <label className="received m-r-10"> {t('RECEIVED')}</label>
                                                                    <label className="return m-r-10"> {t('RETURN')}</label>
                                                                    <label className="exchange m-r-10"> {t('EXCHANGE_RATE')}</label>
                                                                    <label className="advanceDebt m-r-10"> {t('ADVANCE_DEBT')}</label>
                                                                </div>
                                                                {
                                                                    returnAdvanceList.length > 0 && returnAdvanceList.map((item, index) => {
                                                                        return (
                                                                            <div className="editable" key={index} >
                                                                                <DynamicSelect
                                                                                    className="advance-information m-r-10"
                                                                                    disabled={true}
                                                                                    value={item.advanceInformation}
                                                                                    onChange={(e) => handleReturnAdvanceListInputChange(e, index, 'advanceInformation', 'select')}

                                                                                ></DynamicSelect>
                                                                                <MaskedInput
                                                                                    className="ant-input received m-r-10"
                                                                                    placeholder={t('PARTNER_AMOUNT2')}
                                                                                    mask={numberMaskWithSeperator}
                                                                                    disabled={true}
                                                                                    value={item.received}
                                                                                    onChange={(e) => handleReturnAdvanceListInputChange(e, index, 'received', 'priceInput')}
                                                                                />
                                                                                <MaskedInput
                                                                                    className="ant-input return m-r-10"
                                                                                    placeholder={t('PARTNER_AMOUNT2')}
                                                                                    mask={numberMaskWithSeperator}
                                                                                    value={item.returnXwrite}
                                                                                    onChange={(e) => handleReturnAdvanceListInputChange(e, index, 'return', 'priceInput')}
                                                                                />
                                                                                <Input
                                                                                    className="exchange m-r-10"
                                                                                    disabled={true}
                                                                                    value={item.exchangeRate}
                                                                                />
                                                                                <Input
                                                                                    className="advanceDebt m-r-10"
                                                                                    disabled={true}
                                                                                    value={parseFloat(item.advanceDebt).toLocaleString('tr-TR') + " ₺"}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })}
                                                                {returnAdvanceAddList.length > 0 && returnAdvanceAddList.map((item, index) => {
                                                                    return (
                                                                        <div className="editable" key={index} >
                                                                            <DynamicSelect
                                                                                className="advance-information m-r-10"
                                                                                optionList={returnCurrencyList}
                                                                                value={item.advanceInformation}
                                                                                onChange={(e) => handleReturnAdvanceAddListInputChange(e, index, 'advanceInformation', 'select')}

                                                                            ></DynamicSelect>
                                                                            <MaskedInput
                                                                                className="ant-input received m-r-10"
                                                                                placeholder={t('PARTNER_AMOUNT2')}
                                                                                mask={numberMaskWithSeperator}
                                                                                disabled={true}
                                                                                value={item.received}
                                                                                onChange={(e) => handleReturnAdvanceAddListInputChange(e, index, 'received', 'priceInput')}
                                                                            />
                                                                            <MaskedInput
                                                                                className="ant-input return m-r-10"
                                                                                placeholder={t('PARTNER_AMOUNT2')}
                                                                                mask={numberMaskWithSeperator}
                                                                                value={item.returnXwrite}
                                                                                onChange={(e) => handleReturnAdvanceAddListInputChange(e, index, 'return', 'priceInput')}
                                                                            />
                                                                            <Input
                                                                                className="exchange m-r-10"
                                                                                disabled={true}
                                                                                value={item.exchangeRate}
                                                                            />
                                                                            <Input
                                                                                className="advanceDebt m-r-10"
                                                                                disabled={true}
                                                                                value={parseFloat(item.advanceDebt).toLocaleString('tr-TR') + " ₺"}
                                                                            />
                                                                            <label className="delete" onClick={() => handleReturnAddListRemoveItem(index, 'returnList')}>
                                                                                {t('PARTNER_DELETE')}
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                            {returnAdvanceList.length > 0 ?
                                                                <div className="w-100 flex-end">
                                                                    <a
                                                                        className="advance-refunded-add-button"
                                                                        onClick={handleAddNewReturnList}
                                                                    >
                                                                        {t('INVENTORY_ADD_TEXT')}
                                                                    </a>
                                                                </div>
                                                                :
                                                                <br />
                                                            }
                                                        </div>
                                                    </>
                                                }
                                                <div className="expense-total-container">
                                                    {returnAdvanceList.length > 0 && <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('NET_ADVANCE_RECEIVED_TOTAL')} : </span>{parseFloat(returnAdvanceTotal).toLocaleString('tr-TR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })} TL</div>}
                                                    <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('TOTAL_EXPENDITURE')} : </span>{parseFloat(expenseTotal).toLocaleString('tr-TR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}  TL</div>
                                                    <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('CURRENCY_CONVERSION_COSTS')} : </span>{parseFloat(currencyConvertTotal).toLocaleString('tr-TR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })} TL</div>
                                                    {returnAdvanceList.length > 0 && <div className="expense-total-text black f-24"><span className="fw-600 f-16 ">{t('DIFFERENCE')} : </span>{(returnAdvanceList.length) > 0 ? parseFloat(parseFloat(returnAdvanceTotal) - (expenseTotal + currencyConvertTotal)).toLocaleString('tr-TR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    }) : 0} TL</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Spin tip={t('FILE_UPLOADING')} spinning={fileLoadState.page === 'expense' && fileLoadState.loading}>
                                            <div className="w-100 space-between pad-top-10">
                                                <span className="f-24 black fw-600">{t('EXPENSES')}</span>
                                                {/* <Button
                                                    onClick={() => handleDynamicAddRow('expense')}
                                                    className="primary-btn"
                                                >
                                                    {t('INVENTORY_ADD_TEXT')}
                                                </Button> */}
                                            </div>
                                            <div className="table-container table-hide-all-buttons blue-table">
                                                <div className="expense-drawer-credits-card-table">
                                                    <div className="row">
                                                        <label className="expenseDate m-r-20">  {t('PARTNER_DATE')} </label>
                                                        <label className="expenditureGroup m-r-20"> {t('EXPENDITURE_GROUP')}</label>
                                                        <label className="description m-r-20"> {t('PARTNER_RETURN_DESCRIPTION')}</label>
                                                        <label className="spendingAmount m-r-20"> {t('PARTNER_AMOUNT2')}</label>
                                                        <label className="currency m-r-20"> {t('PARTNER_CURRENCY')}</label>
                                                        <label className="vatAmount m-r-20">{t('VAT')}</label>
                                                        <label className="amount m-r-20"> {t('TL_AMOUNT')}</label>
                                                        <label className="amount m-r-20">{t('VAT_EXCLUDE')}</label>
                                                        <label className="amount m-r-20">{t('VAT_AMOUNT')}</label>
                                                        <label className="invoiceNo m-r-20"> {t('PARTNER_INVIOCE_NO')}</label>
                                                        <label className="receiptInfo m-r-20"> {t('RECEIPT_TITLE_INFORMATION')}</label>
                                                        <label className="receipt m-r-20"> {t('RECEIPT')}</label>
                                                    </div>
                                                    {
                                                        expenseList.map((item, index) => {
                                                            return (
                                                                <div className="editable" key={index} >
                                                                    <DatePicker
                                                                        locale={localeLang}
                                                                        className="expenseDate m-r-20"
                                                                        value={item.expenseDate ? moment(item.expenseDate, dateFormat) : null}
                                                                        onChange={(date, dateString) => handleDynamicListInputChange(dateString, index, 'expenseDate', 'datePicker', 'expense')}
                                                                    />
                                                                    <DynamicSelect className="expenditureGroup m-r-20"
                                                                        value={item.expenditureGroup}
                                                                        optionList={expenseGroupList}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'expenditureGroup', 'select', 'expense')}
                                                                    />
                                                                    <Input
                                                                        className="description m-r-20"
                                                                        value={item.description}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'description', 'text', 'expense')}
                                                                    />
                                                                    <MaskedInput
                                                                        className="ant-input spendingAmount m-r-20"
                                                                        placeholder={t('PARTNER_AMOUNT2')}
                                                                        mask={numberMaskWithSeperator}
                                                                        value={item.spendingAmountXwrite}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'spendingAmount', 'priceInput', 'expense')}
                                                                    />
                                                                    <DynamicSelect
                                                                        className="currency m-r-20"
                                                                        optionList={expenseCurrencyList}
                                                                        value={item.currency}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'currency', 'select', 'expense')}

                                                                    />
                                                                    <DynamicSelect
                                                                        className="vatAmount m-r-20"
                                                                        optionList={vatList}
                                                                        value={JSON.stringify(item.vat)}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'vat', 'select', 'expense')}
                                                                    />
                                                                    <Input
                                                                        className="amount m-r-20"
                                                                        placeholder={t('PARTNER_AMOUNT2')}
                                                                        value={parseFloat(item.tlAmount).toLocaleString('tr-TR')}
                                                                        disabled={true}
                                                                    />
                                                                    <Input
                                                                        className="amount m-r-20"
                                                                        placeholder={t('PARTNER_AMOUNT2')}
                                                                        value={isNaN(parseFloat(item?.tlAmount / (1 + item?.vat?.id / 100))) ? '0' : parseFloat(item?.tlAmount / (1 + item?.vat?.id / 100)).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                        disabled={true}
                                                                    />
                                                                    <Input
                                                                        className="amount m-r-20"
                                                                        placeholder={t('PARTNER_AMOUNT2')}
                                                                        value={isNaN(parseFloat(item?.tlAmount - (item?.tlAmount / (1 + item?.vat?.id / 100)))) ? '0' : parseFloat(item?.tlAmount - (item?.tlAmount / (1 + item?.vat?.id / 100))).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                        disabled={true}
                                                                    />
                                                                    <Input
                                                                        className="invoiceNo m-r-20"
                                                                        value={item.invoiceNo}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'invoiceNo', 'text', 'expense')}
                                                                    />
                                                                    <Input className="receiptInfo m-r-20"
                                                                        value={item.receiptInfo}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'receiptInfo', 'text', 'expense')}
                                                                    />
                                                                    <Popover content={t('UPLOAD_OR_REPLACE_FILE')}>
                                                                        <Upload
                                                                            action={apiUrl + "/expenses/upload"}
                                                                            headers={
                                                                                {
                                                                                    "Authorization": `Bearer ${access_token}`
                                                                                }
                                                                            }
                                                                            multiple={false}
                                                                            showUploadList={false}
                                                                            maxCount={1}
                                                                            onChange={(e) => uploadImgAction(e, index, 'expense')}
                                                                        //accept={".png,.jpg,.jpeg"}
                                                                        >
                                                                            <Button className={item.receipt ? (expenseList.length - 1 === index && index !== 0) ? "primary-btn receipt" : "primary-btn receipt-full" : (expenseList.length - 1 === index && index !== 0) ? "primary-btn receipt-without-file" : "primary-btn receipt-without-file-full"} icon={<UploadOutlined />}></Button>
                                                                        </Upload>
                                                                    </Popover>
                                                                    {item.receipt &&
                                                                        <Popover content={t('PARTNER_DOWNLOAD')}>
                                                                            <div className="upload-icon mx-1">
                                                                                <a href={item.receipt} target="_blank"> <UilFile /></a>
                                                                            </div>
                                                                        </Popover>
                                                                    }

                                                                    <div className={(expenseList.length - 1 === index) ? "action-buttons mx-1" : "action-buttons"} >
                                                                        {expenseList.length !== 1 &&
                                                                            <Popover content={t('PARTNER_DELETE')}>
                                                                                <Button
                                                                                    className="mx-1 pa-0 transparent-btn" onClick={() => handleDynamicListRemoveItem(index, 'expense')}>
                                                                                    <UilTrashAlt />
                                                                                </Button>
                                                                            </Popover>
                                                                        }
                                                                        {expenseList.length - 1 === index &&
                                                                            <Popover content={t('INVENTORY_ADD_TEXT')}>
                                                                                <Button className="pa-0 transparent-btn" onClick={() => handleDynamicAddRow('expense')}   >
                                                                                    <UilPlusCircle />
                                                                                </Button>
                                                                            </Popover>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <br />
                                                <div className="w-100">
                                                    <div>
                                                        <span className="black f-16"><span className="fw-600 ">{t('TOTAL_EXPENDITURE')} : </span>{parseFloat(expenseTotal).toLocaleString('tr-TR')} TL</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Spin>
                                        <div className="pad-top-40">
                                            <Spin tip={t('FILE_UPLOADING')} spinning={fileLoadState.page === 'currencyConvert' && fileLoadState.loading}>
                                                <div className="w-100 space-between">
                                                    <span className="f-24 black fw-600">{t('CURRENCY_CONVERSION_COSTS')}</span>
                                                    {/* <Button
                                                        onClick={() => handleDynamicAddRow('currencyConvert')}
                                                        className="primary-btn"
                                                    >
                                                        {t('INVENTORY_ADD_TEXT')}
                                                    </Button> */}
                                                </div>
                                                <div className="table-container table-hide-all-buttons blue-table">
                                                    <div className="expense-drawer-currency-conversion-table">
                                                        <div className="row">
                                                            <label className="expenseDate align-self-center m-r-10">  {t('PARTNER_DATE')} </label>
                                                            <label className="cashedAmount align-self-center m-r-10"> {t('CASHED_AMOUNT')}</label>
                                                            <label className="cashedCurrency align-self-center m-r-10"> {t('PARTNER_CURRENCY')}</label>
                                                            <label className="convertedAmount align-self-center  m-r-10"><Tooltip title={t('CONVERTED_AMOUNT')}>{t('CONVERTED_AMOUNT_SHORT')}</Tooltip></label>
                                                            <label className="convertedCurrency align-self-center m-r-10"> {t('PARTNER_CURRENCY')}</label>
                                                            <label className="tlAmount align-self-center m-r-10"> <Tooltip title={t('CONVERSION_COSTS')}> {t('CONVERSION_COSTS_SHORT')}</Tooltip></label>
                                                            <label className="invoiceNo align-self-center m-r-10"> {t('PARTNER_INVIOCE_NO')}</label>
                                                            <label className="receiptInfo align-self-center m-r-10"> {t('RECEIPT_TITLE_INFORMATION')}</label>
                                                            <label className="receipt align-self-center "> {t('RECEIPT')}</label>
                                                        </div>
                                                        {
                                                            currencyConvertList.map((item, index) => {
                                                                return (
                                                                    <div className="editable" key={index} >
                                                                        <DatePicker
                                                                            locale={localeLang}
                                                                            className="expenseDate m-r-10"
                                                                            value={item.expenseDate ? moment(item.expenseDate, dateFormat) : null}
                                                                            onChange={(date, dateString) => handleDynamicListInputChange(dateString, index, 'expenseDate', 'datePicker', 'currencyConvert')}
                                                                        />
                                                                        <MaskedInput
                                                                            className="ant-input cashedAmount m-r-10"
                                                                            placeholder={t('PARTNER_AMOUNT2')}
                                                                            mask={numberMaskWithSeperator}
                                                                            value={item.cashedAmountXwrite}
                                                                            onChange={(e) => handleDynamicListInputChange(e, index, 'cashedAmount', 'priceInput', 'currencyConvert')}
                                                                        />
                                                                        <DynamicSelect
                                                                            className="cashedCurrency m-r-10"
                                                                            optionList={currencyConvertSelectList1}
                                                                            value={item.cashedCurrency}
                                                                            onChange={(e) => handleDynamicListInputChange(e, index, 'cashedCurrency', 'select', 'currencyConvert')}
                                                                        />
                                                                        <MaskedInput
                                                                            className="ant-input convertedAmount m-r-10"
                                                                            placeholder={t('PARTNER_AMOUNT2')}
                                                                            mask={numberMaskWithSeperator}
                                                                            value={item.convertedAmountXwrite}
                                                                            onChange={(e) => handleDynamicListInputChange(e, index, 'convertedAmount', 'priceInput', 'currencyConvert')}
                                                                        />
                                                                        <DynamicSelect
                                                                            className="convertedCurrency m-r-10"
                                                                            optionList={currencyConvertSelectList2}
                                                                            value={item.convertedCurrency}
                                                                            onChange={(e) => handleDynamicListInputChange(e, index, 'convertedCurrency', 'select', 'currencyConvert')}

                                                                        ></DynamicSelect>
                                                                        <Input
                                                                            className="tlAmount m-r-10"
                                                                            placeholder={t('PARTNER_AMOUNT2')}
                                                                            value={parseFloat(item.tlAmount).toLocaleString('tr-TR')}
                                                                            disabled={true}
                                                                        />
                                                                        <Input
                                                                            className="invoiceNo m-r-10"
                                                                            value={item.invoiceNo}
                                                                            onChange={(e) => handleDynamicListInputChange(e, index, 'invoiceNo', 'text', 'currencyConvert')}
                                                                        />
                                                                        <Input className="receiptInfo m-r-10"
                                                                            value={item.receiptInfo}
                                                                            onChange={(e) => handleDynamicListInputChange(e, index, 'receiptInfo', 'text', 'currencyConvert')}
                                                                        />
                                                                        <Popover content={t('UPLOAD_OR_REPLACE_FILE')}>
                                                                            <Upload
                                                                                action={apiUrl + "/expenses/upload"}
                                                                                headers={
                                                                                    {
                                                                                        "Authorization": `Bearer ${access_token}`
                                                                                    }
                                                                                }
                                                                                multiple={false}
                                                                                showUploadList={false}
                                                                                maxCount={1}
                                                                                onChange={(e) => uploadImgAction(e, index, 'currencyConvert')}
                                                                            //accept={".png,.jpg,.jpeg"}
                                                                            >
                                                                                <Button className={item.receipt ? (currencyConvertList.length - 1 === index && index !== 0) ? "primary-btn receipt" : "primary-btn receipt-full" : (currencyConvertList.length - 1 === index && index !== 0) ? "primary-btn receipt-without-file" : "primary-btn receipt-without-file-full"} icon={<UploadOutlined />}></Button>
                                                                            </Upload>
                                                                        </Popover>
                                                                        {item.receipt &&
                                                                            <Popover content={t('PARTNER_DOWNLOAD')}>
                                                                                <div className="upload-icon mx-1">
                                                                                    <a href={item.receipt} target="_blank"> <UilFile /></a>
                                                                                </div>
                                                                            </Popover>
                                                                        }
                                                                        <div className={(currencyConvertList.length - 1 === index) ? "action-buttons mx-1" : "action-buttons"} >
                                                                            {currencyConvertList.length !== 1 &&
                                                                                <Popover content={t('PARTNER_DELETE')}>
                                                                                    <Button
                                                                                        className="mx-1 pa-0 transparent-btn" onClick={() => handleDynamicListRemoveItem(index, 'currencyConvert')}>
                                                                                        <UilTrashAlt />
                                                                                    </Button>
                                                                                </Popover>
                                                                            }
                                                                            {currencyConvertList.length - 1 === index &&
                                                                                <Popover content={t('INVENTORY_ADD_TEXT')}>
                                                                                    <Button className="pa-0 transparent-btn" onClick={() => handleDynamicAddRow('currencyConvert')}   >
                                                                                        <UilPlusCircle />
                                                                                    </Button>
                                                                                </Popover>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <br />
                                                    <div className="w-100">
                                                        <div>
                                                            <span className="black f-16"><span className="fw-600 ">{t('TOTAL_EXPENDITURE')} : </span>{parseFloat(currencyConvertTotal).toLocaleString('tr-TR')} TL</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Spin>
                                        </div>
                                    </>
                                }
                                {expenseState.expenseType === 2 &&
                                    <>
                                        <Row>
                                            <Col span={11}>
                                                <Form.Item name="description" label={t('ADVANCE_DESCRIPTION')}>
                                                    <Input.TextArea />
                                                </Form.Item>
                                            </Col>
                                            <Col offset={2} span={11}>
                                                <Form.Item name="speacialArea" label={t('SPEACIAL_AREA')}>
                                                    <Input.TextArea />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <div className="pad-top-40" />
                                        <Spin tip={t('FILE_UPLOADING')} spinning={fileLoadState.page === 'creditCard' && fileLoadState.loading}>
                                            <div className="w-100 space-between">
                                                <span className="f-24 black fw-600">{t('EXPENSES')}</span>
                                                {/* <Button
                                                    onClick={() => handleDynamicAddRow('creditCard')}
                                                    className="primary-btn"
                                                >
                                                    {t('INVENTORY_ADD_TEXT')}
                                                </Button> */}
                                            </div>
                                            <div className="table-container table-hide-all-buttons blue-table">
                                                <div className="expense-drawer-credits-card-table">
                                                    <div className="row">
                                                        <label className="expenseDate m-r-20">  {t('PARTNER_DATE')} </label>
                                                        <label className="expenditureGroup m-r-20"> {t('EXPENDITURE_GROUP')}</label>
                                                        <label className="description m-r-20"> {t('PARTNER_RETURN_DESCRIPTION')}</label>
                                                        <label className="spendingAmount m-r-20"> {t('PARTNER_AMOUNT2')}</label>
                                                        <label className="currency m-r-20"> {t('PARTNER_CURRENCY')}</label>
                                                        <label className="amount m-r-20">{t('VAT')}</label>
                                                        <label className="amount m-r-20"> {t('TL_AMOUNT')}</label>
                                                        <label className="amount m-r-20">{t('VAT_EXCLUDE')}</label>
                                                        <label className="amount m-r-20">{t('VAT_AMOUNT')}</label>
                                                        <label className="invoiceNo m-r-20"> {t('PARTNER_INVIOCE_NO')}</label>
                                                        <label className="receiptInfo m-r-20"> {t('RECEIPT_TITLE_INFORMATION')}</label>
                                                        <label className="receipt m-r-20"> {t('RECEIPT')}</label>
                                                    </div>
                                                    {
                                                        creditCardList.map((item, index) => {
                                                            return (
                                                                <div className="editable" key={index} >
                                                                    <DatePicker
                                                                        locale={localeLang}
                                                                        className="expenseDate m-r-20"
                                                                        value={item.expenseDate ? moment(item.expenseDate, dateFormat) : null}
                                                                        onChange={(date, dateString) => handleDynamicListInputChange(dateString, index, 'expenseDate', 'datePicker', 'creditCard')}
                                                                    />
                                                                    <DynamicSelect className="expenditureGroup m-r-20"
                                                                        value={item.expenditureGroup}
                                                                        optionList={expenseGroupList}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'expenditureGroup', 'select', 'creditCard')}
                                                                    />
                                                                    <Input
                                                                        className="description m-r-20"
                                                                        value={item.description}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'description', 'text', 'creditCard')}
                                                                    />
                                                                    <MaskedInput
                                                                        className="ant-input spendingAmount m-r-20"
                                                                        placeholder={t('PARTNER_AMOUNT2')}
                                                                        mask={numberMaskWithSeperator}
                                                                        value={item.spendingAmountXwrite}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'spendingAmount', 'priceInput', 'creditCard')}
                                                                    />
                                                                    <DynamicSelect
                                                                        className="currency m-r-20"
                                                                        optionList={creditCardCurrencyList}
                                                                        value={item.currency}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'currency', 'select', 'creditCard')}

                                                                    ></DynamicSelect>
                                                                    <DynamicSelect
                                                                        className="currency m-r-20"
                                                                        optionList={vatList}
                                                                        value={JSON.stringify(item.vat)}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'vat', 'select', 'creditCard')}
                                                                    />
                                                                    <Input
                                                                        className=" amount m-r-20"
                                                                        placeholder={t('PARTNER_AMOUNT2')}
                                                                        value={parseFloat(item.tlAmount).toLocaleString('tr-TR')}
                                                                        disabled={true}
                                                                    />
                                                                    <Input
                                                                        className="amount m-r-20"
                                                                        placeholder={t('PARTNER_AMOUNT2')}
                                                                        value={isNaN(parseFloat(item?.tlAmount / (1 + item?.vat?.id / 100))) ? '0' : parseFloat(item?.tlAmount / (1 + item?.vat?.id / 100)).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                        disabled={true}
                                                                    />
                                                                    <Input
                                                                        className="amount m-r-20"
                                                                        placeholder={t('PARTNER_AMOUNT2')}
                                                                        value={isNaN(parseFloat(item?.tlAmount - (item?.tlAmount / (1 + item?.vat?.id / 100)))) ? '0' : parseFloat(item?.tlAmount - (item?.tlAmount / (1 + item?.vat?.id / 100))).toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                        disabled={true}
                                                                    />
                                                                    <Input
                                                                        className="invoiceNo m-r-20"
                                                                        value={item.invoiceNo}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'invoiceNo', 'text', 'creditCard')}
                                                                    />
                                                                    <Input className="receiptInfo m-r-20"
                                                                        value={item.receiptInfo}
                                                                        onChange={(e) => handleDynamicListInputChange(e, index, 'receiptInfo', 'text', 'creditCard')}
                                                                    />
                                                                    <Popover content={t('UPLOAD_OR_REPLACE_FILE')}>
                                                                        <Upload
                                                                            action={apiUrl + "/expenses/upload"}
                                                                            headers={
                                                                                {
                                                                                    "Authorization": `Bearer ${access_token}`
                                                                                }
                                                                            }
                                                                            multiple={false}
                                                                            showUploadList={false}
                                                                            maxCount={1}
                                                                            onChange={(e) => uploadImgAction(e, index, 'creditCard')}
                                                                        //accept={".png,.jpg,.jpeg"}
                                                                        >
                                                                            <Button className={item.receipt ? (creditCardList.length - 1 === index && index !== 0) ? "primary-btn receipt" : "primary-btn receipt-full" : (creditCardList.length - 1 === index && index !== 0) ? "primary-btn receipt-without-file" : "primary-btn receipt-without-file-full"} icon={<UploadOutlined />}></Button>
                                                                        </Upload>
                                                                    </Popover>
                                                                    {item.receipt &&
                                                                        <Popover content={t('PARTNER_DOWNLOAD')}>
                                                                            <div className="upload-icon mx-1">
                                                                                <a href={item.receipt} target="_blank"> <UilFile /></a>
                                                                            </div>
                                                                        </Popover>
                                                                    }
                                                                    <div className={(creditCardList.length - 1 === index) ? "action-buttons mx-1" : "action-buttons"} >
                                                                        {creditCardList.length !== 1 &&
                                                                            <Popover content={t('PARTNER_DELETE')}>
                                                                                <Button
                                                                                    className="mx-1 pa-0 transparent-btn" onClick={() => handleDynamicListRemoveItem(index, 'creditCard')}>
                                                                                    <UilTrashAlt />
                                                                                </Button>
                                                                            </Popover>
                                                                        }
                                                                        {creditCardList.length - 1 === index &&
                                                                            <Popover content={t('INVENTORY_ADD_TEXT')}>
                                                                                <Button className="pa-0 transparent-btn" onClick={() => handleDynamicAddRow('creditCard')}   >
                                                                                    <UilPlusCircle />
                                                                                </Button>
                                                                            </Popover>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <br />
                                                <div className="w-100">
                                                    <div>
                                                        <span className="black f-16"><span className="fw-600 ">{t('TOTAL_EXPENDITURE')} : </span>{parseFloat(creditCardTotalAmount).toLocaleString('tr-TR')} TL</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Spin>
                                    </>
                                }
                            </Form>
                        </ConfigProvider>
                    </div>
                </>
                :
                <Successful message={t('EXPENSE_REQUEST_SUCCESS')} />
            }
            <div className="add-expense-draw-footer">
                {currentTab < 2 ?
                    <Button onClick={() => closeControl()} className="w-120 h-40 transparent-btn fw-600 pad-0">{t('INVENTORY_CANCEL')}</Button>
                    : ''}
                <div className="add-expense-draw-footer">
                    {currentTab < 2 ?
                        <Button onClick={() => closeControl()} className="w-120 h-40 transparent-btn fw-600 pad-0">{t('INVENTORY_CANCEL')}</Button>
                        : ''}
                    <div className="add-user-draw-footer-end">
                        {currentTab > 1 && currentTab < 2 ?
                            <Button onClick={() => closeControl()} className="w-180 h-40 f-14 font-w-700 white-btn">{t('INVENTORY_BACK')}</Button>
                            :
                            ''
                        }
                        {currentTab === 2 &&
                            <Button onClick={() => createNewOne()} className="pad-0 w-180 h-40 f-14 font-w-700 white-btn">{t('PARTNER_NEW_EXPENSE_REQUEST')}</Button>
                        }
                        <div className="pad-left-24" />
                        {currentTab < 2 ?
                            currentTab === 1 &&
                            <>
                                <Button onClick={() => createExpense('save')} className="w-100 m-w-200 h-40 f-14 font-w-700 white-btn ">{t('PARTNER_SAVE')}</Button>
                                <div className="pad-left-24" />
                                <Button onClick={() => createExpense('add')} className="w-100 m-w-200 h-40 f-14 font-w-700 primary-btn ">{t('SAVE_AND_SEND')}</Button>

                            </>
                            :
                            <Button onClick={() => closeControl()} className=" w-180 h-40 f-14 font-w-700 primary-btn">{page ? t('RETURN_HOMEPAGE') : t('INVENTORY_RETURN_LIST')}</Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        currencyTypes: state.dataset.currencyTypes,
        currencyWithId: state.expense.currencyWithId,
        multipleCurrencyWithId: state.expense.multipleCurrencyWithId,
        creatExpenseRequests: state.expense.creatExpenseRequests,
        creatExpenseWithCashRequests: state.expense.creatExpenseWithCashRequests,
        profile: state.profile,
        openAdvanceList: state.expense.openAdvanceList.data,
        expenseTypes: state.dataset.expenseTypes
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        getCurrencyWithId: (id, date1, date2) => { dispatch(getCurrencyWithId(id, date1, date2)) },
        getMultipleCurrency: (data1, data2, date1, date2) => { dispatch(getMultipleCurrency(data1, data2, date1, date2)) },
        getDataset: (type) => { dispatch(getDataset(type)) },
        createExpenseAction: (data) => { dispatch(createExpenseAction(data)) },
        resetCreateExpenseResponse: () => { dispatch(resetCreateExpenseResponse()) },
        setExpenseCreateDrawer: (status) => { dispatch(setExpenseCreateDrawer(status)) },
        resetCurrencyWithIdResponse: () => { dispatch(resetCurrencyWithIdResponse()) },
        getOpenAdvance: (resourceId, expenseResourceId) => { dispatch(getOpenAdvance(resourceId, expenseResourceId)) },
        createExpenseWithCashAction: (data) => { dispatch(createExpenseWithCashAction(data)) },
        resetCreateExpenseWithCashResponse: () => { dispatch(resetCreateExpenseWithCashResponse()) },
        resetGetMultipleCurrency: () => { dispatch(resetGetMultipleCurrency()) }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExpenseAdd);